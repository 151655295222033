import React from "react";
import Modal from "components/CustomModal";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import CONSTANTS from "constants/index";
import If from "components/If";
import moment from "moment";
import get from "lodash/get";
import { setDayInMonth } from "utils/dates";
import Toaster from "components/Toaster";
import Select from "react-select";
import Button from "components/Button";
import InfoBox from "components/InfoBox";
import EmailInput from "components/Email/EmailInput";
import { getLocaleString } from "utils/localization/locale";
import ClickAwayListener from "components/ClickAwayListener";

const initState = {
  name: "",
  body: "",
  subject: "",
  showErrors: {
    body: false,
    subject: false,
    sms_body: false,
    workflow_template_id: false,
  },
  trigger_days: null,
  is_recurring: false,
  triggerEvent: "1",
  is_pdf_required: false,
  is_sms_enabled: false,
  selectedCustomer: null,
  workflow_template_id: null,
  showTriggerValidationError: false,
  triggerCriteria: "overdue_balance",
  task_details: {
    title: "",
    assigned_to: [],
    task_type: "TODO",
    description: "",
    overdue_days: "",
    task_priority: "MEDIUM",
    overdue_balance: "",
    reminder_time: "0",
  },
};

export default class ActionsModal extends React.Component {
  constructor(props) {
    super(props);
    const { modalStepData } = props;
    this.state = {
      ...initState,
      ...modalStepData,
      openEscalationDropdown: false,
      othersEmailAddress: get(modalStepData, "cc_others_emails", "")
        ? get(modalStepData, "cc_others_emails", "").split(",")
        : [],
      escalateDropdownSelection: [
        {
          key: "cc_sales_rep",
          name: getLocaleString("receivables.sales_rep", "Sales Rep"),
          is_selected: get(modalStepData, "cc_sales_rep", false),
        },
        {
          key: "cc_account_rep",
          name: getLocaleString("customer.account_rep", "Account Rep"),
          is_selected: get(modalStepData, "cc_account_rep", false),
        },
        {
          key: "cc_ar_rep",
          name: getLocaleString("common.ar_rep", "AR Rep"),
          is_selected: get(modalStepData, "cc_ar_rep", false),
        },
        {
          key: "cc_others",
          name: getLocaleString("common.others", "Others"),
          is_selected: !!get(modalStepData, "cc_others_emails", ""),
        },
      ],
      triggerCriteria: get(
        modalStepData,
        "task_trigger_criteria.overdue_days",
        0
      )
        ? "overdue_days"
        : "overdue_balance",
      task_details: {
        ...initState.task_details,
        ...get(modalStepData, "task_details", {}),
        ...get(modalStepData, "task_trigger_criteria", {}),
      },
      triggerEvent: modalStepData.trigger_task
        ? "5"
        : modalStepData.trigger_on_statement_date
          ? "3"
          : modalStepData.trigger_after_invoice_date
            ? "4"
            : modalStepData.trigger_on_invoice_date
              ? "2"
              : modalStepData.trigger_days > 0
                ? "1"
                : !modalStepData.trigger_days
                  ? "0"
                  : "-1",
      recurringOption: {},
    };
  }

  onHideModal = () =>
    this.setState(
      {
        ...initState,
      },
      this.props.handleModal
    );

  setTriggerValidationError = () => {
    const { trigger_days, triggerEvent } = this.state;
    if (triggerEvent === "4" && (!trigger_days || trigger_days < 0)) {
      this.setState({
        showTriggerValidationError: true,
      });
    } else if (
      !["2", "0"].includes(triggerEvent) &&
      (!trigger_days || trigger_days > 10000)
    ) {
      this.setState({
        showTriggerValidationError: true,
      });
    } else {
      this.setState({
        showTriggerValidationError: false,
      });
    }
  };

  renderEmailModalBody = ({ subject, body, workflow_template_id }) => {
    const { emailTemplate } = this.props;
    const { showErrors, is_sms_enabled, sms_body } = this.state;
    const updatedBody = body
      ? body
          .replaceAll(
            `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="background: white; padding: 10px">`,
            ""
          )
          .replaceAll("</table>", "")
          .replaceAll(`<figure className="table"><table><tbody>`, "")
          .replaceAll(`</tbody></table></figure>`, "")
      : "";

    return (
      <div className="mb-2">
        <div className="mb-2">
          <div>
            <strong>
              {getLocaleString("receivables.email_template", "Email Template")}:
            </strong>
          </div>
          <select
            className="form-control t-12 mb-2"
            data-cy="email-template"
            defaultValue={workflow_template_id}
            onChange={(e) => {
              const template = emailTemplate.data.find(
                (temp) => temp.id === Number(e.target.value)
              );
              this.setState({
                workflow_template_id: Number(e.target.value),
                subject: template.subject,
                body: template.body,
                name: template.name,
                showErrors: {
                  body: false,
                  subject: false,
                  workflow_template_id: false,
                },
              });
            }}
          >
            <option disabled selected value="">
              {getLocaleString(
                "receivables.select_email_template",
                "Select Email Template"
              )}
            </option>
            {emailTemplate.data.map((d) => (
              <option value={d.id}>{d.name}</option>
            ))}
          </select>
          {showErrors.workflow_template_id ? (
            <div className="error t-12">
              {getLocaleString(
                "receivables.email_template_is_required",
                "Email template is required"
              )}
            </div>
          ) : null}
        </div>
        <div className="mb-2">
          <div>
            <strong>
              {getLocaleString("receivables.subject", "Subject")}:
            </strong>
          </div>
          <input
            placeholder="Subject"
            data-cy="email-subject"
            type="text"
            className="form-control t-12"
            defaultValue={subject}
            onChange={(e) => this.setState({ subject: e.target.value })}
          />
          {showErrors.subject ? (
            <span className="error t-12">
              {getLocaleString(
                "receivables.subject_is_required",
                "Subject is Required"
              )}
            </span>
          ) : null}
        </div>
        <div className="workflow-email-body" data-cy="email-body">
          <div>
            <strong>{getLocaleString("receivables.body", "Body")}:</strong>
          </div>
          <CKEditor
            editor={ClassicEditor}
            data={updatedBody}
            config={{
              toolbar: [
                "heading",
                "|",
                "bold",
                "italic",
                "numberedList",
                "bulletedList",
              ],
            }}
            onChange={(_, editor) => {
              const body = editor.getData();
              this.setState({ body });
            }}
          />
          <div>
            {showErrors.body ? (
              <span className="error t-12">
                {getLocaleString(
                  "receivables.body_is_required",
                  "Email body is Required"
                )}
              </span>
            ) : null}
          </div>
        </div>
        {is_sms_enabled ? (
          <>
            <div className="col-md-12 mt-3 border-top py-2">
              <div>
                <strong>
                  {getLocaleString(
                    "customer.text_message_body",
                    "Text Message Body"
                  )}
                  *:
                </strong>
              </div>
              <textarea
                value={sms_body}
                onChange={(e) => {
                  const flag = e.target.value.length > 130;
                  this.setState({
                    sms_body: flag
                      ? e.target.value.substring(0, 130)
                      : e.target.value,
                    showErrors: {
                      ...showErrors,
                      sms_body: flag
                        ? `${getLocaleString(
                            "common.max_character_limit",
                            "Max character limit"
                          )}: 130`
                        : !e.target.value
                          ? getLocaleString(
                              "customer.sms_body_required",
                              "SMS body is required"
                            )
                          : "",
                    },
                  });
                }}
                rows="10"
                placeholder={getLocaleString(
                  "customer.type_short_text_message",
                  "Type short Text Message (Max 130 charters)"
                )}
                className="form-control t-14"
              ></textarea>
              {showErrors.sms_body ? (
                <span className="error t-12">
                  {typeof showErrors.sms_body === "boolean"
                    ? getLocaleString(
                        "customer.sms_body_required",
                        "SMS body is required"
                      )
                    : showErrors.sms_body}
                </span>
              ) : null}
            </div>
          </>
        ) : null}
      </div>
    );
  };

  validateFields = () => {
    const {
      body,
      subject,
      sms_body,
      triggerEvent,
      is_sms_enabled,
      triggerCriteria,
      workflow_template_id,
      showTriggerValidationError,
      task_details: { title, overdue_balance, overdue_days, description },
    } = this.state;

    const errorObj =
      triggerEvent === "5"
        ? {
            title: !title || title.length > 250,
            description: description && description.length > 1000,
            overdue_days: triggerCriteria === "overdue_days" && !overdue_days,
            overdue_balance:
              triggerCriteria === "overdue_balance" && !overdue_balance,
          }
        : {
            body: !body,
            subject: !subject,
            sms_body: !sms_body,
            workflow_template_id: !workflow_template_id,
          };

    const showModal =
      triggerEvent === "5"
        ? !!title &&
          title.length <= 250 &&
          description.length <= 1000 &&
          (triggerCriteria === "overdue_balance"
            ? !!overdue_balance
            : !!overdue_days)
        : !!body &&
          !!subject &&
          !!workflow_template_id &&
          (is_sms_enabled ? !!sms_body : true) &&
          !showTriggerValidationError;

    if (!showModal) {
      this.setState({
        showErrors: errorObj,
      });
      document.getElementById("workflow-step-create").scrollIntoView();
      Toaster(
        getLocaleString(
          "common.please_fill_required_fields",
          "Please fill required fields"
        ),
        "error"
      );
      return false;
    }
    return true;
  };

  onTaskDetailsChange = (e) => {
    this.setState({
      task_details: {
        ...this.state.task_details,
        [e.target.name]:
          e.target.value &&
          ["overdue_balance", "overdue_days"].includes(e.target.name)
            ? parseInt(e.target.value)
            : e.target.value,
      },
    });
  };

  render() {
    const { show, onSave, modalStepData, newWf, userList, isSmsEnabled } =
      this.props;

    const recurringObjectOptions = [
      {
        id: "MONTHLY",
        name: getLocaleString("payroll.monthly_on", "Monthly on"),
        tag: "Recurring day of month",
        value: moment().format("YYYY-MM-DD"),
        appendValue: true,
        options: CONSTANTS.DATE_CONST.MONTH_DAYS_WITH_SUFFIX,
        getNextdate: (_date, _day) => setDayInMonth(_date, _day),
      },
      {
        id: "WEEKLY",
        name: getLocaleString("payroll.weekly_on", "Weekly on"),
        tag: "Recurring week day",
        value: 0,
        appendValue: true,
        options: CONSTANTS.DATE_CONST.WEEK_DAYS,
        getNextdate: (_date, _value) =>
          _date
            .add(1, "week")
            .startOf("week")
            .add(Number(_value) + 1, "days"),
      },
    ];

    const {
      name,
      body,
      subject,
      sms_body,
      showErrors,
      triggerEvent,
      trigger_days,
      is_recurring,
      statement_day,
      is_sms_enabled,
      is_pdf_required,
      triggerCriteria,
      statement_period,
      othersEmailAddress,
      workflow_template_id,
      openEscalationDropdown,
      escalateDropdownSelection,
      trigger_on_statement_date,
      showTriggerValidationError,
      task_details: {
        title,
        task_type,
        assigned_to,
        description,
        overdue_days,
        task_priority,
        overdue_balance,
      },
    } = this.state;

    const isOthersEscalationSelected = escalateDropdownSelection.some(
      (d) => d.key === "cc_others" && d.is_selected
    );

    return (
      <Modal
        footer={() => (
          <>
            <Button
              className="mt-2"
              data-cy="update-event"
              onClick={() => {
                if (this.validateFields()) {
                  this.onHideModal();
                  const stepData =
                    triggerEvent === "5"
                      ? {
                          trigger_task: 1,
                          step_seq: modalStepData.step_seq,
                          task_trigger_criteria: {
                            [triggerCriteria]: get(
                              this.state,
                              `task_details[${triggerCriteria}]`,
                              0
                            ),
                          },
                          task_details: {
                            title,
                            due_date: moment().format("MM/DD/YYYY"),
                            task_type,
                            assigned_to,
                            description,
                            task_priority,
                          },
                        }
                      : {
                          body,
                          name,
                          trigger_on_invoice_date: +(triggerEvent === "2"),
                          trigger_after_invoice_date: +(triggerEvent === "4"),
                          trigger_on_statement_date,
                          statement_period,
                          statement_day,
                          trigger_days:
                            triggerEvent === "-1"
                              ? -Math.abs(trigger_days)
                              : Number(trigger_days),
                          subject,
                          workflow_template_id,
                          is_recurring,
                          is_pdf_required,
                          is_sms_enabled,
                          sms_body,
                          cc_others_emails: isOthersEscalationSelected
                            ? Array.isArray(othersEmailAddress)
                              ? othersEmailAddress.join()
                              : othersEmailAddress
                            : "",
                          ...escalateDropdownSelection.reduce(
                            (acc, cur) => ({
                              ...acc,
                              [cur.key]: cur.is_selected,
                            }),
                            {}
                          ),
                        };
                  onSave(
                    modalStepData.step_seq,
                    {
                      ...modalStepData,
                      ...stepData,
                    },
                    newWf
                  );
                }
              }}
            >
              {getLocaleString("common.save", "Save")}
            </Button>
            <Button
              variant="secondary"
              className="mt-2 ms-2"
              onClick={this.onHideModal}
            >
              {getLocaleString("common.cancel", "Cancel")}
            </Button>
          </>
        )}
        size="lg"
        show={show}
        onHide={this.onHideModal}
        title={getLocaleString("receivables.event_details", "Event Details")}
        modalBodyStyle={{
          "max-height": "calc(100vh - 270px)",
          overflow: "auto",
        }}
      >
        {show && (
          <div>
            <div
              className="w-45 me-4 d-inline-block align-top mb-3"
              id="workflow-step-create"
            >
              <div>
                <strong>
                  {getLocaleString("receivables.set_trigger", "Set trigger")}:
                </strong>
              </div>
              <select
                key={triggerEvent}
                data-cy="trigger-event"
                className="form-control t-12 mb-1"
                defaultValue={triggerEvent}
                onChange={(e) =>
                  this.setState(
                    {
                      triggerEvent: e.target.value,
                      trigger_days: 0,
                      trigger_on_invoice_date: e.target.value === "2",
                      trigger_on_statement_date: e.target.value === "3",
                      statement_period: e.target.value === "3" ? "MONTHLY" : "",
                      statement_day: e.target.value === "3" ? "1" : "",
                    },
                    e.target.value !== "3"
                      ? this.setTriggerValidationError
                      : null
                  )
                }
              >
                <option value="5">
                  {getLocaleString("common.create_task", "Create Task")}
                </option>
                <option value="3">
                  {getLocaleString("receivables.statements", "Statements")}
                </option>
                <option value="4">
                  {getLocaleString(
                    "receivables.after_invoice_date",
                    "After Invoice Date"
                  )}
                </option>
                <option value="2">
                  {getLocaleString(
                    "receivables.on_invoice_date",
                    "On Invoice Date"
                  )}
                </option>
                <option value="1">
                  {getLocaleString(
                    "receivables.after_due_date",
                    "After due date"
                  )}
                </option>
                <option value="0">
                  {getLocaleString("receivables.on_due_date", "On due date")}
                </option>
                <option value="-1">
                  {getLocaleString(
                    "receivables.before_due_date",
                    "Before due date"
                  )}
                </option>
              </select>
            </div>
            {triggerEvent !== "5" ? (
              <>
                {triggerEvent !== "3" ? (
                  <div className="w-50 d-inline-block align-top">
                    <div>
                      <strong>
                        {getLocaleString("receivables.set_days", "Set days")}:
                      </strong>
                    </div>
                    <input
                      key={triggerEvent}
                      type="number"
                      data-cy="trigger-days"
                      placeholder={getLocaleString(
                        "receivables.trigger_days",
                        "Trigger days"
                      )}
                      disabled={["2", "0"].includes(triggerEvent)}
                      onBlur={(e) => {
                        this.setState(
                          {
                            triggerEvent:
                              e.target.value === "" || e.target.value === "0"
                                ? "0"
                                : triggerEvent,
                          },
                          this.setTriggerValidationError
                        );
                      }}
                      onInput={(e) => {
                        this.setState(
                          {
                            trigger_days:
                              e.target.value === ""
                                ? ""
                                : Number(e.target.value),
                          },
                          this.setTriggerValidationError
                        );
                      }}
                      defaultValue={Math.abs(trigger_days)}
                      className="form-control t-12 days-input d-inline-block"
                    />
                    {showTriggerValidationError && (
                      <span className="error t-12">
                        {!trigger_days
                          ? `${getLocaleString(
                              "receivables.days_cannot_be_0",
                              "Days cannot be 0 or blank for"
                            )} ${
                              triggerEvent === "4"
                                ? getLocaleString("receivables.after", "after")
                                : getLocaleString(
                                    "receivables.before_after",
                                    "before/after"
                                  )
                            } ${
                              triggerEvent === "4"
                                ? getLocaleString(
                                    "common.invoiceDate",
                                    "invoice date"
                                  )
                                : getLocaleString("common.dueDate", "due date")
                            } ${getLocaleString(
                              "receivables.trigger_selection",
                              "trigger selection."
                            )}`
                          : trigger_days < 0 && triggerEvent === "4"
                            ? getLocaleString(
                                "receivables.days_cannot_be_0_for_after_invoice",
                                "Days cannot be 0 or less than 0 for after invoice date trigger selection."
                              )
                            : `${getLocaleString(
                                "receivables.days_cannot_be_more_than_1000",
                                "Days cannot be more than 10000 for"
                              )} ${
                                triggerEvent === "4"
                                  ? getLocaleString(
                                      "receivables.after",
                                      "after"
                                    )
                                  : getLocaleString(
                                      "receivables.before_after",
                                      "before/after"
                                    )
                              } ${
                                triggerEvent === "4"
                                  ? getLocaleString(
                                      "common.invoiceDate",
                                      "invoice date"
                                    )
                                  : getLocaleString(
                                      "common.dueDate",
                                      "due date"
                                    )
                              } ${getLocaleString(
                                "receivables.trigger_selection",
                                "trigger selection."
                              )}`}
                      </span>
                    )}
                  </div>
                ) : (
                  <>
                    <div className="w-45 me-4 d-inline-block align-top mb-3">
                      <div>
                        <strong>
                          {getLocaleString("payroll.recurring", "Recurring")}:
                        </strong>
                      </div>
                      <select
                        className="form-control t-12 mb-1"
                        value={statement_period}
                        onChange={(e) => {
                          this.setState({
                            statement_day:
                              e.target.value === "MONTHLY" ? "1" : "0",
                            statement_period: e.target.value,
                          });
                        }}
                      >
                        {recurringObjectOptions.map((d) => (
                          <option key={d.id} value={d.id}>
                            {d.name}
                          </option>
                        ))}
                      </select>
                    </div>
                    <If condition={statement_period !== ""}>
                      <div className="w-45 me-4 d-inline-block align-top mb-3">
                        <div>
                          <strong>
                            {statement_period === "MONTHLY"
                              ? getLocaleString(
                                  "receivables.recurring_day_of_month",
                                  "Recurring day of month"
                                )
                              : getLocaleString(
                                  "receivables.recurring_week_day",
                                  "Recurring week day"
                                )}
                          </strong>
                        </div>
                        <select
                          className="form-control t-12 mb-1"
                          value={statement_day}
                          onChange={(e) =>
                            this.setState({
                              statement_day: e.target.value,
                            })
                          }
                        >
                          {get(
                            statement_period === "MONTHLY"
                              ? recurringObjectOptions[0]
                              : recurringObjectOptions[1],
                            "options",
                            []
                          ).map((d) => (
                            <option key={d.id} value={d.id}>
                              {d.name}
                            </option>
                          ))}
                        </select>
                      </div>
                    </If>
                  </>
                )}
              </>
            ) : (
              <div className="w-50 d-inline-block align-top">
                <div>
                  <strong>
                    {getLocaleString(
                      "receivables.trigger_criteria",
                      "Trigger Criteria"
                    )}
                    :*
                  </strong>
                </div>
                <select
                  key={triggerCriteria}
                  className="form-control t-12 mb-1"
                  data-cy="triggerCriteriaButton"
                  value={triggerCriteria}
                  onChange={(e) => {
                    this.setState({
                      triggerCriteria: e.target.value,
                    });
                  }}
                >
                  <option value="overdue_balance">
                    {getLocaleString(
                      "receivables.overdue_balance",
                      "Overdue balance"
                    )}
                  </option>
                  <option value="overdue_days">
                    {getLocaleString(
                      "receivables.overdue_days",
                      "Overdue days"
                    )}
                  </option>
                </select>
                {triggerCriteria === "overdue_balance" ? (
                  <input
                    id={triggerCriteria}
                    type="number"
                    placeholder={getLocaleString(
                      "receivables.enter_overdue_balance",
                      "Enter Overdue Balance"
                    )}
                    className="form-control t-12"
                    value={overdue_balance}
                    name="overdue_balance"
                    onChange={this.onTaskDetailsChange}
                  />
                ) : (
                  <input
                    id={triggerCriteria}
                    type="number"
                    name="overdue_days"
                    className="form-control t-12"
                    placeholder={getLocaleString(
                      "receivables.enter_overdue_days",
                      "Enter Overdue Days"
                    )}
                    value={overdue_days}
                    onChange={this.onTaskDetailsChange}
                  />
                )}
                {get(showErrors, "overdue_balance", "") ? (
                  <div className="error t-12">
                    {getLocaleString(
                      "receivables.overdue_balance_is_required",
                      "Overdue balance is required"
                    )}
                  </div>
                ) : null}
                {get(showErrors, "overdue_days", "") ? (
                  <div className="error t-12">
                    {getLocaleString(
                      "receivables.overdue_days_is_required",
                      "Overdue days is required"
                    )}
                  </div>
                ) : null}
              </div>
            )}
            {triggerEvent !== "5" ? (
              <div className="mb-3">
                <div className="w-45 dropdown d-inline-block me-2 align-top">
                  <strong>
                    {getLocaleString("customer.escalate_to", "Escalate To")}:
                  </strong>
                  <div
                    className="t-14 dropdown-toggle py-1 px-2 form-control"
                    role="button"
                    id="dropdownMenuLink"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                    onClick={() => {
                      this.setState({
                        openEscalationDropdown: !openEscalationDropdown,
                      });
                    }}
                  >
                    {escalateDropdownSelection.some((d) => d.is_selected)
                      ? escalateDropdownSelection
                          .filter((d) => d.is_selected)
                          .map((d) => d.name)
                          .join(", ")
                      : getLocaleString("common.select", "Select")}
                  </div>
                  <ClickAwayListener
                    onClickAway={() =>
                      this.setState({ openEscalationDropdown: false })
                    }
                  >
                    <div
                      className={`cursor-pointer dropdown-menu p-0 ${
                        openEscalationDropdown ? "show" : ""
                      }`}
                      id="companylist"
                    >
                      {escalateDropdownSelection.map((d) => (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            this.setState({
                              escalateDropdownSelection:
                                escalateDropdownSelection.map((e) =>
                                  d.key === e.key
                                    ? { ...e, is_selected: !e.is_selected }
                                    : e
                                ),
                            });
                          }}
                          key={d.key}
                          className={
                            d.is_selected ? "bg-option-selected-grey" : ""
                          }
                        >
                          <input
                            type="checkbox"
                            className="me-2 d-inline-block align-middle"
                            name={d.key}
                            checked={d.is_selected}
                            data-tlabel={`checkbox ${d.key}`}
                          />
                          <span className="d-inline-block align-middle p-0 w-80">
                            {d.name}
                          </span>
                        </span>
                      ))}
                    </div>
                  </ClickAwayListener>
                </div>
                {isOthersEscalationSelected ? (
                  <div className="w-50 d-inline-block align-bottom">
                    <EmailInput
                      data-testid="emailIds"
                      email={othersEmailAddress}
                      getEmailId={(_, email) =>
                        this.setState({
                          othersEmailAddress: email,
                        })
                      }
                      label="Others"
                    />
                  </div>
                ) : null}
              </div>
            ) : null}
            <div className="mb-2">
              {triggerEvent === "1" && (
                <label className="me-5">
                  <input
                    type="checkbox"
                    onChange={(e) => {
                      this.setState({
                        is_recurring: e.target.checked,
                      });
                    }}
                    defaultChecked={is_recurring}
                    className="md-checkbox align-middle me-2"
                  />
                  {getLocaleString("payroll.recurring", "Recurring")}
                </label>
              )}
              {triggerEvent !== "5" && (
                <>
                  <label>
                    <input
                      type="checkbox"
                      onChange={(e) => {
                        this.setState({
                          is_pdf_required: e.target.checked,
                        });
                      }}
                      data-tlabel={`checkbox Requires invoice pdf`}
                      defaultChecked={is_pdf_required}
                      className="md-checkbox align-middle me-2"
                    />
                    {getLocaleString(
                      "customer.require_invoice_pdf",
                      "Require invoice PDF"
                    )}{" "}
                    <InfoBox
                      description={
                        <div>
                          {getLocaleString(
                            "customer.require_invoice_pdf_tooltip",
                            "Trigger this workflow step only if invoice PDF is available"
                          )}
                        </div>
                      }
                    />
                  </label>
                  {isSmsEnabled ? (
                    <label className="ms-2">
                      <input
                        type="checkbox"
                        onChange={(e) => {
                          this.setState({
                            is_sms_enabled: +e.target.checked,
                          });
                        }}
                        data-tlabel={`checkbox Send Text Message`}
                        checked={is_sms_enabled}
                        className="md-checkbox align-middle me-2"
                      />
                      {getLocaleString(
                        "customer.send_text_message",
                        "Send Text Message"
                      )}
                    </label>
                  ) : null}
                </>
              )}
            </div>
            {triggerEvent !== "5" ? (
              this.renderEmailModalBody(this.state)
            ) : (
              <div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {getLocaleString("common.title", "Title")}*
                      </label>
                      <div>
                        <input
                          // disabled={isEditDisabled}
                          type="text"
                          data-testid="title"
                          className="form-control"
                          name="title"
                          value={title}
                          onChange={this.onTaskDetailsChange}
                        />
                      </div>
                      {get(showErrors, "title", "") ? (
                        <div className="error t-12">
                          {!title
                            ? getLocaleString(
                                "common.title_is_required",
                                "Title is required"
                              )
                            : title.length > 250
                              ? `${getLocaleString(
                                  "common.max_character_limit",
                                  "Max character limit"
                                )}: 250`
                              : ""}
                        </div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-12">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {getLocaleString("common.description", "Description")}
                      </label>
                      <textarea
                        // disabled={isEditDisabled}
                        data-cy="task-description"
                        className="p-2 w-100 form-control"
                        name="description"
                        value={description}
                        onChange={this.onTaskDetailsChange}
                      />
                      {showErrors.description ? (
                        <span className="error t-12">
                          {getLocaleString(
                            "common.max_character_limit",
                            "Max character limit"
                          )}
                          : 1000
                        </span>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {getLocaleString("common.task_type", "Task Type")}*
                      </label>
                      <select
                        // disabled={isEditDisabled}
                        data-testid="task_type"
                        className="form-control"
                        name="task_type"
                        value={task_type}
                        onChange={this.onTaskDetailsChange}
                      >
                        <option value="TODO">
                          {getLocaleString("common.to_do", "To do")}
                        </option>
                        <option value="CALL">
                          {getLocaleString("common.call", "Call")}
                        </option>
                        <option value="EMAIL">
                          {getLocaleString("common.email", "Email")}
                        </option>
                        <option value="OTHER">
                          {getLocaleString("common.other", "Other")}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {getLocaleString("common.priority", "Priority")}*
                      </label>
                      <select
                        // disabled={isEditDisabled}
                        className="form-control"
                        data-testid="priority"
                        name="task_priority"
                        value={task_priority}
                        onChange={this.onTaskDetailsChange}
                      >
                        <option value="CRITICAL">
                          {getLocaleString("common.critical", "Critical")}
                        </option>
                        <option value="HIGH">
                          {getLocaleString("common.high", "High")}
                        </option>
                        <option value="MEDIUM">
                          {getLocaleString("common.medium", "Medium")}
                        </option>
                        <option value="LOW">
                          {getLocaleString("common.low", "Low")}
                        </option>
                      </select>
                    </div>
                  </div>
                  <div className="col-md-6">
                    <div className="form-group mb-3">
                      <label className="form-label">
                        {getLocaleString("common.assigned_to", "Assigned to")}
                      </label>
                      <Select
                        placeholder={getLocaleString(
                          "common.assigned_to",
                          "Assigned to"
                        )}
                        id="assigned_to"
                        isSearchable={true}
                        menuPlacement="top"
                        isMulti={true}
                        options={userList}
                        value={
                          assigned_to && typeof assigned_to[0] !== "object"
                            ? userList &&
                              userList.filter((user) =>
                                assigned_to.includes(get(user, "value", ""))
                              )
                            : assigned_to
                        }
                        onChange={(e) => {
                          if (e && e.some((d) => d.value === 0)) {
                            this.setState({
                              task_details: {
                                ...this.state.task_details,
                                assigned_to: userList.filter(
                                  (d) => d.value !== 0
                                ),
                              },
                            });
                          } else {
                            this.setState({
                              task_details: {
                                ...this.state.task_details,
                                assigned_to: e ? e.map((d) => d.value) : [],
                              },
                            });
                          }
                        }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
          </div>
        )}
      </Modal>
    );
  }
}
