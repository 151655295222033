/* eslint-disable react-hooks/exhaustive-deps */
import "assets/scss/table.scss";
import { connect } from "react-redux";
import Header from "components/header";
import LeftNav from "components/leftnav";
import Reloading from "components/Reloading";
import { refresh_tag } from "components/Tag";
import backlog from "containers/backlog";
import BankReconcile from "containers/bank-reconcile";
import help from "containers/help";
import { trackEvent } from "utils/loggly-tracker";
import CustomerPortalWrapper from "containers/customer-login";
import VendorInvoicingPortal from "containers/vendors/invoicing-portal";
import customers from "containers/customers";
import dashboard from "containers/dashboard";
import insights from "containers/insights";
import nonInvoicedExpenses from "containers/nonInvoicedExpenses";
import payables from "containers/payables";
import payroll from "containers/payroll";
import PurchaseOrder from "containers/purchaseOrder";
import receivables from "containers/receivables";
import reports from "containers/reports";
import settings from "containers/settings";
import company from "containers/settings/company/index";
import customizeDashboard from "containers/settings/customize-dashboard";
import data from "containers/settings/data";
import payablesSettings from "containers/settings/payables/index";
import Currency from "containers/settings/payroll/Currency";
import Entity from "containers/settings/payroll/EntityNew";
import FundingDate from "containers/settings/payroll/FundingDate";
import PaySchedule from "containers/settings/payroll/PaySchedule";
import profile from "containers/settings/profile/index";
import ARworkflow from "containers/settings/receivables/ARworkflow";
import InvoicingTemplate from "containers/settings/receivables/InvoicingTemplates";
import receivablesSettings from "containers/settings/receivables/index";
import support from "containers/support";
import vendors from "containers/vendors";
import analyticReport from "containers/analytics-report";
import Journal from "containers/journal-entries";
import get from "lodash/get";
import CustomSpinner from "components/Spinner";
import React, { useEffect, useState } from "react";
import { Route, Switch } from "react-router-dom";
import TokenManager from "utils/TokenManager";
import { loginAndRedirectByRoute } from "utils/token";
import CreditApplication from "containers/customers/CreditApplication";
import SavedSearch from "containers/saved_search";
import CashApplication from "containers/cash_application";
import AlevateCashApplication from "containers/alevate-cashapp";
import Constants from "constants/index";

function CustomerNavWrapper() {
  return (
    <Switch>
      <Route path="" component={CustomerPortalWrapper} />
    </Switch>
  );
}

function VendorInvoicingWrapper() {
  return (
    <Switch>
      <Route path="" component={VendorInvoicingPortal} />
    </Switch>
  );
}

function AppNavWrapper(props) {
  const [redirectingToClient, setIsRedirectingToClient] = useState(false);
  const pathname = get(props, "location.pathname", "");
  const [lastNavigation, setLastNavigation] = useState("");
  const {
    clientFeatures: {
      CLIENT_CASHAPP_OLD_ENABLED,
      CLIENT_JOURNAL_SECTION_ENABLED,
      CLIENT_PAYROLL_SECTION_ENABLED,
      CLIENT_CASHAPP_SECTION_ENABLED,
      CLIENT_CASHAPP_SERRALA_ENABLED,
      CLIENT_CASHFLOW_SECTION_ENABLED,
      CLIENT_PAYABLES_SECTION_ENABLED,
      CLIENT_RECEIVABLES_SECTION_ENABLED,
    },
  } = props;

  useEffect(() => {
    // load repeating datapoints in redux
    refresh_tag();
    const data = JSON.parse(TokenManager.getUserData());
    if (get(data, "client_id", "") === 10) {
      props.history.push("/signup");
    }
    const { location } = props;
    const urlParams = location.hash || location.search;
    if (
      location &&
      !urlParams.includes("customers") &&
      urlParams.includes("token") &&
      urlParams.includes("redirect")
    ) {
      setIsRedirectingToClient(true);
      loginAndRedirectByRoute(props, () => setIsRedirectingToClient(false));
    }
  }, []);

  useEffect(() => {
    lastNavigation &&
      trackEvent(
        `Navigated from ${lastNavigation} to ${pathname}`,
        "navigation"
      );
    setLastNavigation(pathname);
  }, [pathname]);

  const arRepRole =
    TokenManager.getIsAR_REP() || TokenManager.isReadOnlyARUser();
  const apRepRole = TokenManager.getIsAP_REP();
  const driverAdminRole = TokenManager.getDriverAdmin();
  const readOnlyAdmin =
    TokenManager.isReadOnlyAdmin() && !TokenManager.isReadOnlyARUser();

  const routes = [
    {
      path: "/",
      Component: driverAdminRole ? vendors : apRepRole ? payables : dashboard,
      show:
        driverAdminRole || apRepRole ? CLIENT_PAYABLES_SECTION_ENABLED : true,
      exact: true,
    },
    {
      path: "/dashboard",
      Component: dashboard,
      show: !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/payables",
      Component: payables,
      show: CLIENT_PAYABLES_SECTION_ENABLED && !arRepRole,
      exact: false,
    },
    {
      path: "/non-invoiced-expenses",
      Component: nonInvoicedExpenses,
      show:
        CLIENT_PAYABLES_SECTION_ENABLED &&
        !arRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/payroll",
      Component: payroll,
      show:
        CLIENT_PAYROLL_SECTION_ENABLED &&
        !arRepRole &&
        !apRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/receivables",
      Component: receivables,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/customers",
      Component: customers,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !apRepRole && !driverAdminRole,
      exact: true,
    },
    {
      path: "/customers/rel/:id",
      Component: customers,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/customer/rel/:id",
      Component: customers,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/customers/:id",
      Component: customers,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/vendors",
      Component: vendors,
      show: CLIENT_PAYABLES_SECTION_ENABLED && !arRepRole,
      exact: true,
    },
    {
      path: "/vendors/rel/:id",
      Component: vendors,
      show: CLIENT_PAYABLES_SECTION_ENABLED && !arRepRole,
      exact: false,
    },
    {
      path: "/vendors/:id",
      Component: vendors,
      show: CLIENT_PAYABLES_SECTION_ENABLED && !arRepRole,
      exact: false,
    },
    {
      path: "/settings",
      Component: driverAdminRole ? company : settings,
      show: true,
      exact: true,
    },
    {
      path: "/reports",
      Component: reports,
      show:
        CLIENT_CASHFLOW_SECTION_ENABLED &&
        !apRepRole &&
        !driverAdminRole &&
        (!readOnlyAdmin || TokenManager.isReadOnlyUser()),
      exact: true,
    },
    {
      path: "/insights",
      Component: insights,
      show: !arRepRole && !apRepRole && !driverAdminRole,
      exact: true,
    },
    {
      path: "/backlog",
      Component: backlog,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED && !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/purchase-orders",
      Component: PurchaseOrder,
      show:
        CLIENT_PAYABLES_SECTION_ENABLED &&
        !arRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/reconcile",
      Component: BankReconcile,
      show:
        CLIENT_CASHFLOW_SECTION_ENABLED &&
        !arRepRole &&
        !apRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/journal",
      Component: Journal,
      show:
        CLIENT_JOURNAL_SECTION_ENABLED &&
        !arRepRole &&
        !apRepRole & !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/profile",
      Component: profile,
      show: true,
      exact: false,
    },
    {
      path: "/settings/customize-dashboard",
      Component: customizeDashboard,
      show: !apRepRole && !driverAdminRole,
      exact: false,
    },
    {
      path: "/settings/company",
      Component: company,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/payables",
      Component: payablesSettings,
      show:
        CLIENT_PAYABLES_SECTION_ENABLED &&
        !arRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/receivables/ar-workflow/:id?",
      Component: ARworkflow,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/receivables/invoice-templates",
      Component: InvoicingTemplate,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED &&
        !arRepRole &&
        !apRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/receivables",
      Component: receivablesSettings,
      show:
        CLIENT_RECEIVABLES_SECTION_ENABLED &&
        !arRepRole &&
        !apRepRole &&
        !driverAdminRole &&
        !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/payroll/entity",
      Component: Entity,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/payroll/pay_schedule",
      Component: PaySchedule,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/payroll/funding_date",
      Component: FundingDate,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/payroll/currency",
      Component: Currency,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/settings/data",
      Component: data,
      show: !arRepRole && !apRepRole && !driverAdminRole && !readOnlyAdmin,
      exact: false,
    },
    {
      path: "/help",
      Component: help,
      show: true,
      exact: false,
    },
    {
      path: "reloading",
      Component: Reloading,
      show: true,
      exact: false,
    },
    TokenManager.getIsPayAdmin() && {
      path: "/support",
      Component: support,
      show: !arRepRole,
    },
    {
      path: "/analytics",
      Component: analyticReport,
      show: true,
    },
    {
      path: "/credit-application",
      Component: CreditApplication,
      show: true,
    },
    {
      path: "/saved-search",
      Component: SavedSearch,
      show: true,
    },
    {
      path: "/cash-application",
      Component: CashApplication,
      show:
        CLIENT_CASHAPP_SECTION_ENABLED &&
        CLIENT_CASHAPP_OLD_ENABLED &&
        TokenManager.getAllAdmins(),
    },
    {
      path: Constants.CashAppRoute,
      Component: AlevateCashApplication,
      show:
        CLIENT_CASHAPP_SECTION_ENABLED &&
        CLIENT_CASHAPP_SERRALA_ENABLED &&
        TokenManager.getAllAdmins(),
    },
    {
      path: "**",
      Component: () => (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
            transform: "translateY(40vh)",
          }}
        >
          Not Found
        </div>
      ),
      show: true,
    },
  ];

  return (
    <div className="container-fluid bg-light-grey" id="main-body">
      <Header {...props} />
      <div className="row otherbody" id="application-wrapper">
        <LeftNav />
        <div className="col pay-rec-wrap mp-0">
          <Switch>
            {routes
              .filter((d) => d.show)
              .map(({ path, Component, exact }) => (
                <Route
                  key={path}
                  path={path}
                  exact={exact}
                  component={Component}
                />
              ))}
          </Switch>
        </div>
        {redirectingToClient && (
          <CustomSpinner isfullscreen classNames="position-absolute" />
        )}
      </div>
    </div>
  );
}

function NavWrapper(props) {
  const isCustomer = TokenManager.isCustomer();
  const isVendor = TokenManager.isVendor();
  if (isVendor) {
    return <VendorInvoicingWrapper {...props} />;
  } else if (isCustomer) {
    return <CustomerNavWrapper {...props} />;
  } else {
    return <AppNavWrapper {...props} />;
  }
}

const mapStateToProps = (state) => ({
  user: state.user,
  clientFeatures: state.configurableSettings.clientFeatures,
});

export default connect(mapStateToProps, null)(NavWrapper);
