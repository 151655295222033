const states = {
  CONNECTING: "Connecting",
  READY: "Ready",
  INCOMING: "Incoming",
  OUTGOING: "Outgoing",
  RINGING: "RINGING",
  ON_CALL: "IN_PROGRESS",
  OFFLINE: "Offline",
};

export default states;
