import { instanceWithRetry } from "actions/axiosInstance";
import { ReducersTypes } from "constants/ReducersTypes";
import get from "lodash/get";
import { store } from "store";

export const refresh_tag = (isMutate = false) => {
  return instanceWithRetry
    .get(isMutate ? "tag?mutate=1" : "tag")
    .then((resp) => {
      set_tag({ allTags: get(resp, "data.result", []) });
      localStorage.setItem(
        "tag_list",
        JSON.stringify(get(resp, "data.result", []))
      );
      return get(resp, "data.result", []);
    });
};

export const set_tag = (data) => {
  store.dispatch({
    type: ReducersTypes.SET_TAG,
    payload: data,
  });
};
