import React from "react";
import { useWindowSize } from "handlers/getters";
import { getLocaleString } from "utils/localization/locale";

export const Component = ({
  message = getLocaleString("common.nodataFound", "No data available."),
  width = 320,
  height = 320,
}) => {
  const [windowWidth] = useWindowSize();
  const isMobile = windowWidth <= 768;
  const divider = isMobile ? 1.5 : 1;
  return (
    <div className="w-100 h-100 d-flex justify-content-center">
      <div
        style={{ height: height / divider, width: width / divider }}
        className="my-auto d-flex justify-content-center"
      >
        <div className="my-auto">{message}</div>
      </div>
    </div>
  );
};

export default Component;
