import React, { Suspense, lazy } from "react";
import CodeSplitLoader from "components/CodeSplitLoader";

// const PageRef = lazy(() => import(/* webpackChunkName: "payablessettings" */ "./payableSettings"));
const PageRef = lazy(
  () => import(/* webpackChunkName: "payablessettings" */ "./payablesSettings")
);

const C = (p) => <Suspense fallback={<CodeSplitLoader />}>{p.Child}</Suspense>;
const Page = (p) => <C Child={<PageRef {...p} />} />;
export default Page;
