import moment from "moment";
import TokenManager from "utils/TokenManager";
import { ranges } from "components/DateRangePicker";
import { removeEmptyValueFromObject } from "utils";

export const Today = moment().format("MM/DD/YYYY");
export const Yesterday = moment().subtract(1, "days").format("MM/DD/YYYY");
export const Tomorrow = moment().add(1, "days").format("MM/DD/YYYY");

export const lowerDate = (date, startDay) => {
  const days_to_add = startDay + 1 === 7 ? 0 : startDay + 1;
  let result_date = moment(date, "MM/DD/YYYY").startOf("week");
  days_to_add && result_date.add(days_to_add, "days");
  // Toaster(`${date} -> ${result_date.format("MM/DD/YYYY")}`, "info")
  return result_date.format("MM/DD/YYYY");
};

export const getThisFormatDate = (
  date,
  ip_format = "MM/DD/YYYY",
  op_format = "YYYY-MM-DD"
) => {
  if (date) {
    return moment(date, ip_format).format(op_format) === "Invalid date" ||
      moment(date, ip_format).format(op_format) === null ||
      moment(date, ip_format).format(op_format) === "null"
      ? ""
      : moment(date, ip_format).format(op_format);
  }
  return null;
};

export const getUTCDate = (date) => {
  if (date) {
    const utcOffset = moment().utcOffset();
    if (date === Today && utcOffset < 0) {
      const utc_date = moment().utc().format("MM/DD/YYYY");
      if (utc_date !== date) {
        return Tomorrow;
      }
      return date;
    } else {
      return date;
    }
  }
  return null;
};

export const getFormattedDate = (
  date,
  ip_format = "YYYY-MM-DD",
  apiFormat = false
) => {
  if (date) {
    const op_format = apiFormat || TokenManager.getDateFormat();
    return moment(date).format(op_format) === "Invalid date" ||
      moment(date).format(op_format) === null ||
      moment(date).format(op_format) === "null"
      ? ""
      : moment(date, ip_format).format(op_format);
  }
  return null;
};

export const utcToLocal = (utc_date_string, withTime = false) => {
  if (utc_date_string) {
    return moment(moment.utc(utc_date_string).toDate())
      .local()
      .format(`${TokenManager.getDateFormat()} ${withTime || "hh:mm A"}`);
  }
  return null;
};

export function new_ts(date) {
  return moment(date || new Date())
    .toDate()
    .getTime();
}

export function ts_str(args = {}) {
  const { ts, timeformat = "MM/DD/YYYY" } = args;
  if (!ts) return "";
  return moment(ts).format(timeformat);
}

export function str_ts(args = {}) {
  const { str, timeformat = "MM/DD/YYYY" } = args;
  if (!str) return "";
  return moment(str, timeformat).toDate().getTime();
}

export function betweenDays(args = {}) {
  let { startdate = moment(), enddate = moment() } = args;
  enddate = moment.isMoment(enddate) ? enddate : moment(enddate);
  startdate = moment.isMoment(startdate) ? startdate : moment(startdate);
  const duration = moment.duration(enddate.diff(startdate));
  const res = duration.asDays();
  return parseInt(res);
}

export function getAllWeeksOfMonth(
  month,
  year = moment().format("YYYY"),
  format = "MM/DD/YYYY"
) {
  if (month === "") {
    return [];
  }
  month = moment().set("month", month).set("year", year).startOf("month");
  var first = month.day() === 0 ? 6 : month.day() - 1;
  var day = 7 - first;
  var last = month.daysInMonth();
  var count = (last - day) / 7;
  var weeks = [];
  weeks.push({
    start: month.set("date", 1).format(format),
    end: month.set("date", day).format(format),
  });
  for (var i = 0; i < count; i++) {
    weeks.push({
      start: month.set("date", day + 1).format(format),
      end: month.set("date", Math.min((day += 7), last)).format(format),
    });
  }
  return weeks;
}

export const dateExists = (date, start, end) =>
  moment(date).isBetween(moment(start), moment(end), undefined, "[]");

export const dateBefore = (end_date, current_date) =>
  moment(current_date).isSameOrBefore(moment(end_date), undefined);
export const dateAfter = (end_date, current_date) =>
  moment(current_date).isSameOrAfter(moment(end_date), undefined);
export const dateMinimum = (end_date, current_date) =>
  moment(current_date).isBefore(moment(end_date), undefined);
export const dateMaximum = (end_date, current_date) =>
  moment(current_date).isAfter(moment(end_date), undefined);

export const getPastAndFutureDates = (startDate, endDate) => {
  let prev =
    betweenDays({ startdate: moment(startDate), enddate: moment(Yesterday) }) >=
    0;
  let aft =
    betweenDays({ startdate: moment(Today), enddate: moment(endDate) }) >= 0;

  const getPastDates = () => {
    if (prev) {
      return [startDate, aft ? Yesterday : endDate];
    }
    return ["", ""];
  };

  const getFutureDates = () => {
    if (aft) {
      return [prev ? Today : startDate, endDate];
    }
    return ["", ""];
  };
  return [getPastDates(), getFutureDates()];
};

export function addBusinessDays(originalDate = moment(), numDaysToAdd = 0) {
  const Sunday = 0;
  const Saturday = 6;
  let daysRemaining = numDaysToAdd;

  const newDate = originalDate.startOf("day").clone();

  while (daysRemaining > 0) {
    newDate.add(1, "days");
    if (newDate.day() !== Sunday && newDate.day() !== Saturday) {
      daysRemaining--;
    }
  }

  return newDate;
}

export const setDayInMonth = (_date, _day) =>
  _day > _date.add(1, "month").daysInMonth()
    ? _date.endOf("month")
    : _date.set("date", _day);

export const setDayInQuarter = (_date, _day) =>
  _day > _date.add(1, "quarter")
    ? _date.endOf("quarter")
    : _date.set("date", _day);

export const setQuarterDaysInYear = (_date, _day) =>
  _date.add(12, "month").quarter(_day).startOf("quarter");

export const getFirstAndLastDateOfMonth = (
  dateArray = [],
  format = "MM/DD/YYYY"
) => {
  if (!dateArray.length) return;
  const dateMapped = dateArray.reduce((acc, cur) => {
    const dateObj = moment(cur, format);
    const monthYear = `${dateObj.format("MM")}_${dateObj.format("YYYY")}`;
    acc = {
      ...acc,
      [monthYear]: acc[monthYear] ? [...acc[monthYear], dateObj] : [dateObj],
    };
    return acc;
  }, {});
  return {
    firstDates: Object.keys(dateMapped).map((d) =>
      moment.min(dateMapped[d]).format(format)
    ),
    lastDates: Object.keys(dateMapped).map((d) =>
      moment.max(dateMapped[d]).format(format)
    ),
  };
};

// get date as per saved interval in filters from daterange picker
export const getDateByRangeInterval = (
  interval,
  defaultFromDate,
  defaultToDate
) => {
  const fromDate =
    defaultFromDate && moment(defaultFromDate, "MM/DD/YYYY").isValid()
      ? moment(defaultFromDate, "MM/DD/YYYY")
      : moment();
  const toDate =
    defaultToDate && moment(defaultToDate, "MM/DD/YYYY").isValid()
      ? moment(defaultToDate, "MM/DD/YYYY")
      : moment();
  const [start, end] =
    interval && interval !== "Custom Range" && ranges[interval]
      ? ranges[interval]
      : [fromDate, toDate];
  return [start.format("MM/DD/YYYY"), end.format("MM/DD/YYYY")];
};

export const getTimeZoneDetails = () => {
  let offset = new Date().getTimezoneOffset();
  offset =
    (offset < 0 ? "+" : "-") +
    parseInt(Math.abs(offset / 60)) +
    ":" +
    Math.abs(offset % 60);
  const name = Intl.DateTimeFormat().resolvedOptions().timeZone;
  return {
    offset,
    name,
  };
};

export const convertToNSDateFormat = (date, format = "MM/DD/YYYY") => {
  if (!date) {
    return "";
  }
  return moment(date, format).format("MM%2FDD%2FYYYY");
};

export const convertNSDateToMomenFormat = (date, format = "MM%2FDD%2FYYYY") => {
  if (!date) {
    return "";
  }
  return moment(date, format);
};

export const generateAgingBuckets = (
  apiData = [],
  startDate,
  endDate,
  isInvoiceDate = false
) => {
  const COLORS = [
    "#2B7EF6",
    "#7CA82D",
    "#EF853A",
    "#DB504D",
    "#424348",
    "#90ED7D",
    "#3C861F",
    "#6666FF",
    "#FFB399",
    "#FF1A66",
    "#E666FF",
    "#B3B31A",
  ];
  const defaultBuckets = [
    {
      name: isInvoiceDate ? "< 0 Days" : "Current",
      dataindex: 0,
      from_date: isInvoiceDate
        ? moment().add(1, "days").format("MM/DD/YYYY")
        : moment().format("MM/DD/YYYY"),
      is_future: true,
      to_date: endDate,
      color: COLORS[0],
    },
    {
      name: "Past 1-30 Days",
      dataindex: 1,
      from_date: moment().subtract(30, "days").format("MM/DD/YYYY"),
      to_date: moment().subtract(1, "days").format("MM/DD/YYYY"),
      color: COLORS[1],
    },
    {
      name: "Past 31-60 Days",
      dataindex: 2,
      from_date: moment().subtract(60, "days").format("MM/DD/YYYY"),
      to_date: moment().subtract(31, "days").format("MM/DD/YYYY"),
      color: COLORS[2],
    },
    {
      name: "Past 61-90 Days",
      dataindex: 3,
      from_date: moment().subtract(90, "days").format("MM/DD/YYYY"),
      to_date: moment().subtract(61, "days").format("MM/DD/YYYY"),
      color: COLORS[3],
    },
    {
      name: "Past > 90 Days",
      dataindex: 4,
      is_past: true,
      from_date: startDate,
      to_date: moment().subtract(91, "days").format("MM/DD/YYYY"),
      color: COLORS[4],
    },
  ];

  if (apiData.length) {
    const apiBuckets = [
      {
        name: isInvoiceDate ? "< 0 Days" : "Current",
        dataindex: 0,
        from_date: isInvoiceDate
          ? moment().add(1, "days").format("MM/DD/YYYY")
          : moment().format("MM/DD/YYYY"),
        to_date: endDate,
        color: COLORS[0],
      },
    ].concat(
      apiData
        .sort((a, b) => a.min_days - b.min_days)
        .map((d, i) =>
          removeEmptyValueFromObject({
            name: d.bucket_name,
            dataindex: i + 1,
            from_date: d.max_days
              ? moment().subtract(d.max_days, "days").format("MM/DD/YYYY")
              : "",
            to_date: d.min_days
              ? moment().subtract(d.min_days, "days").format("MM/DD/YYYY")
              : endDate,
            color: COLORS[i + 1],
          })
        )
    );
    return apiBuckets;
  }
  return defaultBuckets;
};

export const loadMomentLocale = async (locale) => {
  try {
    if (locale) {
      const localeLower = locale.toLowerCase();
      await import(`moment/locale/${localeLower}.js`);
      moment.locale(locale);
    }
  } catch (error) {
    console.error(`Error loading moment locale for ${locale}`, error);
  }
};

export const utcToLocalTime = (utc_date_string, hourFormat = false) => {
  if (utc_date_string) {
    return moment(moment.utc(utc_date_string).toDate())
      .local()
      .format(`hh:mm:ss ${hourFormat ? "" : "A"}`);
  }
  return null;
};
