import React, { memo } from "react";
import { Modal } from "react-bootstrap";
import { trackEvent } from "utils/loggly-tracker";

function CustomModal(props) {
  const {
    show,
    size = "md",
    title,
    footer,
    onHide,
    children,
    wrapperClassName = "",
    modalBodyClassName = "",
    modalBodyStyle = "",
    ...rest
  } = props;
  return (
    <Modal
      show={show}
      size={size}
      onHide={() => {
        onHide(false);
        trackEvent(`close ${title} popup`, "click");
      }}
      {...rest}
      className={`mt-4 ${wrapperClassName}`}
    >
      <Modal.Header closeButton>
        {title ? <Modal.Title className="h6">{title}</Modal.Title> : null}
      </Modal.Header>
      <Modal.Body style={{ ...modalBodyStyle }} className={modalBodyClassName}>
        {children}
      </Modal.Body>
      {footer && footer() ? <Modal.Footer>{footer()}</Modal.Footer> : null}
    </Modal>
  );
}

export default memo(CustomModal);
