import { handleAPIError } from "handlers/setters";
import omit from "lodash/omit";
import get from "lodash/get";
import { getPastAndFutureDates } from "utils/dates";
import { trimText } from "utils/strings";
import { ReducersTypes } from "constants/ReducersTypes";
import { instanceWithRetry } from "actions/axiosInstance";
import {
  setReducerData,
  convertDecimalNo,
  getEnabledKeysValue,
  replaceQuotes,
  removeEmptyValueFromObject,
  getRoundedIntegerNo,
} from "utils";
import mapValues from "lodash/mapValues";
import { generateQueryString } from "utils/query";
import { setuxvalue, getuxvalue } from "handlers/ux";
import { getAgingTag } from "containers/customers/listnew/actions";
import {
  customFieldExportColumns,
  rearrangeCSVColumns,
} from "utils/table-utils";
import { supplierListColumns } from "./reducer";
import { sendExportedReport } from "actions/global";
import { getLocaleString } from "utils/localization/locale";

export const getVendorList =
  (appliedFilters, exportType) => async (dispatch) => {
    const listingKey = exportType ? "exportedData" : "vendorList";
    const fetchingFlag = exportType ? "isExporting" : "isVendorListFetching";

    dispatch(
      setReducerData(ReducersTypes.VENDOR_LIST, {
        [fetchingFlag]: true,
      })
    );

    const {
      page,
      sort_by,
      to_date,
      per_page,
      category,
      from_date,
      activeKey,
      sort_order,
      with_notes,
      ap_category,
      customFilters,
      visibleColumns,
      vendor_category_id,
      appliedCustomFilters,
    } = appliedFilters;

    const ids = await getEnabledKeysValue(customFilters, visibleColumns);

    const queryFilters = {
      page,
      sort_by,
      per_page,
      sort_order,
      with_notes,
      is_export: exportType ? 1 : "",
      [exportType ? "send_custom_field_master_id" : "send_custom_fields"]:
        ids.length ? 1 : "",
    };
    let URL = `apinvoice/grouped${generateQueryString(queryFilters)}`;

    const [due_dates, notdue_dates] = getPastAndFutureDates(from_date, to_date);

    let filters = [
      {
        from_date: from_date,
        to_date: to_date,
        label_name: "total_amount_due",
        status: "open",
        subtract_credit_and_unapplied_payments: 1,
      },
      {
        from_date: due_dates[0],
        to_date: due_dates[1],
        label_name: "amount_due",
        status: "open",
      },
      {
        from_date: notdue_dates[0],
        to_date: notdue_dates[1],
        label_name: "amount_not_due",
        status: "open",
      },
    ];

    const hideColumns = [];
    if (due_dates.some((d) => !d)) {
      hideColumns.push("amount_due");
    } else if (notdue_dates.some((d) => !d)) {
      hideColumns.push("amount_not_due");
    }
    dispatch({
      type: ReducersTypes.VENDOR_LIST_UPDATE,
      payload: {
        hideColumns,
      },
    });

    if (activeKey === "payrollGraph" && vendor_category_id) {
      filters = filters.map((x) => ({
        ...x,
        vendor_category_id,
      }));
      filters[0]["status"] = "closed";
      filters[0]["payment_plan"] = "exclude";
      filters[0]["subtract_credit_and_unapplied_payments"] = undefined;
    }
    if (activeKey === "vendorSpending" && category) {
      filters = filters.map((d) => ({
        ...d,
        category,
      }));
    }

    if (activeKey === "apInvoiceGraph" && ap_category) {
      if (ap_category === "paid") {
        filters = filters.map((d) => ({
          ...d,
          status: "closed",
        }));
      } else if (ap_category === "l30") {
        filters = filters.map((d) => ({
          ...d,
          status: "all",
          category: "4",
        }));
      } else if (ap_category === "g30") {
        filters = filters.map((d) => ({
          ...d,
          status: "all",
          category: "2",
        }));
      }
    }

    const excluded_tag_id = activeKey === "apaging" ? getAgingTag() : false;
    if (excluded_tag_id) {
      filters = filters.map((d) => ({
        ...d,
        not_tag_ids: excluded_tag_id.toString(),
      }));
    }

    if (appliedCustomFilters && Object.keys(appliedCustomFilters).length) {
      let customFilterKeyVals = {};
      let masterId = [];
      customFilterKeyVals["custom_field_master_id"] =
        Object.keys(appliedCustomFilters).join();
      Object.keys(appliedCustomFilters).forEach((d) => {
        const filter = appliedCustomFilters[d];
        let flag = false;
        Object.keys(filter).forEach((f) => {
          if (f !== "custom_field_master_id" && filter[f]) {
            customFilterKeyVals[f] = filter[f];
            flag = true;
          }
        });
        if (flag) {
          masterId.push(d);
          flag = false;
        }
      });
      if (masterId.length) {
        filters = filters.map((d) => ({
          ...omit(d, "get_all"),
          ...customFilterKeyVals,
          custom_field_master_id: masterId.join(),
        }));
      }
    }

    if (get(appliedFilters, "isEmailExport", false)) {
      return sendExportedReport(
        removeEmptyValueFromObject({
          ...queryFilters,
          filters: filters.filter((x) => x.from_date || x.to_date),
          export_type: "csv",
          page_type: "supplier",
        })
      );
    }
    return instanceWithRetry
      .post(URL, {
        filters: filters.filter((x) => x.from_date || x.to_date),
      })
      .then((response) => {
        dispatch(
          setReducerData(ReducersTypes.VENDOR_LIST, {
            [fetchingFlag]: false,
            [listingKey]: response.data.result,
            totalRecords: response.data.total,
          })
        );
        return response.data.result;
      })
      .catch((e) => {
        dispatch(
          setReducerData(ReducersTypes.VENDOR_LIST, {
            [fetchingFlag]: false,
            [listingKey]: [],
          })
        );
        handleAPIError(e);
        return [];
      });
  };

export const searchVendor = (text, callback) => {
  if (text) {
    callback({
      isVendorListFetching: true,
      vendorSearchList: [],
    });
    instanceWithRetry
      .get(`supplier/rel/search?name=${trimText(text)}&per_page=15`)
      .then((res) => {
        callback({
          isVendorListFetching: false,
          vendorSearchList: res.data.result,
        });
      })
      .catch((e) => {
        callback({
          isVendorListFetching: false,
          vendorSearchList: [],
        });
        handleAPIError(e);
      });
  }
};

export const getCustomFieldDropdownData = (data, callback) => {
  instanceWithRetry
    .get(`/customfield${generateQueryString(data)}`)
    .then((response) => {
      callback &&
        callback(
          response.data.result
            .filter((d) => d)
            .map((d) => ({ label: d, value: d }))
        );
    })
    .catch((e) => {
      handleAPIError(e);
      callback && callback([]);
    });
};

function filterProperties(originalObj, visibleHeaders) {
  Object.keys(visibleHeaders).forEach((key) => {
    if (!visibleHeaders[key]) delete originalObj[key];
  });
  return originalObj;
}

export function ExportCSV(
  data,
  visibleColumns = {},
  hideColumns = [],
  customFilters,
  exportAllColumns,
  columnOrder = {}
) {
  let visibleHeaders = {
    [getLocaleString("common.vendor", "Vendor")]:
      visibleColumns[getLocaleString("common.vendor", "Vendor")],
    [getLocaleString("common.category", "Category")]:
      visibleColumns[getLocaleString("common.category", "Category")],
    [getLocaleString("dashboard.current", "Current")]:
      !hideColumns.includes("amount_not_due") &&
      visibleColumns[getLocaleString("dashboard.current", "Current")],
    [getLocaleString("customer.overdue", "Overdue")]:
      !hideColumns.includes("amount_due") &&
      visibleColumns[getLocaleString("customer.overdue", "Overdue")],
    [getLocaleString("dashboard.total_balance", "Total Balance")]:
      visibleColumns[
        getLocaleString("dashboard.total_balance", "Total Balance")
      ],
    [getLocaleString("reconcile.credit", "Credit")]:
      visibleColumns[getLocaleString("reconcile.credit", "Credit")],
    [getLocaleString("common.unapplied_payments", "Unapplied Payments")]:
      visibleColumns[
        getLocaleString("common.unapplied_payments", "Unapplied Payments")
      ],
    [getLocaleString("dashboard.avg_days_late", "Avg Days Late")]:
      visibleColumns[
        getLocaleString("dashboard.avg_days_late", "Avg Days Late")
      ],
    [getLocaleString("dashboard.wt_avg_days_late", "Weighted(avg) Days Late")]:
      visibleColumns[
        getLocaleString("dashboard.wt_avg_days_late", "Weighted(avg) Days Late")
      ],
    [getLocaleString("dashboard.payment_terms", "Payment Terms")]:
      visibleColumns[
        getLocaleString("dashboard.payment_terms", "Payment Terms")
      ],
    [getLocaleString("common.internal_id", "Internal ID")]:
      visibleColumns[getLocaleString("common.internal_id", "Internal ID")],
    [getLocaleString("common.notes", "Notes")]:
      visibleColumns[getLocaleString("common.notes", "Notes")],
  };
  customFilters.forEach((field) => {
    visibleHeaders[get(field, "erp_field_name", "")] =
      visibleColumns[get(field, "erp_field_name", "")];
  });

  if (exportAllColumns) {
    visibleHeaders = mapValues(visibleHeaders, () => true);
  }
  if (data && !data.length) {
    return [
      filterProperties(
        {
          ...mapValues(visibleHeaders, () => ""),
        },
        visibleHeaders
      ),
    ];
  }
  const csvData = data.map((element) => {
    let ele = {
      [getLocaleString("common.vendor", "Vendor")]: replaceQuotes(
        get(element, "name", "")
      ),
      [getLocaleString("common.category", "Category")]: replaceQuotes(
        get(element, "vendor_category_name", "")
      ),
      [getLocaleString("dashboard.current", "Current")]: convertDecimalNo(
        get(element, "amount_not_due", 0)
      ),
      [getLocaleString("customer.overdue", "Overdue")]: convertDecimalNo(
        get(element, "amount_due", 0)
      ),
      [getLocaleString("dashboard.total_balance", "Total Balance")]:
        convertDecimalNo(get(element, "total_amount_due", 0)),
      [getLocaleString("reconcile.credit", "Credit")]: convertDecimalNo(
        get(element, "aggregate_credit", 0)
      ),
      [getLocaleString("common.unapplied_payments", "Unapplied Payments")]:
        convertDecimalNo(get(element, "unapplied_payment", 0)),
      [getLocaleString("dashboard.avg_days_late", "Avg Days Late")]:
        getRoundedIntegerNo(get(element, "average_days_late", "")),
      [getLocaleString(
        "dashboard.wt_avg_days_late",
        "Weighted(avg) Days Late"
      )]: getRoundedIntegerNo(get(element, "weighted_avg_days_late", "")),
      [getLocaleString("dashboard.payment_terms", "Payment Terms")]:
        `Net ${get(element, "payment_terms", "") || 0}`,
      [getLocaleString("common.internal_id", "Internal ID")]: get(
        element,
        "erp_internal_id",
        ""
      ),
      [getLocaleString("common.notes", "Notes")]: get(
        element,
        "vendor_notes",
        []
      ).length
        ? get(element, "vendor_notes", [])
            .map((d, i) => `${i + 1}. ${get(d, "note", "")}`)
            .join("\n")
        : "",
    };
    ele = {
      ...ele,
      ...customFieldExportColumns(customFilters, element),
    };

    const obj = filterProperties(ele, visibleHeaders);
    return rearrangeCSVColumns(columnOrder, obj);
  });

  return csvData;
}

export const getCustomFieldFilters =
  (getList = () => {}) =>
  (dispatch) => {
    instanceWithRetry
      .get("customfields?model_name=APInvoiceHeader,ClientSupplierRelationship")
      .then(async (res) => {
        const result = res.data.result;
        const allPromises = result
          .filter((d) => d.field_type === "STRING")
          .map(async (d) => {
            return {
              id: d.id,
              data: await instanceWithRetry.get(
                `/customfield${generateQueryString({
                  custom_field_master_id: d.id,
                  per_page: 10,
                })}`
              ),
            };
          });
        const masterFieldValue = await Promise.allSettled(allPromises).then(
          (response) => {
            return response.reduce(
              (acc, d) => ({
                ...acc,
                [get(d, "value.id", "")]: get(d, "value.data.data.result", [])
                  .filter((d) => d)
                  .map((d) => ({ label: d, value: d })),
              }),
              {}
            );
          }
        );

        const _columns =
          getuxvalue("SUPPLIER_LIST_COLUMNS") || supplierListColumns;
        (await result.length) &&
          result.forEach((field) => {
            if (!_columns[field.erp_field_name]) {
              _columns[field.erp_field_name] = false;
            }
          });

        const custom_keys = await result.map((field) => field.erp_field_name);

        const _finalColumns = {};
        Object.keys(_columns).forEach((key) => {
          if (
            [...Object.keys(supplierListColumns), ...custom_keys].includes(key)
          ) {
            _finalColumns[key] = _columns[key];
          }
        });

        setuxvalue("SUPPLIER_LIST_COLUMNS", _finalColumns);

        dispatch({
          type: ReducersTypes.VENDOR_LIST_UPDATE,
          payload: {
            customFilters: result,
            visibleColumns: _finalColumns,
            masterFieldValue,
          },
        });

        getList(_finalColumns, result);
      })
      .catch((e) => handleAPIError(e));
  };

export const updateVendorList = (data) => (dispatch) => {
  dispatch({
    type: ReducersTypes.VENDOR_LIST_UPDATE,
    payload: data,
  });
};

export const resetVendorListing = (data) => (dispatch) => {
  dispatch({
    type: ReducersTypes.RESET_VENDOR_LIST,
    payload: data,
  });
};

export const setVendorFilters = (data) => (dispatch) => {
  dispatch({
    type: ReducersTypes.SET_VENDOR_FILTER,
    payload: data,
  });
  dispatch({
    type: ReducersTypes.SET_UX,
    payload: {
      SUPPLIER_LIST_FILTERS: {
        ...getuxvalue("SUPPLIER_LIST_FILTERS"),
        ...data,
      },
    },
  });
};

export const resetVendorFilters = (data) => (dispatch) => {
  dispatch({
    type: ReducersTypes.RESET_VENDOR_FILTER,
    payload: data,
  });
  dispatch({
    type: ReducersTypes.SET_UX,
    payload: { SUPPLIER_LIST_FILTERS: data },
  });
};
