import { toast } from "react-toastify";

const Toaster = (message, type, time) => {
  const commonProps = {
    autoClose: time || 2500,
    theme: "colored",
    closeOnClick: true,
    pauseOnHover: true,
  };
  switch (type) {
    case "success":
      toast.success(message, {
        position: "top-right",
        ...commonProps,
      });
      break;
    case "error":
      toast.error(message, {
        position: "top-right",
        ...commonProps,
      });
      break;
    case "info":
      toast.info(message, {
        position: "top-right",
        ...commonProps,
      });
      break;
    default:
      toast("Default Notification !");
      break;
  }
};

export default Toaster;
