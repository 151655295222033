import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { offsetArApForLampplus } from "handlers/features";
import { convertDecimalNo, sumArray } from "../../utils";

const zeroArray = new Array(13 + 4).fill(0);
const nullArray = new Array(13 + 4).fill(null);

export const initReportsState = {
  cashIn: zeroArray,
  cashOut: zeroArray,
  isTotalLoading: true,
  isCashInLoading: true,
  isCashOutLoading: true,
  netOperatingCashFlow: zeroArray,
  orderBacklogTotal: zeroArray,
  endingCashBalanceTotal: zeroArray,
  showCashFlowDetailsModalData: null,
  showScenarioModal: false,
  scenarioList: [],
  dummyArray: zeroArray,
  isBankBalanceLoading: true,
  isPdfGenerated: false,
  bankbalance: zeroArray,
  dateRanges: nullArray,
  corporateEntities: [],
  showCompareScenarioChart: false,
  reportFrequency: "weekly",
  entityLabels: {},
};

export const reports = (state = { ...initReportsState }, action) => {
  switch (action.type) {
    case ReducersTypes.REPORTS: {
      return { ...state, ...action.payload };
    }
    case ReducersTypes.RECALCULATE_REPORTS: {
      const {
        zeroArray,
        dateRanges,
        cf_show_ar,
        cf_show_ap,
        adjustedBBC = [],
        actualsCount,
        forecastConfig,
        reportFrequency,
        currentScenario = "",
        cf_show_payroll,
        corporateEntities = [],
        cf_show_new_sales,
        cf_show_ap_credits,
        cf_show_ar_credits,
        bankbalanceoriginal = [],
        cf_show_payment_plan,
        cf_show_order_backlog,
        cf_show_trust_receipt,
        cf_show_purchase_order,
        cf_show_journal_inflow,
        cf_show_projected_cogs,
        cf_show_journal_outflow,
        liquidityLinesTotal = [],
        cf_beginning_balance_date,
        cf_show_customer_payment_plan,
        is_cf_beginning_balance_enabled,
        cf_show_bank_balance_by_entity,
        cf_show_ap_non_invoiced_expense,
      } = state;

      const fakeArray = new Array(forecastConfig - 1).fill(0);
      const isInScenario = currentScenario !== "default";

      if (!corporateEntities.length) {
        return state;
      }
      const _corporateEntities = corporateEntities.map((entity) => {
        const {
          item = [],
          trTotal = [],
          nonOpCashIn = [],
          newSalesItem = [],
          nonOpCashOut = [],
          payrollTotal = [],
          arCreditTotal = [],
          apCreditTotal = [],
          cashInFlowItem = [],
          cashinZeroItem = [],
          arInvoiceTotal = [],
          apInvoiceTotal = [],
          projectedAPCost = [],
          cashOutFlowItem = [],
          cashoutZeroItem = [],
          paymentPlanTotal = [],
          nonInvoicedTotal = [],
          orderBacklogTotal = [],
          miscPaymentInflow = [],
          purchaseOrderTotal = [],
          miscPaymentOutflow = [],
          journalInflowTotal = [],
          journalOutflowTotal = [],
          bankbalanceoriginal = [],
          offsetArInvoiceTotal = [],
          offsetApInvoiceTotal = [],
          unappliedPaymentTotal = [],
          customerpaymentPlanTotal = [],
          miscPaymentOutflowUnapplied = [],
          miscPaymentOutflowNonInvoiced = [],
        } = entity;

        let temp = sumArray(
          [...cashInFlowItem, ...cashinZeroItem, item].map((line) =>
            line.map((d) => (d && d.amount ? d.amount : 0))
          )
        ).splice(0, forecastConfig + actualsCount);
        let cashinArrays = [temp, unappliedPaymentTotal, miscPaymentInflow];
        if (cf_show_ar) {
          cashinArrays.push(arInvoiceTotal);
        }
        if (offsetArApForLampplus()) {
          cashinArrays.push(offsetArInvoiceTotal);
        }
        if (cf_show_ar_credits) {
          cashinArrays.push(arCreditTotal);
        }
        if (cf_show_journal_inflow) {
          cashinArrays.push(journalInflowTotal);
        }
        if (cf_show_order_backlog) {
          cashinArrays.push(orderBacklogTotal);
        }
        if (cf_show_customer_payment_plan) {
          cashinArrays.push(customerpaymentPlanTotal);
        }
        if (
          isInScenario ||
          (cf_show_new_sales && currentScenario === "default")
        ) {
          cashinArrays.push(newSalesItem.map((d) => convertDecimalNo(d)));
        }
        const cashInTotal = sumArray(cashinArrays);

        temp = sumArray(
          [...cashOutFlowItem, ...cashoutZeroItem, item].map((line) =>
            line.map((d) => (d && d.amount ? d.amount : 0))
          )
        ).splice(0, forecastConfig + actualsCount);
        const cashOutArrays = [
          temp,
          miscPaymentOutflow,
          miscPaymentOutflowUnapplied,
          miscPaymentOutflowNonInvoiced,
        ];
        if (cf_show_ap) {
          cashOutArrays.push(apInvoiceTotal);
        }
        if (offsetArApForLampplus()) {
          cashOutArrays.push(offsetApInvoiceTotal);
        }
        if (cf_show_ap_non_invoiced_expense) {
          cashOutArrays.push(nonInvoicedTotal);
        }
        if (cf_show_ap_credits) {
          cashOutArrays.push(apCreditTotal);
        }
        if (cf_show_purchase_order) {
          cashOutArrays.push(purchaseOrderTotal);
        }
        if (cf_show_payment_plan) {
          cashOutArrays.push(paymentPlanTotal);
        }
        if (cf_show_payroll) {
          cashOutArrays.push(payrollTotal);
        }
        if (cf_show_journal_outflow) {
          cashOutArrays.push(journalOutflowTotal);
        }
        if (cf_show_trust_receipt) {
          cashOutArrays.push(trTotal);
        }
        if (
          isInScenario ||
          (cf_show_projected_cogs && currentScenario === "default")
        ) {
          cashOutArrays.push(projectedAPCost.map((d) => convertDecimalNo(d)));
        }
        const cashOutTotal = sumArray(cashOutArrays);

        // non operating totals
        const nonOpCashInTotal = nonOpCashIn
          .map((line) => line.map((d) => (d && d.amount ? d.amount : 0)))
          .reduce((r, a) => {
            if (a.length) {
              a.forEach((b, i) => {
                r[i] = (r[i] || 0) + b;
              });
            }
            return r;
          }, [])
          .splice(0, forecastConfig + actualsCount);
        const nonOpCashOutTotal = nonOpCashOut
          .map((line) => line.map((d) => (d && d.amount ? d.amount : 0)))
          .reduce((r, a) => {
            if (a.length) {
              a.forEach((b, i) => {
                r[i] = (r[i] || 0) + b;
              });
            }
            return r;
          }, [])
          .splice(0, forecastConfig + actualsCount);

        const netOperatingCashFlow =
          cf_show_bank_balance_by_entity &&
          cashInTotal.map((n, i) => n - cashOutTotal[i]);
        let endingCashBalanceTotal =
          cf_show_bank_balance_by_entity &&
          bankbalanceoriginal
            .map((n, i) => n + netOperatingCashFlow[i])
            .concat(isInScenario ? [] : fakeArray);
        let bankbalanceUpdated =
          cf_show_bank_balance_by_entity &&
          bankbalanceoriginal.concat(isInScenario ? [] : fakeArray);
        if (cf_show_bank_balance_by_entity) {
          const carryForwardStart = actualsCount + 1;
          for (
            let i = carryForwardStart;
            i < forecastConfig + actualsCount;
            i++
          ) {
            bankbalanceUpdated[i] = endingCashBalanceTotal[i - 1];
            endingCashBalanceTotal[i] =
              endingCashBalanceTotal[i - 1] + netOperatingCashFlow[i];
          }
        }
        return {
          ...entity,
          cashInTotal,
          cashOutTotal,
          netOperatingCashFlow,
          bankbalance: bankbalanceUpdated,
          endingCashBalanceTotal,
          nonOpCashInTotal: nonOpCashInTotal.length
            ? nonOpCashInTotal
            : zeroArray,
          nonOpCashOutTotal: nonOpCashOutTotal.length
            ? nonOpCashOutTotal
            : zeroArray,
        };
      });

      // calculations
      const cashIn = sumArray(
        _corporateEntities.map((d) => d.cashInTotal || [])
      );
      const cashOut = sumArray(
        _corporateEntities.map((d) => d.cashOutTotal || [])
      );
      const nonOpCashIn = sumArray(
        _corporateEntities.map((d) => d.nonOpCashInTotal || [])
      );
      const nonOpCashOut = sumArray(
        _corporateEntities.map((d) => d.nonOpCashOutTotal || [])
      );
      const netOperatingCashFlow = cashIn.map((n, i) => n - cashOut[i]);
      const netNonOperatingCashFlow = nonOpCashIn.map(
        (n, i) => n - nonOpCashOut[i]
      );
      const netOperatingIncludingNonOperating = sumArray([
        netOperatingCashFlow,
        netNonOperatingCashFlow,
      ]);

      let endingCashBalanceTotal = bankbalanceoriginal
        .map((n, i) => n + netOperatingIncludingNonOperating[i])
        .concat(isInScenario ? [] : fakeArray);
      let bankbalanceUpdated = bankbalanceoriginal.concat(
        isInScenario ? [] : fakeArray
      );
      const ecbIndex = dateRanges.findIndex(
        (d) =>
          moment(d, "MM/DD/YYYY").format("MM/YYYY") ===
          moment(cf_beginning_balance_date).format("MM/YYYY")
      );
      const bankBalanceByUser =
        ecbIndex !== -1 ? bankbalanceoriginal[ecbIndex] : 0;
      const carryForwardStart =
        is_cf_beginning_balance_enabled && reportFrequency === "monthly"
          ? 1
          : isInScenario
            ? bankbalanceoriginal.findLastIndex((d) => d)
            : actualsCount + 1;

      for (let i = carryForwardStart; i < forecastConfig + actualsCount; i++) {
        bankbalanceUpdated[i] = convertDecimalNo(endingCashBalanceTotal[i - 1]);
        endingCashBalanceTotal[i] = convertDecimalNo(
          endingCashBalanceTotal[i - 1] + netOperatingIncludingNonOperating[i]
        );
        if (
          is_cf_beginning_balance_enabled &&
          reportFrequency === "monthly" &&
          ecbIndex !== -1
        ) {
          bankbalanceUpdated[ecbIndex] = convertDecimalNo(bankBalanceByUser);
          endingCashBalanceTotal[ecbIndex] = convertDecimalNo(
            bankBalanceByUser + netOperatingIncludingNonOperating[ecbIndex]
          );
        }
      }

      const availabilityBBC = sumArray([adjustedBBC, endingCashBalanceTotal]);
      const totalLiquidity = sumArray([liquidityLinesTotal, availabilityBBC]);

      return {
        ...state,
        corporateEntities: _corporateEntities,
        cashOut,
        cashIn,
        recalculateReportsCompleted: true,
        bankbalance: bankbalanceUpdated,
        nonOpCashIn: nonOpCashIn.length ? nonOpCashIn : zeroArray,
        nonOpCashOut: nonOpCashOut.length ? nonOpCashOut : zeroArray,
        netOperatingCashFlow,
        endingCashBalanceTotal,
        netNonOperatingCashFlow,
        netOperatingIncludingNonOperating,
        availabilityBBC,
        totalLiquidity,
      };
    }

    case ReducersTypes.SET_REPORTS_CORPORATE_ENTITIES: {
      const { entity, obj } = action.payload;
      const {
        zeroArray,
        dateRanges,
        adjustedBBC = [],
        actualsCount,
        forecastConfig,
        currentScenario,
        reportFrequency,
        corporateEntities = [],
        bankbalanceoriginal = [],
        liquidityLinesTotal = [],
        cf_beginning_balance_date,
        recalculateReportsCompleted = false,
        is_cf_beginning_balance_enabled,
      } = state;
      const { id } = entity;

      if (!corporateEntities.length) {
        return state;
      }
      const _corporateEntities = corporateEntities.map((ent) => {
        if (Number(ent.id) === Number(id) || ent.id === id) {
          return {
            ...ent,
            ...obj,
          };
        } else {
          return ent;
        }
      });

      // calculations
      const cashIn = sumArray(
        _corporateEntities.map((d) => d.cashInTotal || [])
      );
      const cashOut = sumArray(
        _corporateEntities.map((d) => d.cashOutTotal || [])
      );
      const nonOpCashIn = sumArray(
        _corporateEntities.map((d) => d.nonOpCashInTotal || [])
      );
      const nonOpCashOut = sumArray(
        _corporateEntities.map((d) => d.nonOpCashOutTotal || [])
      );

      const netOperatingCashFlow = cashIn.map((n, i) => n - cashOut[i]);
      const netNonOperatingCashFlow = nonOpCashIn.map(
        (n, i) => n - nonOpCashOut[i]
      );
      const netOperatingIncludingNonOperating = sumArray([
        netOperatingCashFlow,
        netNonOperatingCashFlow,
      ]);

      const fakeArray = new Array(forecastConfig - 1).fill(0);
      let endingCashBalanceTotal = bankbalanceoriginal
        .map((n, i) => n + netOperatingIncludingNonOperating[i])
        .concat(currentScenario !== "default" ? [] : fakeArray);
      let bankbalanceUpdated = bankbalanceoriginal.concat(
        currentScenario !== "default" ? [] : fakeArray
      );

      const ecbIndex = dateRanges.findIndex(
        (d) =>
          moment(d, "MM/DD/YYYY").format("MM/YYYY") ===
          moment(cf_beginning_balance_date).format("MM/YYYY")
      );
      const bankBalanceByUser =
        ecbIndex !== -1 ? bankbalanceoriginal[ecbIndex] : 0;
      const carryForwardStart =
        is_cf_beginning_balance_enabled && reportFrequency === "monthly"
          ? 1
          : currentScenario !== "default"
            ? bankbalanceoriginal.findLastIndex((d) => d)
            : actualsCount + 1;

      for (let i = carryForwardStart; i < forecastConfig + actualsCount; i++) {
        bankbalanceUpdated[i] = convertDecimalNo(endingCashBalanceTotal[i - 1]);
        endingCashBalanceTotal[i] = convertDecimalNo(
          endingCashBalanceTotal[i - 1] + netOperatingIncludingNonOperating[i]
        );
        if (
          is_cf_beginning_balance_enabled &&
          reportFrequency === "monthly" &&
          ecbIndex !== -1
        ) {
          bankbalanceUpdated[ecbIndex] = convertDecimalNo(bankBalanceByUser);
          endingCashBalanceTotal[ecbIndex] = convertDecimalNo(
            bankBalanceByUser + netOperatingIncludingNonOperating[ecbIndex]
          );
        }
      }

      const isCashInLoading = obj.arInvoiceTotal
        ? false
        : state.isCashInLoading;
      const isCashOutLoading = obj.apInvoiceTotal
        ? false
        : state.isCashOutLoading;
      const isTotalLoading =
        isCashInLoading ||
        isCashOutLoading ||
        _corporateEntities.some(
          (d) => d.isCashOutTotalLoading || d.isCashInTotalLoading
        );

      const availabilityBBC = sumArray([adjustedBBC, endingCashBalanceTotal]);
      const totalLiquidity = sumArray([liquidityLinesTotal, availabilityBBC]);
      return {
        ...state,
        corporateEntities: _corporateEntities,
        cashOut,
        cashIn,
        availabilityBBC,
        totalLiquidity,
        nonOpCashIn: nonOpCashIn.length ? nonOpCashIn : zeroArray,
        nonOpCashOut: nonOpCashOut.length ? nonOpCashOut : zeroArray,
        isTotalLoading,
        isCashInLoading,
        recalculateReportsCompleted:
          recalculateReportsCompleted === "" ? "" : !isTotalLoading,
        isCashOutLoading,
        netOperatingCashFlow,
        endingCashBalanceTotal,
        netNonOperatingCashFlow,
        netOperatingIncludingNonOperating,
        bankbalance: bankbalanceUpdated,
      };
    }
    case ReducersTypes.RESET_REPORTS:
      return { ...initReportsState };
    default:
      return state;
  }
};

export default reports;
