import { applyMiddleware, legacy_createStore as createStore } from "redux";
import { createLogger } from "redux-logger";
import { thunk } from "redux-thunk";
import reducers from "./combineReducers";
import { persistReducer, persistStore } from "redux-persist";
import storage from "redux-persist/lib/storage";
import fe_config from "constants/Configs";

const persistenceConfigs = {
  key: "payference", // whatever you want to keep as your key
  storage,
  whitelist: [
    "ux",
    "user",
    "clientDetails",
    "configurableSettings",
    "translation",
  ],
};

const persistedReducer = persistReducer(persistenceConfigs, reducers);
const middleware = [thunk];

if (fe_config && fe_config.MODE === "DEV") {
  middleware.push(createLogger({ collapsed: true, duration: true }));
}

export const store = createStore(
  persistedReducer,
  applyMiddleware(...middleware)
);
export const persistedStore = persistStore(store);
// export default { store, persistedStore: persistStore(store) };
