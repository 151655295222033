import { Box } from "@mui/material";
import React from "react";
import InputText from "./InputText";
import MessageLogs from "./MessageLogs";

function ChatWindow({
  title = "",
  isLoadingRes,
  messageList = [],
  onClose = () => {},
  onSubmit = () => {},
}) {
  return (
    <section className="aiSupportBot-continer__chatWindow">
      <Box className="aiSupportBot-continer__chatWindow__header p-2 d-flex align-items-center justify-content-between ">
        <h6 className="mb-0">
          {" "}
          <i class="fas fa-robot me-2"></i>
          {title}
        </h6>
        <span className="cursor-pointer" onClick={onClose}>
          <i class="fas fa-times"></i>
        </span>
      </Box>
      <Box className=" ">
        <MessageLogs isLoadingRes={isLoadingRes} messageList={messageList} />
        <InputText isLoadingRes={isLoadingRes} onSubmit={onSubmit} />
      </Box>
    </section>
  );
}

export default ChatWindow;
