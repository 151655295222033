import React, { useRef, useState } from "react";
import useEllipsisEnabled from "./useEllipsisEnabled";
import Tooltip from "@mui/material/Tooltip";
import "./EllipsisOverlay.css";
import { createTheme, ThemeProvider } from "@mui/material";

const ellipsisOverlayTheme = createTheme({
  components: {
    MuiTooltip: {
      styleOverrides: {
        arrow: { color: "rgb(33, 37, 41)" },
        tooltip: { background: "rgb(33, 37, 41)" },
      },
    },
  },
});

function EllipsisOverlay({
  title,
  placement = "top-end",
  showAlways,
  width,
  text,
}) {
  const ellipsisRef = useRef();
  const [show, setShow] = useState(false);
  const isEllipsisApplied = useEllipsisEnabled(ellipsisRef);

  const handleToggle = (e) => {
    if (!show && !showAlways) {
      setShow(isEllipsisApplied);
    } else {
      setShow(() => !show);
    }
  };

  return (
    <ThemeProvider theme={ellipsisOverlayTheme}>
      <Tooltip
        open={show}
        placement={placement}
        title={title}
        onOpen={handleToggle}
        onClose={handleToggle}
        arrow
      >
        <span
          style={{ width: showAlways ? "inherit" : `${width}px` }}
          ref={ellipsisRef}
          className="show-ellipses ellipsis-overlay-title"
          title={text ? text : title}
        >
          {text ? text : title}
        </span>
      </Tooltip>
    </ThemeProvider>
  );
}

export default EllipsisOverlay;
