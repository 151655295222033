import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import translationEng from "./locale_en.json";
import translationFr from "./locale_fr.json";
import translationDe from "./locale_de.json";
import translationEs from "./locale_es.json";

export const initializeI18 = (resources = {}) => {
  i18n.use(LanguageDetector).init({
    resources: {
      en: {
        translations: translationEng,
      },
      fr: {
        translations: translationFr,
      },
      de: {
        translations: translationDe,
      },
      es: {
        translations: translationEs,
      },
      ...resources,
    },
    fallbackLng: "en",
    detection: {
      order: [
        "querystring",
        "localStorage",
        "cookie",
        "sessionStorage",
        "navigator",
        "htmlTag",
        "path",
        "subdomain",
      ],
      lookupCookie: "locale",
      lookupQuerystring: "locale",
      lookupLocalStorage: "locale",
      lookupSessionStorage: "locale",
      caches: ["localStorage", "cookie"],
    },
    debug: true,

    // have a common namespace used around the full app
    ns: ["translations"],
    defaultNS: "translations", // key to use translations

    keySeparator: ".", // we use content as keys

    interpolation: {
      escapeValue: false, // not needed for react!!
      formatSeparator: ",",
    },

    react: {
      wait: true,
    },
  });
  return i18n;
};

const I18N = initializeI18();
export const changeLanguage = (language = "en") => {
  I18N.changeLanguage(language);
  window.location.reload();
};

export const availableLanguages = ["en", "fr", "de", "es"];

export const languageFileMapping = {
  en: "english",
  fr: "french",
  de: "german",
  es: "spanish",
};

export default I18N;
