import React, { useRef, useState } from "react";
import Fab from "@mui/material/Fab";
import Popper from "@mui/material/Popper";
import { Box, Grow, Paper } from "@mui/material";
import { Message, TYPE_ASSISTANT, TYPE_USER } from "./constant";
import moment from "moment";
import ChatWindow from "./ChatWindow";
import { getQuery } from "./api";
import Toaster from "components/Toaster";
import { getLocaleString } from "utils/localization/locale";
import "./AiSupportBot.css";

const ID = "AiSupportBot";

/* 
TODO: Implement Readable stream UI 
*/

function AiSupportBot() {
  const [messageList, setMessageList] = useState([]);
  const [isLoadingRes, setIsLoadingRes] = useState(false);
  const [open, setOpen] = useState(false);
  const btnRef = useRef();

  const getQueryResponse = async (payload) => {
    // call api end point and fetch result
    try {
      const queryRes = await getQuery(payload);
      const { message } = queryRes || {};
      if (message) {
        setMessageList((prev) => [
          new Message(TYPE_ASSISTANT, message?.content, [], moment.now()),
          ...prev,
        ]);
      }
      setIsLoadingRes(false);
      // extract message list from response here
    } catch (error) {
      Toaster(
        getLocaleString(
          "common.something_went_wrong_error",
          "Something went wrong, please try after sometime"
        ),
        "error"
      );
      setIsLoadingRes(false);
      return error;
    }
  };
  const handleWindowOpen = () => {
    setOpen((prev) => !prev);
  };

  const handleWindowClose = () => {
    setOpen(false);
  };

  const handleQuerySend = (content) => {
    try {
      // update message log
      setMessageList((prev) => [
        new Message(TYPE_USER, content, moment.now()),
        ...prev,
      ]);
      setIsLoadingRes(true);
      getQueryResponse({ role: TYPE_USER, content });
    } catch (error) {}
  };

  return (
    <Box className={"aiSupportBot-container"}>
      <Fab
        aria-describedby={ID}
        color="primary"
        onClick={handleWindowOpen}
        elevation={99}
      >
        <i ref={btnRef} class="fa fa-solid fa-comments"></i>
      </Fab>
      <Popper
        id={ID}
        open={open}
        anchorEl={btnRef?.current}
        placement="bottom-end"
        transition={true}
        modifiers={[
          {
            name: "zIndex",
            enabled: true,
            phase: "afterWrite",
            fn: ({ state }) => {
              state.elements.popper.style.zIndex = "9999";
            },
          },
        ]}
      >
        {({ TransitionProps }) => (
          <Grow {...TransitionProps} timeout={300}>
            <Paper className="aiSupportBot__popper-paper">
              <ChatWindow
                isLoadingRes={isLoadingRes}
                messageList={messageList}
                onClose={handleWindowClose}
                title={"AAR Bot"}
                onSubmit={handleQuerySend}
              />
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
}

export default AiSupportBot;
