import React, { useEffect, useState } from "react";
import get from "lodash/get";
import { renderToStaticMarkup } from "react-dom/server";
import { connect } from "react-redux";
import { store } from "store";
import Card from "react-bootstrap/Card";
import {
  resetconfirmationboxData,
  setconfirmationboxData,
} from "components/ConfirmationBox";
import Modal from "../CustomModal";
import Button from "components/Button";
import TokenManager from "utils/TokenManager";
import DNDContainer from "components/DNDContainer";
import DropContainer from "components/DropContainer";
import { previewInNewTab } from "components/Preview";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import { getLocaleString } from "utils/localization/locale";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { ReducersTypes } from "constants/ReducersTypes";
import { checkNonstandardCurrencyFormat } from "utils/localization/formatCurrency";
import {
  _get,
  extractEmails,
  isInvoiceClosed,
  convertDecimalNo,
  checkAttachmentSize,
  extractRemainingText,
  convertAmountWithDecimal,
} from "utils";
import { getFormattedDate, dateBefore, Yesterday } from "utils/dates";
import { MIMETYPE } from "components/Preview/const.js";
import "./emailcreator.css";
import { getUrlExtension } from "handlers/getters";
import {
  instanceWithRetry,
  regenerateCancelToken,
} from "actions/axiosInstance";
import Chip from "@mui/material/Chip";
import debounce from "lodash/debounce";
import EmailAutocomplete from "../EmailAutocomplete";
import { getuxvalue, setcolumndata } from "handlers/ux";
import { emailRegEx } from "constants/regex";
import { removeNumbersFromWord } from "utils/strings";
import Toaster from "components/Toaster";
import Constants from "constants/index";
import {
  enableForAIR,
  enableEditInvoiceButtonEmail,
  enableCustomerInvoiceRestrictedCreation,
} from "handlers/features";
import { modifiedCustomerNameForAIRClient } from "containers/customers/details/actions";

const __html = `<html>
<head>
  <meta charset="utf-8" />
  <meta http-equiv="x-ua-compatible" content="ie=edge" />
  <title>Notification</title>
  <meta name="color-scheme" content="light dark">
  <meta name="supported-color-schemes" content="light dark">
  <meta name="viewport" content="width=device-width, initial-scale=1" />
  <style type="text/css">
    @media screen {
      @font-face {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 400;
        src: local("Source Sans Pro Regular"), local("SourceSansPro-Regular"),
          url(https://fonts.gstatic.com/s/sourcesanspro/v10/ODelI1aHBYDBqgeIAH2zlBM0YzuT7MdOe03otPbuUS0.woff)
            format("woff");
      }

      @font-face {
        font-family: "Source Sans Pro";
        font-style: normal;
        font-weight: 700;
        src: local("Source Sans Pro Bold"), local("SourceSansPro-Bold"),
          url(https://fonts.gstatic.com/s/sourcesanspro/v10/toadOcfmlt9b38dHJxOBGFkQc6VGVFSmCnC_l7QZG60.woff)
            format("woff");
      }
    }

    :root {
      Color-scheme: light dark;
      supported-color-schemes: light dark;
    }
    @media (prefers-color-scheme: dark ) {
      .body {
        background-color: #ffffff !important;
      }
    }

    /**
    * Avoid browser level font resizing.
    * 1. Windows Mobile
    * 2. iOS / OSX
    */
    body,
    table,
    td,
    a {
      -ms-text-size-adjust: 100%; /* 1 */
      -webkit-text-size-adjust: 100%; /* 2 */
    }

    /**
    * Remove extra space added to tables and cells in Outlook.
    */
    table,
    td,
    th {
      mso-table-rspace: 0pt;
      mso-table-lspace: 0pt;
      padding: 0 10px;
    }

   td + td + td + td + td {
      text-align: right!important;
    }

    /**
    * Better fluid images in Internet Explorer.
    */
    img {
      -ms-interpolation-mode: bicubic;
    }

    /**
    * Remove blue links for iOS devices.
    */
    a[x-apple-data-detectors] {
      font-family: inherit !important;
      font-size: inherit !important;
      font-weight: inherit !important;
      line-height: inherit !important;
      color: inherit !important;
      text-decoration: none !important;
    }

    /**
    * Fix centering issues in Android 4.4.
    */
    div[style*="margin: 16px 0;"] {
      margin: 0 !important;
    }

    body {
      width: 100% !important;
      height: 100% !important;
      padding: 0 !important;
      margin: 0 !important;
      background-color: #ffffff!important;
    }

    .text-bottom {
      font-size: 13px;
      color: #333;
    }

    /**
    * Collapse table borders to avoid space between cells.
    */
    table {
      border-collapse: collapse !important;
    }

    a {
      color: #1a82e2;
    }

    img {
      line-height: 100%;
      text-decoration: none;
      border: 0;
      outline: none;
    }
  </style>
</head>
<body style="background-color: #fff!important">

  <!-- start body -->
  <table bgcolor="#ffffff" border="0" cellpadding="0" cellspacing="0" width="100%" style="background: #fff!important; padding: 10px">
    <!-- start copy block -->
    <tr>
      <td align="center" bgcolor="#ffffff">
        <table
          border="0"
          cellpadding="0"
          cellspacing="0"
          width="100%"
          style="max-width: 600px; background: #ffffff; margin-top: 24px; border: 0.5px solid #dfdfdf"
        >
          <tr>
            <td align="center" valign="center" style="height: 100px; padding: 5px; border-bottom: 1px solid #d9d9d9">
              <img
                src="{{logo_url}}"
                alt="Logo"
                border="0"
                valign="center"
                style="vertical-align: middle;"
              />
            </td>
          </tr>

          <!-- start copy -->
          <tr>
            <td
              align="left"
              style="
                padding: 24px;
                padding-bottom: 0;
                padding-top: 12px;
                font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif;
                font-size: 16px;
                line-height: 24px;
              "
            >
              <p style="margin: 0">{{details}}</p>
            </td>
          </tr>
          <!-- end copy -->
          <tr>
            <td style="display:block; text-align:center; padding: 20px 24px 35px 24px; font-family:Poppins ,sans-serif; {{hide_payment_btn}}">
              <!--[if mso]>
              <v:roundrect xmlns:v="urn:schemas-microsoft-com:vml" xmlns:w="urn:schemas-microsoft-com:office:word" href="{{customer_login_url}}" style="height:60px;v-text-anchor:middle;width:200px;" arcsize="50%" stroke="f" fillcolor="#1480EE">
              <w:anchorlock/>
              <center style="color:#ffffff;font-family:Poppins, sans-serif;font-size:16px;">
              <![endif]-->
                <a href="{{customer_login_url}}" style="background-color:#1480EE;border-radius:20px;color:#ffffff;display:inline-block;font-family:Poppins, sans-serif;font-size:16px;line-height:40px;text-align:center;text-decoration:none;width:200px;-webkit-text-size-adjust:none;">{{cta_text}}</a>
              <!--[if mso]>
              </center>
              </v:roundrect>
              <![endif]-->
            </td>
          </tr>
          <tr className="text-bottom">
            <td align="center" style="padding-top: 10px;  border-top: 1px solid #dfdfdf">
                <span style="margin: 0; font-size: 12px;" className="text-bottom">Powered by</span>
                <img align="middle" style="vertical-align: middle;" height="25px" src="https://payference-public.s3-us-west-1.amazonaws.com/payference_logo_small.png" alt="Serrala" border="0" />
            </td>
          </tr>
          <tr>
            <td align="center" style="padding-bottom: 10px; font-family: 'Source Sans Pro', Helvetica, Arial, sans-serif; font-size: 16px; line-height: 24px;">
              <p style="margin: 0; font-size: 12px;" class="text-bottom">Copyright © ${new Date().getFullYear()} ${getLocaleString(
                "common.payference_all_rights_reserved",
                "Serrala - All Rights Reserved"
              )}. <a href="www.payference.com" target="_blank">www.payference.com</a></p>
            </td>
          </tr>
        </table>
      </td>
    </tr>
    <!-- end copy block -->
  </table>

  <!-- end body -->
</body>
</html>
`;

export const setemailData = (data) => {
  store.dispatch({
    type: ReducersTypes.EMAIL_DATA,
    payload: data,
  });
};

export const resetemail = () => {
  store.dispatch({
    type: ReducersTypes.EMAIL_RESET,
  });
};

export const calculateAmounts = (invoices = []) => {
  const keyName = "foreign_total_amount_due";
  return invoices.reduce(
    (acc, curr) => {
      acc.total_amount_due += curr[keyName];
      if (dateBefore(Yesterday, curr.due_date)) {
        acc.overdue += curr[keyName];
      } else {
        acc.current += curr[keyName];
      }
      return acc;
    },
    {
      overdue: 0,
      current: 0,
      total_amount_due: 0,
    }
  );
};

function EmailCreator(props) {
  const debounceMethod = debounce(getSearchEmails, 300);
  let {
    email: {
      to = "",
      cc = "",
      show,
      body = "",
      files = [],
      onSave,
      subject = "",
      filesUri = [],
      readOnly,
      pushToErp = false,
      hidePayBtn = false,
      customerName = "",
      showTemplate,
      templateName = "",
      tableHeaders = [],
      portalPayment = "",
      isEmailSending = false,
      portalStatement = "",
      onExcelDownload = false,
      currentTemplate = {},
      defaultTemplate = {},
      loggedInUserData,
      foreignCurrency = TokenManager.getCurrencyCode(),
      skip_attachments = false,
      fetchingInvoices = false,
      hideStatementBtn = false,
      selectedInvoices = [],
      onSkipAttachments = () => {},
      emailTemplateData = [],
      showCheckForPortal = false,
      is_payment_enabled = false,
      attachments_skipped = false,
      selectedInvoicesCopy = [],
    },
    payrollSettingsData: { isFetching, payrollSettings },
    email,
    charLimit = 2000,
  } = props;

  const showMixedCurrencies = selectedInvoices.reduce((acc, cur, _, arr) => {
    const currentInvoiceCurrency = checkNonstandardCurrencyFormat(cur.currency);
    if (
      arr.find(
        (d) =>
          checkNonstandardCurrencyFormat(d.currency) !== currentInvoiceCurrency
      )
    ) {
      acc = true;
    }
    return acc;
  }, false);

  const [emailTo, setEmailTo] = useState([]);
  const [emailCc, setEmailCc] = useState([]);
  const [extraTextTo, setExtraTextTo] = useState("");
  const [extraTextCc, setExtraTextCc] = useState("");
  const customerNameToUse = enableForAIR()
    ? modifiedCustomerNameForAIRClient(customerName)
    : removeNumbersFromWord(customerName);
  const [showInvoiceTable, setShowInvoiceTable] = useState(false);
  const [usersList, setUsersList] = useState([]);
  const [fileSizeError, setFileSizeError] = useState(false);
  const [showError, setShowError] = useState({
    to: false,
    subject: false,
    body: false,
  });
  const template =
    window.location.pathname.split("/")[1] === "customers"
      ? "CUSTOMER_EMAIL_TEMPLATE"
      : "VENDOR_EMAIL_TEMPLATE";
  let currentEmailTemplate = null;
  const emailTemplateDataModified = emailTemplateData.filter(
    (d) => !Constants.BEworkflowTemplates.includes(d.name)
  );
  const corporateEntities = isFetching ? [] : payrollSettings;
  const [flag, setFlag] = useState(showTemplate);
  const clientId = TokenManager.getClientId();
  const maliciousFileRegex =
    /(\.ade|\.adp|\.apk|\.appx|\.appxbundle|\.bat|\.cab|\.chm|\.cmd|\.com|\.cpl|\.dll|\.dmg|\.ex|\.ex_|\.exe|\.hta|\.ins|\.isp|\.iso|\.jar|\.js|\.jse|\.lib|\.lnk|\.mde|\.msc|\.msi|\.msix|\.msixbundle|\.msp|\.mst|\.nsh|\.pif|\.ps1|\.scr|\.sct|\.shb|\.sys|\.vb|\.vbe|\.vbs|\.vxd|\.wsc|\.wsf|\.wsh)$/i;

  const calculatedAmounts = calculateAmounts(selectedInvoices);
  useEffect(() => {
    setFlag(showTemplate);
  }, [showTemplate]);

  useEffect(() => {
    if (files.length) {
      setFileSizeError(!checkAttachmentSize(files));
    }
  }, [files]);

  useEffect(() => {
    if (to !== "" && to !== "-" && to !== null) {
      setEmailTo(extractEmails(to) || []);
      setExtraTextTo(extractRemainingText(to || ""));
    }
  }, [to]);

  useEffect(() => {
    if (cc !== "" && cc !== "-" && cc !== null) {
      setEmailCc(extractEmails(cc) || []);
      setExtraTextCc(extractRemainingText(cc) || "");
    }
  }, [cc]);

  function onChange(key, value) {
    let data = { [key]: value };
    if (currentEmailTemplate && key === "body") {
      data = {
        ...currentEmailTemplate,
        [key]: value,
      };
      currentEmailTemplate = null;
    }
    setemailData(data);
  }

  function removeFiles(index, name) {
    const updatedFiles = files.filter((d, i) => i !== index);
    const updatedFileUri = filesUri.filter((d) => d.name !== name);
    const data = { ...email, files: updatedFiles, filesUri: updatedFileUri };
    setemailData(data);
  }

  function onDrop({ item }) {
    let dropFiles = [];
    let uploadedFiles = get(item, "files", []);
    for (let i = 0; i < uploadedFiles.length; i++) {
      if (maliciousFileRegex.exec(uploadedFiles[i].name)) {
        Toaster(
          `Uploaded File type ${uploadedFiles[i].name} not supported`,
          "error"
        );
        return;
      } else {
        dropFiles.push({
          name: uploadedFiles[i].name,
          uri: URL.createObjectURL(uploadedFiles[i]),
        });
      }
    }
    const updatedFiles = [...files, ...get(item, "files", [])];

    const data = {
      ...email,
      files: updatedFiles,
      filesUri: [...filesUri, ...dropFiles],
    };
    setFileSizeError(!checkAttachmentSize(updatedFiles));
    if (!checkAttachmentSize(updatedFiles)) {
      return;
    }
    setemailData(data);
  }

  const userData = JSON.parse(TokenManager.getUserData());
  const corporateEntityId = TokenManager.getCorporateEntityId();
  let img_url =
    userData.client_logo ||
    "https://payference-public.s3-us-west-1.amazonaws.com/payference_logo_small.png";
  let clientEmail = TokenManager.getClientEmail() || "contact@payference.com";
  let clientName = TokenManager.getClientName();
  if (corporateEntityId) {
    img_url =
      _get(
        corporateEntities.find(
          (x) => Number(x.id) === Number(corporateEntityId)
        ),
        "logo_url",
        img_url
      ) || img_url;
    clientEmail =
      _get(
        corporateEntities.find(
          (x) => Number(x.id) === Number(corporateEntityId)
        ),
        "reply_to_email",
        clientEmail
      ) || clientEmail;
  }

  function addTagsStylingBeforeSending(emailBody) {
    document
      .getElementById("preview-email")
      .insertAdjacentHTML("beforeend", emailBody);
    if (document.querySelector("#preview-email figure")) {
      document
        .querySelector("#preview-email figure")
        .setAttribute("style", "margin: 0;");
    }
    if (document.querySelector("#preview-email table")) {
      document
        .querySelector("#preview-email table")
        .setAttribute("border", "1");

      const tdElement = document.querySelectorAll("#preview-email table td");
      for (let i = 0; i < tdElement.length; i++) {
        tdElement[i].setAttribute(
          "style",
          "padding-left: 10px; padding-right:10px;"
        );
      }
      const thElement = document.querySelectorAll("#preview-email table th");
      for (let i = 0; i < thElement.length; i++) {
        thElement[i].setAttribute(
          "style",
          "padding-left: 10px; padding-right:10px;"
        );
      }
      document
        .querySelector("#preview-email table")
        .setAttribute("width", "100%");
      document
        .querySelector("#preview-email table")
        .setAttribute("align", "center");
      document
        .querySelector("#preview-email table")
        .setAttribute("cellpadding", "1");
      document
        .querySelector("#preview-email table")
        .setAttribute("cellspacing", "0");
    }
    return document.getElementById("preview-email").innerHTML;
  }

  function getSearchEmails(search_string) {
    const string = `email/search?${
      email.client_supplier_rel_id
        ? "client_supplier_rel_id="
        : "client_customer_rel_id="
    }${email.client_supplier_rel_id || email.client_customer_rel_id}`;
    instanceWithRetry.post(string, { data: search_string }).then((response) => {
      if (response) {
        setUsersList([]);
        setUsersList(response.data.data);
      }
    });
  }

  function renderEmailAutoComplete(
    label,
    emailIds,
    setEmailId,
    search,
    extraText,
    tabIndex
  ) {
    return (
      <div
        id={label}
        className="m-2 border rounded input-email-box"
        data-cy={`field-${label}`}
      >
        <div className="p-2 d-table-cell text-start label">{label}</div>
        <div className="p-2 d-table-cell">
          {emailIds.length ? (
            <div className="mb-1">
              {emailIds.map((d) => (
                <span className="d-inline-block ms-1 mb-1" data-cy="email-chip">
                  <Chip
                    id={`ChipTo-${tabIndex}`}
                    style={{ backgroundColor: "lightgrey" }}
                    size="small"
                    label={d}
                    onDelete={() =>
                      setEmailId(emailIds.filter((email) => email !== d))
                    }
                    variant="outlined"
                  />
                </span>
              ))}
            </div>
          ) : null}
          <EmailAutocomplete
            key={extraText}
            tabIndex={tabIndex}
            defaultValue={extraText}
            suggestions={usersList}
            getSelected={(item) => {
              setShowError({
                ...showError,
                to: false,
              });
              item &&
                setEmailId(
                  emailIds.length
                    ? !emailIds.includes(item)
                      ? emailIds.concat(item)
                      : emailTo
                    : [item]
                );
            }}
            getSelectedArray={(arr) => {
              setShowError({
                ...showError,
                to: false,
              });
              arr &&
                setEmailId(
                  emailIds.length
                    ? emailIds.concat(
                        arr.filter((item) => emailIds.indexOf(item) < 0)
                      )
                    : arr
                );
            }}
            tryRemove={() => {
              setEmailId(emailIds.slice(0, -1));
            }}
            search={(text) => {
              if (text !== "" && text !== " " && text !== null) search(text);
            }}
            placeholder={getLocaleString(
              "common.enter_email_address",
              "Enter email address"
            )}
            isLoading={false}
          />
        </div>
      </div>
    );
  }

  const getAmountSplitHTML = () =>
    `<p>${getLocaleString(
      "worldpay.overdue_amount",
      "Overdue Amount"
    )}: &nbsp;&nbsp;<strong>{{overdue}}</strong> <br/> ${getLocaleString(
      "worldpay.current_amount",
      "Current Amount"
    )}: &nbsp;&nbsp;&nbsp;&nbsp;<strong>{{current}}</strong> <br/>${getLocaleString(
      "worldpay.total_amount_due",
      "Total Amount Due"
    )}: <strong>{{total_amount_due}}</strong></p>`;

  // const getForeignAmount = (invoice, foreignCurrency) => {
  //   if (!invoice) return;
  //   const { currency } = invoice;
  //   const invoiceCurency = checkNonstandardCurrencyFormat(currency);
  //   const isDifferentCurrency = invoiceCurency !== TokenManager.getCurrencyCode();
  //   const prefix = isDifferentCurrency ? "foreign_" : "";
  //   const isAmountZero = isInvoiceClosed(invoice) ? !(invoice[`${prefix}amount_closed`] || invoice[`${prefix}invoice_amount`]) : !invoice[`${prefix}total_amount_due`];
  //   if (!isDifferentCurrency || foreignCurrency === invoiceCurency || isAmountZero) return;

  //   if (isInvoiceClosed(invoice)) {
  //     return `(${convertAmountWithDecimal(
  //       invoice[`${prefix}amount_closed`] || invoice[`${prefix}invoice_amount`], invoiceCurency
  //     )})`
  //   }
  //   return `(${convertAmountWithDecimal(invoice[`${prefix}total_amount_due`], invoiceCurency)})`;
  // };

  const replaceVariablesInBody = (text) => {
    let replaceTableTag =
      text.includes("{{invoice_list_with_details}}") &&
      (text.includes(
        `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="background: white; padding: 10px">`
      ) ||
        text.includes(`<figure className="table"><table><tbody>`))
        ? text
            .replaceAll(
              `<table border="0" cellpadding="0" cellspacing="0" width="100%" style="background: white; padding: 10px">`,
              ""
            )
            .replaceAll("</table>", "")
            .replaceAll(`<figure className="table"><table><tbody>`, "")
            .replaceAll(`</tbody></table></figure>`, "")
        : text;
    return replaceTableTag
      .replace(
        "{{invoice_list_with_details}}",
        renderToStaticMarkup(generateEditableTable(true))
      )
      .replace(
        "{{total_due_overdue_current_split}}",
        showMixedCurrencies ? "" : getAmountSplitHTML()
      )
      .replace(
        "<strong>{{total_amount_due}}</strong>",
        `<strong>${
          showMixedCurrencies
            ? "-"
            : convertAmountWithDecimal(
                calculatedAmounts.total_amount_due,
                foreignCurrency
              )
        }</strong>`
      )
      .replace(
        "<strong>{{overdue}}</strong>",
        `<strong>${
          showMixedCurrencies
            ? "-"
            : convertAmountWithDecimal(
                calculatedAmounts.overdue,
                foreignCurrency
              )
        }</strong>`
      )
      .replace(
        "<strong>{{current}}</strong>",
        `<strong>${
          showMixedCurrencies
            ? "-"
            : convertAmountWithDecimal(
                calculatedAmounts.current,
                foreignCurrency
              )
        }</strong>`
      )
      .replace("{{customer.name}}", customerNameToUse)
      .replace("{{CLIENT_EMAIL}}", clientEmail)
      .replace("{{CLIENT_NAME}}", clientName)
      .replace("{{logged_in_user_name}}", get(loggedInUserData, "name", ""))
      .replace("{{logged_in_user_email}}", get(loggedInUserData, "email", ""))
      .replace(
        "{{logged_in_user_phone}}",
        get(loggedInUserData, "phone", "") || "-"
      );
  };

  const validateCustomerRestrictedEmailCF = () => {
    if (enableCustomerInvoiceRestrictedCreation()) {
      let errorMsg = "";
      const isCustomFieldExist = selectedInvoices.some((d) =>
        get(d, "custom_fields", []).find(
          (v) =>
            get(v, "custom_field_master.erp_field_name", "").toLowerCase() ===
            "emails"
        )
      );
      selectedInvoices.forEach((d) => {
        const emailsCustomField = get(
          get(d, "custom_fields", []).find(
            (v) =>
              get(v, "custom_field_master.erp_field_name", "").toLowerCase() ===
              "emails"
          ),
          "erp_field_string_value",
          ""
        );
        emailTo.forEach((email) => {
          const isToUserBelongstoCF =
            emailsCustomField && emailsCustomField.includes(email)
              ? true
              : false;
          if (!isToUserBelongstoCF) {
            errorMsg += `<li class="mb-1">${email} ${getLocaleString(
              "common.doesnt_have_access_to_invoice",
              "doesn’t have access to invoice"
            )} - ${d.invoice_number}</li> `;
          }
        });
      });
      if (isCustomFieldExist && errorMsg) {
        setconfirmationboxData({
          variant: "warning",
          okButtonText: getLocaleString("common.ok", "Ok"),
          hideCancel: true,
          msg: `<ul class="text-start mb-0">${errorMsg}</ul>`,
          onSave: () => resetconfirmationboxData(),
        });
        return {
          isValid: false,
          isRestrictedCustomerRole: false,
        };
      } else {
        return {
          isValid: true,
          isRestrictedCustomerRole: isCustomFieldExist,
        };
      }
    }
    return {
      isValid: true,
      isRestrictedCustomerRole: false,
    };
  };

  const replaceVariablesInSubject = (sub = "") =>
    sub
      .replace("{{customer.name}}", customerNameToUse)
      .replace("{{CLIENT_EMAIL}}", clientEmail)
      .replace("{{CLIENT_NAME}}", clientName)
      .replace("{{logged_in_user_name}}", get(loggedInUserData, "name", ""))
      .replace("{{logged_in_user_email}}", get(loggedInUserData, "email", ""))
      .replace(
        "{{logged_in_user_phone}}",
        get(loggedInUserData, "phone", "") || "-"
      );

  const generateEditableTable = (isStatic = false) => {
    if (!selectedInvoices.length) return;
    return (
      <table
        id="invoiceTable"
        border="1"
        width="100%"
        align="center"
        cellpadding="1"
        cellspacing="0"
      >
        <tbody>
          <tr>
            {tableHeaders
              .filter((d) => d.show)
              .map((d, i) => (
                <th
                  className="delete-hover"
                  style={{
                    textAlign: "center",
                    padding: "10px 5px",
                    width: i === 0 ? "60px" : "auto",
                  }}
                >
                  {" "}
                  {!d.mandatory && !isStatic ? (
                    <i
                      className="fa fa-trash-o t-16 cursor-pointer t-red me-1 d-block"
                      title="Delete column"
                      aria-hidden="true"
                      onClick={() => {
                        setemailData({
                          tableHeaders: tableHeaders.map((e) =>
                            d.text === e.text ? { ...e, show: false } : e
                          ),
                        });
                      }}
                    />
                  ) : null}
                  {d.text}
                </th>
              ))}
          </tr>
          {selectedInvoices.map((d, i) => {
            const invoiceCurency = d.currency
              ? checkNonstandardCurrencyFormat(d.currency)
              : TokenManager.getCurrencyCode();
            const prefix = "foreign_";
            return (
              <tr key={d.id}>
                <td
                  className="delete-hover"
                  style={{
                    textAlign: "right",
                    padding: i === 0 ? "0 10px 0 5px" : "0 10px",
                  }}
                >
                  {!isStatic && (
                    <i
                      className="fa fa-trash-o t-16 cursor-pointer t-red me-1"
                      aria-hidden="true"
                      title="Delete row"
                      onClick={() => {
                        const updatedInv = selectedInvoices.filter(
                          (e) => e.id !== d.id
                        );
                        setemailData({
                          selectedInvoices: updatedInv,
                          body: body.replace(
                            convertAmountWithDecimal(
                              calculatedAmounts.total_amount_due,
                              invoiceCurency
                            ),
                            convertAmountWithDecimal(
                              get(
                                calculateAmounts(updatedInv),
                                "total_amount_due",
                                0
                              ),
                              invoiceCurency
                            )
                          ),
                        });
                      }}
                    />
                  )}
                  {i + 1}
                </td>
                {get(tableHeaders[1], "show", true) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {d.invoice_number}
                  </td>
                ) : null}
                {get(tableHeaders[2], "show", false) ? (
                  <td style={{ textAlign: "left", padding: "0 10px" }}>
                    {enableForAIR()
                      ? modifiedCustomerNameForAIRClient(
                          get(d, "customer.name", "-")
                        )
                      : get(d, "customer.name", "-")}
                  </td>
                ) : null}
                {get(tableHeaders[3], "show", true) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {getFormattedDate(d.invoice_date)}
                  </td>
                ) : null}
                {get(tableHeaders[4], "show", true) ? (
                  <td style={{ textAlign: "center" }}>
                    {isInvoiceClosed(d)
                      ? getFormattedDate(d.date_closed)
                      : getFormattedDate(d.due_date)}
                  </td>
                ) : null}
                {get(tableHeaders[5], "show", true) ? (
                  <td style={{ textAlign: "right", padding: "0 10px" }}>
                    {isInvoiceClosed(d)
                      ? convertAmountWithDecimal(
                          d[`${prefix}amount_closed`] ||
                            d[`${prefix}invoice_amount`],
                          invoiceCurency
                        )
                      : convertAmountWithDecimal(
                          d[`${prefix}total_amount_due`],
                          invoiceCurency
                        )}
                  </td>
                ) : null}
                {get(tableHeaders[6], "show", false) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {d.status}
                  </td>
                ) : null}
                {get(tableHeaders[7], "show", false) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {get(d, "ContainerNumber", "-")}
                  </td>
                ) : null}
                {get(tableHeaders[8], "show", false) ? (
                  <td
                    style={{ textAlign: "center", padding: "0 10px" }}
                    title={get(d, "CustomerReferenceNumber", "")}
                  >
                    <span className="d-block width-94px show-ellipses mx-auto">
                      {get(d, "CustomerReferenceNumber", "-")}
                    </span>
                  </td>
                ) : null}
                {get(tableHeaders[9], "show", false) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {d.cust_po_number}
                  </td>
                ) : null}
                {!showMixedCurrencies &&
                get(tableHeaders[10], "show", false) ? (
                  <td style={{ textAlign: "right", padding: "0 10px" }}>
                    {convertAmountWithDecimal(d.Total, invoiceCurency)}
                  </td>
                ) : null}
                {get(tableHeaders[11], "show", false) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {d.days_past_due}
                  </td>
                ) : null}
                {get(tableHeaders[12], "show", false) ? (
                  <td style={{ textAlign: "center", padding: "0 10px" }}>
                    {d.memo}
                  </td>
                ) : null}
              </tr>
            );
          })}
        </tbody>
      </table>
    );
  };

  let editorValue = body;
  if (!showInvoiceTable) {
    editorValue = replaceVariablesInBody(editorValue);
  }

  return (
    show &&
    !readOnly && (
      <Modal
        show={show && !readOnly}
        size="lg"
        backdrop="static"
        onHide={() => {
          resetemail();
          regenerateCancelToken();
          setEmailTo([]);
          setEmailCc([]);
          setShowInvoiceTable(false);
          setShowError({
            to: false,
            subject: false,
            body: false,
          });
        }}
        keyboard={false}
        title={getLocaleString("common.new_email", "New Email")}
        footer={() => (
          <>
            <Button
              variant="primary"
              className="me-2 cursor-pointer"
              hidden={!onExcelDownload}
              disabled={showInvoiceTable}
              data-tlabel="Download Excel"
              onClick={() => {
                let table = document.querySelector(
                  ".ck-widget_with-selection-handle > table"
                );
                let header = [];
                let rows = [];
                if (table) {
                  for (
                    let i = 0;
                    i < get(table, "rows[0].cells", []).length;
                    i++
                  ) {
                    header.push(
                      get(table, `rows[0].cells[${i}].textContent`, "-")
                    );
                  }

                  for (let i = 1; i < get(table, "rows", []).length; i++) {
                    let row = {};
                    for (
                      let j = 0;
                      j < get(table, `rows[${i}].cells`, []).length;
                      j++
                    ) {
                      row[header[j]] = get(
                        table,
                        `rows[${i}].cells[${j}].textContent`
                      );
                    }
                    rows.push(row);
                  }
                  onExcelDownload(rows);
                }
              }}
            >
              <i className="fa fa-download me-2" />
              {getLocaleString("common.download_excel", "Download Excel")}
            </Button>
            {enableEditInvoiceButtonEmail() &&
            !showInvoiceTable &&
            get(currentTemplate, "body", "").includes(
              "{{invoice_list_with_details}}"
            ) &&
            selectedInvoices.length ? (
              <Button
                variant="primary"
                className="me-2 cursor-pointer"
                onClick={() => {
                  setShowInvoiceTable(true);
                  setTimeout(() => {
                    if (document.getElementById("invoice-table")) {
                      document
                        .getElementById("email-wrapper")
                        .scroll(
                          0,
                          document.getElementById("invoice-table").offsetTop
                        );
                    }
                  }, 200);
                  setemailData({
                    body: body
                      .replace(
                        /<table.*?>.*?<\/table>/gi,
                        "{{invoice_list_with_details}}"
                      )
                      .replace(
                        `<strong>${
                          showMixedCurrencies
                            ? "-"
                            : convertAmountWithDecimal(
                                calculatedAmounts.total_amount_due,
                                foreignCurrency
                              )
                        }</strong>`,
                        "<strong>{{total_amount_due}}</strong>"
                      )
                      .replace(
                        `<strong>${
                          showMixedCurrencies
                            ? "-"
                            : convertAmountWithDecimal(
                                calculatedAmounts.overdue,
                                foreignCurrency
                              )
                        }</strong>`,
                        "<strong>{{overdue}}</strong>"
                      )
                      .replace(
                        `<strong>${
                          showMixedCurrencies
                            ? "-"
                            : convertAmountWithDecimal(
                                calculatedAmounts.current,
                                foreignCurrency
                              )
                        }</strong>`,
                        "<strong>{{current}}</strong>"
                      ),
                  });
                }}
              >
                {getLocaleString("common.edit_invoices", "Edit Invoices")}
              </Button>
            ) : null}

            {enableEditInvoiceButtonEmail() &&
            get(currentTemplate, "body", "").includes(
              "{{invoice_list_with_details}}"
            ) &&
            showInvoiceTable ? (
              <>
                <Button
                  variant="primary"
                  onClick={() => {
                    setShowInvoiceTable(false);
                  }}
                >
                  {getLocaleString("common.save", "Save")}
                </Button>
                <Button
                  variant="secondary"
                  className="ms-2 me-2"
                  onClick={() => {
                    setShowInvoiceTable(false);
                    setemailData({
                      selectedInvoices: selectedInvoicesCopy,
                    });
                  }}
                >
                  {getLocaleString("common.cancel", "Cancel")}
                </Button>
              </>
            ) : null}
            <Button
              tabIndex="8"
              disabled={
                fileSizeError ||
                isEmailSending ||
                !(skip_attachments ? attachments_skipped : !fetchingInvoices) ||
                showInvoiceTable
              }
              variant="primary"
              className="float-end me-2"
              data-cy="sendemail"
              onClick={() => {
                const { isValid, isRestrictedCustomerRole } =
                  validateCustomerRestrictedEmailCF();
                if (!emailTo[0] || !subject || !body || !isValid) {
                  if (!body) {
                    document
                      .getElementById("email_attachment")
                      .scrollIntoView();
                  } else if (!emailTo[0]) {
                    document.getElementById("TO").scrollIntoView();
                  } else if (!subject) {
                    document.getElementById("email_subject").scrollIntoView();
                  }
                  setShowError({
                    to: !emailTo[0],
                    subject: !subject,
                    body: !body,
                  });
                  return;
                }
                setShowError({
                  to: false,
                  subject: false,
                  body: false,
                });
                let emailBody =
                  selectedInvoices.length &&
                  body.includes("{{invoice_list_with_details}}")
                    ? body.replace(
                        /<table.*?>.*?<\/table>/gi,
                        "{{invoice_list_with_details}}"
                      )
                    : body;
                emailBody = addTagsStylingBeforeSending(
                  replaceVariablesInBody(emailBody)
                );
                onChange("isEmailSending", true);
                const _body = __html
                  .replace("{{details}}", emailBody)
                  .replace("{{customer_logo}}", img_url)
                  .replaceAll(
                    "{{hide_payment_btn}}",
                    hidePayBtn ? "display:none" : ""
                  )
                  .replaceAll("{{customer_portal_payment}}", portalPayment)
                  .replace(
                    "{{hide_statement_btn}}",
                    hideStatementBtn ? "display:none" : ""
                  )
                  .replaceAll("{{customer_portal_statement}}", portalStatement)
                  .replaceAll(
                    "{{customer_login_url}}",
                    email.client_supplier_rel_id
                      ? "{{supplier_login_url}}"
                      : isRestrictedCustomerRole
                        ? "{{customer_invoice_restricted_login_url}}"
                        : "{{customer_login_url}}"
                  )
                  .replaceAll(
                    "{{cta_text}}",
                    hidePayBtn
                      ? ""
                      : is_payment_enabled
                        ? getLocaleString(
                            "common.review_and_pay",
                            "Review & Pay"
                          )
                        : getLocaleString(
                            "common.view_invoices",
                            "View Invoices"
                          )
                  );
                const sendTo = emailTo.join();
                const sendCc = emailCc.join();
                onSave(sendTo, subject, _body, files, sendCc, pushToErp);
              }}
            >
              {getLocaleString("common.send", "Send")}
              {isEmailSending && <i className="ms-1 fa fa-spinner fa-spin" />}
            </Button>
          </>
        )}
      >
        <DNDContainer>
          <DropContainer onDrop={onDrop}>
            <div
              style={{ maxHeight: "calc(90vh - 190px)", overflowY: "auto" }}
              className="email-wrapper"
              id="email-wrapper"
            >
              {renderEmailAutoComplete(
                getLocaleString("common.to", "To"),
                emailTo,
                setEmailTo,
                debounceMethod,
                extraTextTo,
                "1"
              )}
              <div>
                {showError.to ? (
                  <span className="error t-12 ms-2">
                    {getLocaleString("common.to_is_required", "To is Required")}
                  </span>
                ) : null}
              </div>
              {renderEmailAutoComplete(
                getLocaleString("common.cc", "CC"),
                emailCc,
                setEmailCc,
                debounceMethod,
                extraTextCc,
                "2"
              )}
              {emailTemplateDataModified.length > 0 && (
                <div className="m-2 border rounded input-email-box">
                  <select
                    tabIndex="3"
                    data-cy="email-template"
                    className="form-control t-14"
                    style={{ border: "none" }}
                    onChange={(e) => {
                      if (e.target.value === "clear_template") {
                        setcolumndata(["EMAIL_TEMPLATES", template], {
                          ...getuxvalue("EMAIL_TEMPLATES")[template],
                          [clientId]: {
                            name: "null",
                            body: "null",
                            subject: "null",
                            clientId,
                            id: "",
                          },
                        });
                        setemailData({
                          body: "",
                          subject: "",
                          ...defaultTemplate,
                          currentTemplate: defaultTemplate,
                        });
                        setFlag(false);
                      } else {
                        const {
                          subject: templateSubject,
                          body: templateEmailBody,
                          name,
                          id,
                        } = JSON.parse(e.target.value);
                        setcolumndata(["EMAIL_TEMPLATES", template], {
                          ...getuxvalue("EMAIL_TEMPLATES")[template],
                          [clientId]: {
                            name,
                            body: templateEmailBody,
                            subject: templateSubject || subject,
                            clientId,
                            id,
                          },
                        });
                        currentEmailTemplate = {
                          body: replaceVariablesInBody(templateEmailBody),
                          subject: replaceVariablesInSubject(
                            templateSubject || subject
                          ),
                        };
                        setemailData({
                          ...email,
                          ...currentEmailTemplate,
                          currentTemplate: {
                            body: replaceVariablesInSubject(templateEmailBody),
                            subject: replaceVariablesInSubject(
                              templateSubject || subject
                            ),
                          },
                        });
                        setShowError({
                          to: emailTo && emailTo.length,
                          subject: !!templateSubject,
                          body: !!templateEmailBody,
                        });
                        setFlag(true);
                      }
                    }}
                  >
                    <option disabled={!flag} selected value="clear_template">
                      {flag
                        ? getLocaleString(
                            "common.unselect_template",
                            "Unselect a template"
                          )
                        : getLocaleString(
                            "common.select_template",
                            "Please select a template"
                          )}
                    </option>
                    {emailTemplateDataModified.map((d) => (
                      <option
                        key={d.id}
                        value={JSON.stringify(d)}
                        selected={d.name === templateName}
                      >
                        {d.name}
                      </option>
                    ))}
                  </select>
                </div>
              )}
              <div className="m-2 border rounded input-email-box">
                <div className="py-2 ps-2">
                  <input
                    tabIndex="4"
                    type="text"
                    placeholder={getLocaleString("common.subject", "Subject")}
                    data-cy="Subject"
                    className="input-email w-80"
                    id="email_subject"
                    onChange={(e) => {
                      setShowError({
                        ...showError,
                        subject: false,
                      });
                      onChange("subject", e.target.value);
                    }}
                    value={subject}
                    pattern={emailRegEx}
                  />
                </div>
              </div>
              <div>
                {showError.subject ? (
                  <span className="error t-12 ms-2">
                    {getLocaleString(
                      "common.subject_required",
                      "Subject is Required"
                    )}
                  </span>
                ) : null}
              </div>
              {showCheckForPortal && (
                <div className="py-2 pl m-2">
                  <div>
                    <label>
                      <input
                        tabIndex="5"
                        type="checkbox"
                        className="md-checkbox align-middle"
                        defaultChecked={hidePayBtn}
                        onChange={(e) =>
                          setemailData({
                            ...email,
                            hidePayBtn: e.target.checked,
                          })
                        }
                        data-tlabel={`checkbox remove View Invoices button`}
                      />
                      <span className="ms-1">
                        {getLocaleString(
                          "common.remove_view_invoices_button",
                          "Remove View Invoices button"
                        )}
                      </span>
                    </label>
                  </div>
                </div>
              )}
              <div id="preview-email" className="d-none" />
              <div className="m-2" tabIndex="6">
                <CKEditor
                  key={customerNameToUse}
                  disabled={showInvoiceTable}
                  editor={ClassicEditor}
                  data={editorValue}
                  config={{
                    toolbar: [
                      "heading",
                      "|",
                      "bold",
                      "italic",
                      "numberedList",
                      "bulletedList",
                      "undo",
                      "redo",
                    ],
                  }}
                  onChange={(event, editor) => {
                    setShowError({
                      ...showError,
                      body: false,
                    });
                    const data = editor.getData();
                    onChange(
                      "body",
                      data.substr(data.length - 7, 6) === "figure"
                        ? data + "<p><br data-cke-filler=true></p>"
                        : data
                    );
                  }}
                />
                <div className="text-end pb-2 text-danger t-12">
                  {body.length === charLimit && (
                    <b>
                      {getLocaleString(
                        "common.max_character_limit",
                        "Max characters limit"
                      )}
                      :{charLimit}
                    </b>
                  )}
                </div>
                <div>
                  {showError.body ? (
                    <span className="error t-12">
                      {getLocaleString(
                        "common.email_body_required",
                        "Email body is required"
                      )}
                    </span>
                  ) : null}
                </div>
              </div>

              {get(currentTemplate, "body", "").includes(
                "{{invoice_list_with_details}}"
              ) &&
              showInvoiceTable &&
              enableEditInvoiceButtonEmail() ? (
                <div className="m-2 border rounded p-2" id="invoice-table">
                  <span>
                    {getLocaleString(
                      "common.edit_invoice_details",
                      "Edit Invoice Details"
                    )}
                    :
                  </span>
                  {generateEditableTable()}
                </div>
              ) : null}
              <input
                data-cy="attach"
                type="file"
                tabIndex="7"
                id="attach"
                className="d-none"
                multiple
                onChange={(e) => {
                  onDrop({ item: e.target });
                }}
              />
              <div className="text-secondary ms-2 my-2" id="email_attachment">
                <span>
                  {getLocaleString(
                    "common.atttachments_max_limit_10mb",
                    "Attachments (Maximum limit is 10MB)"
                  )}
                  :
                  <i
                    role="presentation"
                    cyid="fa-paperclip"
                    className="fas fa-paperclip cursor-pointer ps-2"
                    onClick={() => {
                      document.getElementById("attach").click();
                    }}
                    title="Add attachments"
                  />
                </span>
                {fileSizeError ? (
                  <div className="error t-12">
                    {getLocaleString(
                      "common.total_attachment_10mb",
                      "Total attachments size should not exceed 10MB"
                    )}
                  </div>
                ) : null}
              </div>
              {fetchingInvoices ? (
                <div className="row mx-2">
                  <div className="text-center w-100 p-2 text-secondary">
                    {getLocaleString(
                      "common.looking_for_attachments",
                      "Looking for invoice attachments"
                    )}{" "}
                    &nbsp;{" "}
                    <i className="ms-2 fa fa-spinner fa-spin text-primary" />
                    {skip_attachments ? (
                      <>
                        <br />
                        <Button
                          variant="success"
                          as="div"
                          size="sm"
                          role="presentation"
                          className="mt-2"
                          onClick={() => {
                            onSkipAttachments();
                            regenerateCancelToken();
                          }}
                        >
                          {getLocaleString(
                            "common.skip_attachments",
                            "Skip attachments"
                          )}
                        </Button>
                      </>
                    ) : null}
                  </div>
                </div>
              ) : null}
              <div className="row mx-2">
                {files.length ? (
                  files.map((file, i) => {
                    const filetTypes = {
                      pdf: "fa fa-file-pdf-o red-file-highlighter",
                      jpeg: "fa fa-file-photo-o blue-file-highlighter",
                      png: "fa fa-file-photo-o blue-file-highlighter",
                      jpg: "fa fa-file-photo-o blue-file-highlighter",
                      xlsx: "fa fa-file-excel-o green-file-highlighter",
                      xls: "fa fa-file-excel-o green-file-highlighter",
                    };
                    let fileExtension = get(file, "name", "").split(".");
                    fileExtension = get(
                      fileExtension,
                      `${fileExtension.length - 1}`,
                      ""
                    ).toLowerCase();
                    return (
                      <Card
                        className="col-md-3 emailthumbnail cursor-pointer"
                        height="80"
                      >
                        {!file.isbase64 ? (
                          <div>
                            <div className="file-wrapper">
                              <span className="invoice-file-name">
                                {fileExtension}
                              </span>
                              <span className="invoice-file-size">
                                {convertDecimalNo(file.size / 1000)}
                                kB
                              </span>
                            </div>
                            <div
                              role="presentation"
                              className="file-icon"
                              onClick={() => {
                                if (file.uri) {
                                  previewInNewTab(file.uri, "other");
                                }

                                const fileUri = filesUri.find(
                                  (d) => d.name === file.name
                                );

                                if (fileUri && fileUri.isPDF) {
                                  return fileUri.uri();
                                }

                                if (fileUri) {
                                  previewInNewTab(fileUri.uri, "other");
                                }
                              }}
                            >
                              <i
                                className={`${
                                  filetTypes[fileExtension]
                                    ? filetTypes[fileExtension]
                                    : "fa fa-file-o"
                                } fa-7x`}
                              />
                            </div>
                          </div>
                        ) : (
                          <div
                            role="presentation"
                            cyid="file-wrapper"
                            onClick={() => {
                              const data = JSON.parse(get(file, "content", ""));
                              const ext = getUrlExtension(file.name);
                              previewInNewTab(
                                data
                                  ? `data:application/${_get(
                                      MIMETYPE,
                                      ext,
                                      "txt"
                                    )};base64, ${encodeURI(data)}`
                                  : file.uri,
                                ext
                              );
                            }}
                          >
                            <div className="file-wrapper">
                              <span className="invoice-file-name">
                                {fileExtension}
                              </span>
                              {!!file.content && (
                                <span className="invoice-file-size">
                                  {convertDecimalNo(file.content.length / 1000)}
                                  kB
                                </span>
                              )}
                            </div>
                            <div className="file-icon">
                              <i
                                className={`${
                                  filetTypes[fileExtension]
                                    ? filetTypes[fileExtension]
                                    : "fa fa-file-o"
                                } fa-7x`}
                              />
                            </div>
                          </div>
                        )}
                        <Card.Body>
                          <Card.Text>
                            <div className="row p-0">
                              <div className="col-md-9 text-truncate">
                                <span title={file.name}>{file.name}</span>
                              </div>
                              <div className="col-md-3">
                                <i
                                  role="presentation"
                                  cyid="delete-attachment"
                                  body="Remove attachment"
                                  className="fa fa-remove cursor-pointer float-end"
                                  onClick={() => removeFiles(i, file.name)}
                                />
                              </div>
                            </div>
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    );
                  })
                ) : (
                  <div className="text-center w-100 p-2 text-secondary">
                    {getLocaleString(
                      "common.no_attachments_found",
                      "No attachments found"
                    )}
                  </div>
                )}
              </div>
            </div>
          </DropContainer>
        </DNDContainer>
      </Modal>
    )
  );
}

const mapStateToProps = (state) => ({
  email: state.email,
  payrollSettingsData: state.payrollSettingsData,
});

export default connect(mapStateToProps, null)(EmailCreator);
