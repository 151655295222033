import React from "react";
import InfiniteScroll from "react-infinite-scroll-component";

function InfiniteScrollList(props) {
  const {
    dataLength = 0,
    wrapperClass = "infinite-list-wrapper",
    loader = "",
    hasMore = false,
    getNextList,
    refreshList,
    children,
  } = props;

  return (
    <div id="infinite-list-wrapper" className={wrapperClass}>
      <InfiniteScroll
        dataLength={dataLength}
        next={getNextList}
        hasMore={hasMore}
        loader={loader}
        refreshFunction={refreshList}
        pullDownToRefresh
        pullDownToRefreshThreshold={50}
        scrollableTarget="infinite-list-wrapper"
        className="p-0 m-0"
      >
        {children}
      </InfiniteScroll>
    </div>
  );
}

export default InfiniteScrollList;
