import { ReducersTypes } from "constants/ReducersTypes";
import { getUXSettingsFromStorage } from "utils";
import moment from "moment";
import { getDateByRangeInterval } from "utils/dates";
import { getLocaleString } from "utils/localization/locale";
import mapValues from "lodash/mapValues";

export const TASK_LIST_COLUMNS = {
  [getLocaleString("common.status", "Status")]: true,
  [getLocaleString("common.title", "Title")]: true,
  [getLocaleString("dashboard.associations", "Associations")]: true,
  [getLocaleString("common.assigned_to", "Assigned To")]: true,
  [getLocaleString("common.last_contacted", "Last Contacted")]: true,
  [getLocaleString("common.task_type", "Task Type")]: true,
  [getLocaleString("common.dueDate", "Due Date")]: true,
  [getLocaleString("common.created_date", "Created Date")]: true,
  [getLocaleString("common.customer_category", "Customer Category")]: true,
  [getLocaleString("common.workflow_assigned", "Workflow Assigned")]: true,
  [getLocaleString("common.hierarchical_total_balance", "Current Open Balance")]: true,
};

let i = 1;
export const initialDashboardTaskColumnsOrder = mapValues(
  TASK_LIST_COLUMNS,
  () => i++
);
i = 1;

let savedFilters = getUXSettingsFromStorage("TASK_LIST_FILTERS");
if (savedFilters && savedFilters.date_label) {
  const { date_label, from_date, to_date } = savedFilters;
  const [sd, ed] = getDateByRangeInterval(date_label, from_date, to_date);
  savedFilters = {
    ...savedFilters,
    from_date: sd,
    to_date: ed,
  };
}
export const initialTaskFilters = {
  from_date: moment(0).format("MM/DD/YYYY"),
  to_date: moment().format("MM/DD/YYYY"),
  date_type: "",
  task_type: "",
  task_priority: "",
  date_label: "All",
  assigned_to: "",
  nextPage: null,
  prevPage: null,
  page: 1,
  sort_by: "due_date",
  sort_order: "desc",
  task_status: "",
  client_customer_rel_id: "",
};

export const initialData = {
  payablesData: null,
  receivablesData: null,
  isFetching: true,
  taskManagement: {
    arRepList: [],
    taskList: [],
    isFetching: false,
    filters: {
      ...initialTaskFilters,
      ...savedFilters,
    },
    selectedTask: {},
    selectedRows: [],
    isheaderchecked: false,
    isrowselected: false,
  },
  userList: {
    list: [],
    isFetching: true,
  },
  arUserList: [],
  assignedUser: {},
};

const dashboard = (state = initialData, action) => {
  const { type, payload } = action;
  switch (type) {
    case ReducersTypes.SET_DASHBOARD_DATA: {
      const result = { ...state, ...action.payload };
      return result;
    }
    case ReducersTypes.SET_PAYABLE_DATA:
      return {
        ...state,
        payablesData: payload.data,
        isFetching: action.isFetching,
      };

    case ReducersTypes.SET_RECEIVABLE_DATA:
      return {
        ...state,
        receivablesData: payload.data,
        isFetching: action.isFetching,
      };
    case ReducersTypes.FETCH_TASK_LIST:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
          taskList: payload.taskList || state.taskManagement.taskList,
          isFetching: payload.isFetching,
          filters: {
            ...state.taskManagement.filters,
            ...payload.filters,
          },
          exportedData: payload?.exportedData,
          exporting: payload?.exporting,
        },
      };
    case ReducersTypes.EDIT_TASK_LIST:
      return {
        ...state,
        taskManagement: {
          ...state.taskManagement,
        },
        ...payload,
      };
    case ReducersTypes.USER_LIST_FETCH:
      return {
        ...state,
        userList: {
          ...action.payload,
        },
      };
    case ReducersTypes.APPLY_USER_FILTER:
      return {
        ...state,
        assignedUser: action.payload,
      };
    default:
      return state;
  }
};

export default dashboard;
