import React from "react";
import ClickAwayListener from "components/ClickAwayListener";
import Button from "./";
import classNames from "classnames";

function DropdownButton(props) {
  const {
    children,
    buttonText = "",
    buttonProps,
    filterButton = false,
    showDropdown,
    btnClassName = "",
    columnsButton = false,
    setShowDropdown,
    dropdownWrapperClassName = "",
    dropdownWrapperProps = {},
  } = props;

  const buttonStartIcon = filterButton ? (
    <i className="fa fa-sliders" aria-hidden="true" />
  ) : null;
  const buttonEndIcon = columnsButton ? (
    <i className="fas fa-ellipsis-v" aria-hidden="true" />
  ) : null;

  const buttonClassName = classNames(
    "cursor-pointer",
    "dropdown-toggle",
    btnClassName,
    { "no-arrow": columnsButton }
  );
  const dropdownWrapperClassnames = classNames(
    "dropdown-menu",
    "hide-scroll",
    dropdownWrapperClassName,
    {
      show: showDropdown,
      "dropdown-list-wrap": columnsButton,
    }
  );

  return (
    <div className="dropdown d-inline-block">
      <Button
        className={buttonClassName}
        startIcon={buttonStartIcon}
        endIcon={buttonEndIcon}
        {...buttonProps}
        onClick={() => setShowDropdown(!showDropdown)}
        data-tlabel={buttonText}
      >
        {buttonText}&nbsp;
      </Button>
      {showDropdown ? (
        <ClickAwayListener onClickAway={() => setShowDropdown(false)}>
          <div
            className={dropdownWrapperClassnames}
            aria-labelledby="dropdownMenuLink"
            {...dropdownWrapperProps}
          >
            {children}
          </div>
        </ClickAwayListener>
      ) : null}
    </div>
  );
}

export default React.memo(DropdownButton);

/* <DropdownButton buttonProps={{ variant: "primary", size: "md", as: "button" }}>
    {children}
</DropdownButton>; */
