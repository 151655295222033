import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { checkForeignCurrency, getUXSettingsFromStorage } from "utils";
import { enableDefaultAllDateFilter } from "handlers/features";
import { getDateByRangeInterval } from "utils/dates";
import { getLocaleString } from "utils/localization/locale";
import mapValues from "lodash/mapValues";

export const VENDOR_DETAILS_NONINVOICE_LIST_COLUMNS_ORDER = {
  [getLocaleString("common.payIQDate", "PayIQ Date")]: 1,
  [getLocaleString("common.dueCloseDate", "Due/Closed Date")]: 2,
  [getLocaleString("common.amountDue", "Amount Due")]: 3,
  [getLocaleString("common.amountPaid", "Amount Paid")]: 4,
  [getLocaleString("common.status", "Status")]: 5,
};

export const VENDOR_DETAILS_CREDIT_LIST_COLUMNS = {
  [getLocaleString("common.credit_number", "Credit Number")]: true,
  [getLocaleString("common.dueDate", "Due Date")]: true,
  [getLocaleString("common.closedDate", "Closed Date")]: true,
  [getLocaleString("common.amountDue", "Amount Due")]: true,
  [getLocaleString("common.amountPaid", "Amount Paid")]: true,
  [getLocaleString("common.status", "Status")]: true,
  ...getUXSettingsFromStorage("VENDOR_DETAILS_CREDIT_LIST_COLUMNS"),
};

export const VENDOR_DETAILS_INVOICE_LIST_COLUMNS = {
  [getLocaleString("common.invoice", "Invoice")]: true,
  [getLocaleString("common.payIQDate", "PayIQ Date")]: true,
  [getLocaleString("common.dueDate", "Due Date")]: true,
  [getLocaleString("common.closedDate", "Closed Date")]: true,
  [getLocaleString("common.InvoiceAmount", "Invoice Amount")]: true,
  [getLocaleString("common.amountDue", "Amount Due")]: true,
  [getLocaleString("common.amountPaid", "Amount Paid")]: true,
  [getLocaleString("common.projectID", "Project ID")]: false,
  [getLocaleString("common.entity", "Entity")]: true,
  [getLocaleString("common.status", "Status")]: true,
  ...getUXSettingsFromStorage("VENDOR_DETAILS_INVOICE_LIST_COLUMNS"),
};

let i = 1;
export const VENDOR_DETAILS_INVOICE_LIST_COLUMNS_ORDER = mapValues(
  VENDOR_DETAILS_INVOICE_LIST_COLUMNS,
  () => i++
);
i = 1;

let j = 1;
export const VENDOR_DETAILS_CREDIT_COLUMNS_ORDER = mapValues(
  VENDOR_DETAILS_CREDIT_LIST_COLUMNS,
  () => j++
);
j = 1;

const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().subtract(2, "year").format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().add(1, "year").format("MM/DD/YYYY"),
};

const { keyNamePrefix } = checkForeignCurrency();

let savedVendorDetailsFilters = getUXSettingsFromStorage(
  "SUPPLIER_DETAILS_FILTERS"
);
if (savedVendorDetailsFilters && savedVendorDetailsFilters.interval) {
  const { interval, from_date, to_date } = savedVendorDetailsFilters;
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedVendorDetailsFilters = {
    ...savedVendorDetailsFilters,
    from_date: sd,
    to_date: ed,
  };
}

let savedVendorNonInvFilters = getUXSettingsFromStorage(
  "SUPPLIER_DETAILS_NON_INV_FILTERS"
);
if (savedVendorNonInvFilters && savedVendorNonInvFilters.interval) {
  const { interval, from_date, to_date } = savedVendorNonInvFilters;
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedVendorNonInvFilters = {
    ...savedVendorNonInvFilters,
    from_date: sd,
    to_date: ed,
  };
}

export const initialVendorDetailsFilter = {
  page: 1,
  tag_ids: "",
  interval: enableDefaultAllDateFilter() ? "All" : "",
  ...dateRange,
  not_tag_ids: "",
  selectedSection: null,
  exclude_payment_type: "",
  sort_by: `${keyNamePrefix}total_amount_due`,
  sort_order: "desc",
  appliedCustomFilters: {},
};

export const initialNonInvFilters = {
  page: 1,
  interval: enableDefaultAllDateFilter() ? "All" : "",
  ...dateRange,
  selectedSection: null,
  sort_by: "total_amount_due",
  sort_order: "desc",
};

export const initialVendorDetailsData = {
  vendorRelData: {},
  isChartDataFetching: true,
  chartDetails: {},
  invoiceListData: {
    invoiceList: [],
    isFetching: false,
    visibleColumns: {
      ...VENDOR_DETAILS_INVOICE_LIST_COLUMNS,
    },
    filters: {
      ...initialVendorDetailsFilter,
      ...savedVendorDetailsFilters,
    },
  },
  creditListData: {
    creditList: [],
    isFetching: false,
    visibleColumns: {
      ...VENDOR_DETAILS_CREDIT_LIST_COLUMNS,
    },
    filters: {
      ...initialVendorDetailsFilter,
    },
  },
  nonInvoiceListData: {
    nonInvoiceList: [],
    isFetching: false,
    filters: {
      ...initialNonInvFilters,
      ...savedVendorNonInvFilters,
    },
  },
  paymentPlanData: {
    paymentPlanList: [],
    isFetching: false,
    filters: {},
  },
  driverActivity: {
    filters: {},
  },
};

const vendorDetails = (state = initialVendorDetailsData, action) => {
  const keytoUpdate = action?.payload?.key;
  switch (action.type) {
    case ReducersTypes.SET_VENDOR_DETAILS_FILTER: {
      return {
        ...state,
        [keytoUpdate]: {
          ...state[keytoUpdate],
          filters: {
            ...state[keytoUpdate].filters,
            ...action.payload.filters,
          },
        },
      };
    }

    case ReducersTypes.RESET_VENDOR_DETAILS_FILTER: {
      return {
        ...state,
        [keytoUpdate]: {
          ...state[keytoUpdate],
          filters: {
            ...initialVendorDetailsData[keytoUpdate].filters,
            ...action.payload.filters,
          },
        },
      };
    }

    case ReducersTypes.SET_VENDOR_DETAILS_DATA: {
      if (keytoUpdate) {
        return {
          ...state,
          [keytoUpdate]: {
            ...state[keytoUpdate],
            ...action.payload.data,
            filters: {
              ...state[keytoUpdate].filters,
              ...action.payload.filters,
            },
          },
        };
      }
      return {
        ...state,
        ...action.payload,
      };
    }

    default:
      return state;
  }
};

export default vendorDetails;
