import DotLoader from "components/DotLoader/DotLoader";
import React, { useEffect, useRef } from "react";
import Markdown from "react-markdown";

function MessageLogs({
  isLoadingRes = false,
  fullWidth = false,
  messageList = [],
}) {
  const bottomRef = useRef();

  useEffect(() => {
    bottomRef.current.scrollIntoView({ behavior: "smooth" });
  }, [messageList]);

  const markdownComponentProps = {
    a: ({ node, ...props }) => (
      <a {...props} target="_blank" rel="noopener noreferrer">
        {props.children}
      </a>
    ),
  };

  return (
    <div
      className="messageLogs__container p-2 "
      style={{ minHeight: "300px", maxHeight: fullWidth ? "auto" : "350px" }}
    >
      <div ref={bottomRef}></div>
      {messageList?.map((msg, index, arr) => {
        return (
          <>
            {isLoadingRes && index === 0 ? <DotLoader /> : null}
            <div
              className={`messageLogs__message messageLogs__message--${msg.role}`}
            >
              <Markdown
                components={markdownComponentProps}
                children={msg.content}
              />
            </div>
          </>
        );
      })}
    </div>
  );
}

export default MessageLogs;
//
