export const REQUEST_METHODS = [
  { id: 99, label: "ALL", value: "" },
  // { id: 1, label: "GET", value: "get" },
  { id: 2, label: "POST", value: "post" },
  { id: 3, label: "PUT", value: "put" },
  { id: 4, label: "DELETE", value: "delete" },
  { id: 5, label: "PATCH", value: "patch" },
  { id: 6, label: "OPTIONS", value: "options" },
  { id: 7, label: "HEAD", value: "head" },
];

export const ANALYTICS_AUDIT_LOGS_FILTERS = "ANALYTICS_AUDIT_LOGS_FILTERS";

export const HTTP_STATUS = {
  200: { code: 200, label: "Succeeded", icon: "✅", color: "#28a745" },
  201: { code: 201, label: "Created", icon: "🆕", color: "#17a2b8" },
  400: { code: 400, label: "Bad Request", icon: "🚫", color: "#dc3545" },
  401: { code: 401, label: "Unauthorized", icon: "🔒", color: "#ff9800" },
  403: { code: 403, label: "Forbidden", icon: "⛔", color: "#d9534f" },
  404: { code: 404, label: "Not Found", icon: "🔍", color: "#6c757d" },
  405: { code: 405, label: "Method Not Allowed", icon: "❌", color: "#f44336" },
  415: {
    code: 415,
    label: "Unsupported Media Type",
    icon: "🎥❌",
    color: "#9c27b0",
  },
  429: { code: 429, label: "Too Many Requests", icon: "🚦", color: "#ff5722" },
  500: {
    code: 500,
    label: "Internal Server Error",
    icon: "💥",
    color: "#c82333",
  },
  504: {
    code: 504,
    label: "Gateway Timeout",
    icon: "⏳",
    color: "#ff9800",
    422: {
      code: 422,
      label: "Unprocessable Entity",
      icon: "⚠️",
      color: "#f44336",
    },
  },
};

export const MODAL_COMPONENT = {
  USER_TIMELINE: "timeline",
  USER_REQ_PAYLOAD: "API Request Details",
};
