import React, { useEffect, useState } from "react";
import DateRangePicker from "components/DateRangePicker";
import PieChartPlaceholder from "components/Recharts/Placeholders/Piechart";
import {
  setconfirmationboxData,
  resetconfirmationboxData,
} from "components/ConfirmationBox";
import CustomSpinner from "components/Spinner";
import { handleAPIError } from "handlers/setters";
import debounce from "lodash/debounce";
import get from "lodash/get";
import sum from "lodash/sum";
import moment from "moment";
import { CSVLink } from "react-csv";
import { connect } from "react-redux";
import isEqual from "lodash/isEqual";
import { Cell, Label, Pie, PieChart, Sector } from "recharts";
import {
  getFAWC,
  convertAmount,
  getShortNumber,
  convertDecimalNo,
  processStringCell,
  convertAmountWithDecimal,
} from "utils";
import { instanceWithRetry as instance } from "actions/axiosInstance";
import { getFormattedDate, getDateByRangeInterval } from "utils/dates";
import { ExportXLSX, rearrangeCSVColumns } from "utils/table-utils";
import Toaster from "components/Toaster";
import { trimText } from "utils/strings";
import { getuxvalue, setuxvalue } from "handlers/ux";
import AssociateInvoiceList from "./AssociateInvoice";
import TokenManager from "utils/TokenManager";
import Button from "components/Button";
import Table from "components/ReactTable";
import Dropdown from "components/Button/Dropdown";
import { generateQueryString } from "utils/query";
import { getLocaleString } from "utils/localization/locale";

export const foreignKeyMap = {
  salary: "foreign_salary",
  bonus: "foreign_bonus",
  commission: "foreign_commission",
  benefits: "foreign_benefits",
  tax: "foreign_tax",
  misc: "foreign_misc",
  total: "foreign_total",
};

export const PAYROLL_LIST_COLUMN_ORDER = {
  Amount: 4,
  "Bank Account": 7,
  Date: 2,
  Entity: 3,
  Invoices: 6,
  Note: 8,
  Vendor: 5,
};

const renderActiveShape = (props) => {
  const { cx, cy, innerRadius, outerRadius, startAngle, endAngle, fill } =
    props;

  return (
    <g>
      <Sector
        className="cursor-pointer"
        cx={cx}
        cy={cy}
        innerRadius={innerRadius}
        outerRadius={outerRadius + 5}
        startAngle={startAngle}
        endAngle={endAngle}
        fill={fill}
      />
    </g>
  );
};

const DonutChart = ({ data, onRecordClick, total, selectedPayroll }) => {
  const [activeindex, setactiveindex] = useState(null);

  useEffect(() => {
    (get(selectedPayroll, "dataindex", null) ||
      get(selectedPayroll, "dataindex", null) === 0) &&
      setactiveindex(get(selectedPayroll, "dataindex", null));
  }, [selectedPayroll]);

  function onPieEnter(data, index) {
    setactiveindex(index);
  }
  function onPieLeave() {
    setactiveindex(null || get(selectedPayroll, "dataindex", null));
  }

  return (
    <PieChart width={320} height={320}>
      <Pie
        animationDuration={1000}
        activeIndex={activeindex}
        activeShape={renderActiveShape}
        data={data}
        innerRadius={60}
        outerRadius={140}
        fill="#8884d8"
        dataKey="value"
        onClick={(e) => onRecordClick(e.payload.payload)}
        onMouseEnter={onPieEnter}
        onMouseLeave={onPieLeave}
      >
        <Label
          className="cursor-pointer"
          width={30}
          position="center"
          onClick={() => onRecordClick(null)}
        >
          {`${getLocaleString("common.total", "Total")} ${getShortNumber(
            total
          )}`}
        </Label>
        {data.map((entry, index) => (
          <Cell
            className="cursor-pointer"
            fill={COLORS[index % COLORS.length]}
          />
        ))}
      </Pie>
    </PieChart>
  );
};

const COLORS = [
  "#2B7EF6",
  "#7CA82D",
  "#EF853A",
  "#DB504D",
  "#424348",
  "#90ED7D",
  "#3C861F",
  "#F9D7E0",
];

function ExportCSV(
  data,
  allbankaccounts = [],
  allentities,
  columnsOrder = PAYROLL_LIST_COLUMN_ORDER
) {
  if (!data.length) {
    return [
      {
        [getLocaleString("common.date", "Date")]: "",
        [getLocaleString("common.amount", "Amount")]: "",
        [getLocaleString("common.entity", "Entity")]: "",
        [getLocaleString("common.vendor", "Vendor")]: "",
        [getLocaleString("common.invoices", "Invoices")]: "",
        [getLocaleString("common.bank_account", "Bank Account")]: "",
        [getLocaleString("common.note", "Note")]: "",
      },
    ];
  }
  const sortedData = data.filter((row) => {
    const corporateEntityId = Number(row.corporate_entity_id);
    if (corporateEntityId === 0) {
      return true;
    }
    const entity = allentities.find(
      (entity) => Number(entity.id) === corporateEntityId
    );

    return corporateEntityId === entity?.id;
  });
  const csvData = sortedData.map((element) =>
    rearrangeCSVColumns(columnsOrder, {
      [getLocaleString("common.date", "Date")]: moment(
        get(element, "due_date", "")
      ).format("MM/DD/YYYY"),
      [getLocaleString("common.amount", "Amount")]: convertDecimalNo(
        get(element, "total", 0)
      ),
      [getLocaleString("common.entity", "Entity")]: processStringCell(
        get(element, "corporate_entity.name", "")
      ),
      [getLocaleString("common.vendor", "Vendor")]: get(
        element,
        "client_supplier_rel.associated_supplier.name",
        "-"
      ),
      [getLocaleString("common.invoices", "Invoices")]: get(
        element,
        "attached_invoices",
        []
      )
        .map((d) => get(d, "invoice.invoice_number", ""))
        .join(","),
      [getLocaleString("common.bank_account", "Bank Account")]: get(
        allbankaccounts.find(
          (x) => x.id === get(element, "bank_account_id", "-")
        ),
        "name",
        ""
      ),
      [getLocaleString("common.note", "Note")]: get(element, "notes", ""),
    })
  );

  return csvData;
}
export const initialPayrollFilters = {
  status: "",
  endDate: moment().add(365, "days").format("MM/DD/YYYY"),
  interval: "",
  currPage: 1,
  dateType: "",
  startDate: moment().subtract(2, "month").format("MM/DD/YYYY"),
  api_sort_by: "due_date",
  vendorSelect: null,
  invoiceSelect: null,
  vendorCategory: "",
  api_sort_order: "desc",
  currentScenario: "",
  selectedSupplier: "",
  payroll_category: "",
  corporate_entity_id: "",
  exclude_invoices_attached_payroll: "",
};
export class PayrollList extends React.Component {
  constructor() {
    super();
    this.debounceMethod = debounce(this.searchVendor.bind(this), 500);
    this.handleAddClick = this.handleAddClick.bind(this);
    this.state = {
      rows: [],
      originalRows: [],
      showFilter: false,
      showexport: false,
      isFetching: true,
      isFetchingOriginal: true,
      supplierList: [],
      payrollGraphData: [],
      allbankaccounts: [],
      totalRecords: 0,
      ...initialPayrollFilters,
      columnOrder: getuxvalue("PAYROLL_LIST_COLUMN_ORDER"),
    };
    this.filters = getuxvalue("PAYROLL_LIST_FILTERS");
  }

  componentDidMount() {
    let filters = getuxvalue("PAYROLL_LIST_FILTERS");
    if (filters) {
      const { interval, startDate, endDate } = filters;
      if (filters.interval) {
        const [sd, ed] = getDateByRangeInterval(interval, startDate, endDate);
        filters = {
          ...filters,
          startDate: sd,
          endDate: ed,
        };
      }
      this.setState(
        {
          ...filters,
        },
        () => this.filterData()
      );
    } else {
      this.filterData();
    }
    this.getBankAndScenarioData();
    window.scrollTo(0, 0);
  }

  getBankAndScenarioData = () => {
    Promise.allSettled([
      instance.get("bankaccount?per_page=4000"),
      instance.get("cashforecast/scenario"),
    ])
      .then((resp) => {
        this.setState({
          allbankaccounts: get(resp, "[0].value.data.result", []),
          scenarioList: get(resp, "[1].value.data.result", []),
        });
      })
      .catch((e) => handleAPIError(e));
  };

  handleAddClick() {
    const { history } = this.props;
    history.push(`payroll/create`);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", () => {});
  }

  setshowExport = (flag) =>
    this.setState({
      showexport: flag,
    });

  setNextPage = () =>
    this.setState(
      {
        currPage: this.state.currPage + 1,
      },
      this.filterData
    );

  setPrevPage = () =>
    this.setState(
      {
        currPage: this.state.currPage === 1 ? 1 : this.state.currPage - 1,
      },
      this.filterData
    );

  searchVendor = (text, searchBy) => {
    if (text) {
      instance
        .get(`apinvoice/search?per_page=100&${searchBy}=${trimText(text)}`)
        .then((res) => {
          this.setState({
            supplierList: res.data.result,
          });
        })
        .catch((e) => handleAPIError(e));
    }
  };

  filterData = (isMutate = false) => {
    this.setState({
      showFilter: false,
    });
    const {
      status,
      endDate,
      interval,
      currPage,
      dateType,
      startDate,
      api_sort_by,
      vendorSelect,
      invoiceSelect,
      vendorCategory,
      api_sort_order,
      currentScenario,
      payroll_category,
      corporate_entity_id,
      exclude_invoices_attached_payroll,
    } = this.state;

    const urlParams = {
      page: currPage,
      status,
      mutate: isMutate ? 1 : "",
      to_date: endDate,
      sort_by: api_sort_by,
      per_page: 20,
      category: vendorCategory,
      from_date: startDate,
      date_type: dateType,
      sort_order: api_sort_order,
      invoice_id: get(invoiceSelect, "invoice_number", ""),
      supplier_id: get(vendorSelect, "id", ""),
      payroll_category,
      corporate_entity_id,
      forecast_scenario_id: currentScenario,
      exclude_invoices_attached_payroll: exclude_invoices_attached_payroll
        ? 1
        : "",
    };
    const URLTemp = `payroll${generateQueryString(urlParams)}`;

    const filters = {
      status,
      endDate,
      currPage,
      interval,
      dateType,
      startDate,
      api_sort_by,
      vendorSelect,
      invoiceSelect,
      vendorCategory,
      api_sort_order,
      currentScenario,
      payroll_category,
      corporate_entity_id,
      exclude_invoices_attached_payroll,
    };

    setuxvalue("PAYROLL_LIST_FILTERS", filters);
    this.getData(URLTemp === `payroll?page=${currPage}` ? "" : URLTemp);
  };

  getData = (dataUrl) => {
    const { endDate, startDate, currPage, api_sort_by, api_sort_order } =
      this.state;
    let URL =
      dataUrl ||
      `payroll?page=${currPage}&from_date='${startDate}'&to_date='${endDate}'&sort_by=${api_sort_by}&sort_order=${api_sort_order}`;
    this.setState({
      rows: [],
      originalRows: [],
      isFetching: true,
    });
    instance
      .get(URL)
      .then((response) => {
        this.setState({
          isFetching: false,
          isFetchingOriginal: false,
        });
        const isEntitySelected = TokenManager.isCorpEntityRelatedToUser();

        const modified = isEntitySelected
          ? response.data.result.map((d) => {
              let obj = d;
              Object.keys(foreignKeyMap).forEach((e) => {
                if (d) {
                  obj = {
                    ...obj,
                    [e]: obj[foreignKeyMap[e]],
                  };
                }
              });
              return obj;
            })
          : response.data.result;
        this.setState(
          {
            rows: modified,
            originalRows: modified,
            showFilter: false,
            totalRecords: get(response, "data.total", 0),
          },
          () => this.setpayrollGraphData()
        );
      })
      .catch((e) => {
        this.setState({
          isFetching: false,
          showFilter: false,
        });
        if (e) {
          handleAPIError(e);
        }
      });
  };

  exportAllData = (flag) => {
    const {
      status,
      endDate,
      dateType,
      startDate,
      api_sort_by,
      vendorSelect,
      invoiceSelect,
      vendorCategory,
      api_sort_order,
      allbankaccounts,
      currentScenario,
      payroll_category,
      corporate_entity_id,
      exclude_invoices_attached_payroll,
    } = this.state;

    const urlParams = {
      status,
      to_date: endDate,
      sort_by: api_sort_by,
      per_page: 2000,
      from_date: startDate,
      category: vendorCategory,
      date_type: dateType,
      sort_order: api_sort_order,
      invoice_id: get(invoiceSelect, "invoice_number", ""),
      supplier_id: get(vendorSelect, "id", ""),
      payroll_category,
      corporate_entity_id,
      forecast_scenario_id: currentScenario,
      exclude_invoices_attached_payroll: exclude_invoices_attached_payroll
        ? 1
        : "",
    };

    this.setState({ exporting: true });
    instance
      .get(`payroll${generateQueryString(urlParams)}`)
      .then((response) => {
        const result = get(response, "data.result", []);
        const isEntitySelected = TokenManager.isCorpEntityRelatedToUser();

        const modified = isEntitySelected
          ? result.map((d) => {
              let obj = d;
              Object.keys(foreignKeyMap).forEach((e) => {
                if (d && [e]) {
                  obj = {
                    ...obj,
                    [e]: obj[foreignKeyMap[e]],
                  };
                }
              });
              return obj;
            })
          : result;
        this.setState(
          {
            exportedData: modified,
            showexport: false,
            exporting: false,
          },
          () => {
            const _nores = !get(response, "data.result[0]", true);
            if (_nores) {
              Toaster("No records found", "info");
            }
            if (window.Cypress) {
              // Do not attempt to actually download the file in test.
              // Just leave the anchor in there. Ensure your code doesn't
              // automatically remove it either.
              return;
            }
            if (flag === true) {
              this.refs.csv.link.click();
            } else {
              ExportXLSX(
                ExportCSV(
                  modified,
                  allbankaccounts,
                  this.props.allentities,
                  this.state.columnOrder
                ),
                `payroll${moment().format("MMMM Do YYYY-h:mm:ss-a")}.xlsx`
              );
            }
          }
        );
      })
      .catch((e) => {
        this.setState({
          exporting: false,
        });
        handleAPIError(e);
      });
  };

  setpayrollGraphData = () => {
    const {
      endDate,
      startDate,
      currentScenario,
      payroll_category,
      exclude_invoices_attached_payroll,
    } = this.state;
    const { allentities } = this.props;
    const isEntitySelected = TokenManager.isCorpEntityRelatedToUser();
    if (!isEntitySelected && !allentities.some((d) => d.name === "None")) {
      allentities.push({
        name: "None",
        id: "0",
      });
    }
    let allPromises = [];
    for (let i = 0; i < allentities.length; i++) {
      const singleObj = {
        from_date: startDate,
        to_date: endDate,
        corporate_entity_id: allentities[i].id,
      };
      if (currentScenario) {
        singleObj["forecast_scenario_id"] = currentScenario;
      }
      if (exclude_invoices_attached_payroll) {
        singleObj["exclude_invoices_attached_payroll"] = 1;
      }
      if (payroll_category) {
        singleObj["payroll_category"] = payroll_category;
      }
      allPromises.push(singleObj);
    }
    instance
      .post("payroll/totals", {
        filters: allPromises,
      })
      .then((response) => {
        const chartdata = allentities
          .map((entity, i) => ({
            name: entity.name,
            value: get(response, `data.totals[${i}].total`, null),
            dataindex: i,
            corporate_entity_id: allentities[i].id,
          }))
          .filter((x) => x.value)
          .map((x, i) => ({ ...x, dataindex: i }));
        this.setState({
          payrollGraphData: chartdata,
          chartdatareceived: true,
        });
      });
  };

  setshowFilter = (v) => this.setState({ showFilter: v });

  changeScenario = (v) => this.setState({ currentScenario: v });

  onReset = () => {
    this.setState(
      {
        ...initialPayrollFilters,
      },
      () => {
        setuxvalue("PAYROLL_LIST_FILTERS", initialPayrollFilters);
        this.getData();
      }
    );
  };

  setDates = (startDate, endDate, interval) => {
    this.setState({
      startDate: moment(startDate).format("MM/DD/YYYY"),
      endDate: moment(endDate).format("MM/DD/YYYY"),
      interval,
      showFilter: true,
    });
  };

  onClone = (data) => {
    delete data.id;
    data.due_date = moment(data.due_date, "YYYY-MM-DD").format("MM/DD/YYYY");
    data.notes = `Copy of - ${data.entity}`;
    data.bank = data.bank_account_id;
    const { currentScenario } = this.state;
    const postjson = {
      records: [
        {
          ...data,
          [currentScenario ? "forecast_scenario_id" : ""]:
            currentScenario || "",
        },
      ],
    };

    instance
      .put(`/payroll?mutate=1`, postjson)
      .then(() => {
        Toaster("Cloned", "success");
        this.filterData();
        localStorage.setItem("refresh_reports", true);
      })
      .catch((e) => handleAPIError(e));
  };

  onDelete = (id) => {
    const { currentScenario } = this.state;
    instance
      .put(`payroll/delete?id=${id}&mutate=1`, {
        [currentScenario ? "forecast_scenario_id" : ""]: currentScenario || "",
      })
      .then(() => {
        this.getData();
        localStorage.setItem("refresh_reports", true);
      })
      .catch((e) => handleAPIError(e));
  };

  onBulkDelete = () => {
    const { rows, currentScenario } = this.state;
    const apis = rows
      .filter((x) => x.isselected)
      .map((x) =>
        instance.put(`payroll/delete?id=${x.id}&mutate=1`, {
          [currentScenario ? "forecast_scenario_id" : ""]:
            currentScenario || "",
        })
      );
    Promise.all(apis)
      .then(() => {
        this.getData();
        localStorage.setItem("refresh_reports", true);
      })
      .catch((e) => handleAPIError(e));
  };

  setAPISort = (api_sort_by) => {
    this.setState(
      {
        currPage: 1,
        api_sort_by,
        api_sort_order:
          api_sort_by !== this.state.api_sort_by ||
          this.state.api_sort_order === "asc"
            ? "desc"
            : "asc",
        originalRows: [],
        rows: [],
      },
      this.filterData
    );
  };

  updatePayroll = (id, postdata) => {
    const data = {
      records: [{ ...postdata }],
    };
    instance
      .put(`/payroll?id=${id}&mutate=1`, data)
      .then(() => {
        this.filterData(true);
        Toaster(
          getLocaleString(
            "common.updated_successfully",
            "Updated successfully"
          ),
          "success"
        );
      })
      .catch((e) => handleAPIError(e));
  };

  renderDonutChart = (payrollGraphData, corporate_entity_idObj) => (
    <DonutChart
      onRecordClick={(record) => {
        this.setState(
          {
            corporate_entity_id: get(record, "corporate_entity_id", ""),
            currPage: 1,
          },
          this.filterData
        );
      }}
      data={payrollGraphData}
      total={sum(payrollGraphData.map((x) => x.value))}
      selectedPayroll={corporate_entity_idObj}
    />
  );

  render() {
    const {
      rows,
      showFilter,
      showexport,
      currPage,
      isFetching,
      startDate,
      endDate,
      payrollGraphData,
      corporate_entity_id,
      allbankaccounts = [],
      isFetchingOriginal,
      chartdatareceived,
      api_sort_by,
      api_sort_order,
      exportedData = [],
      exporting,
      totalRecords,
      currentScenario = "",
      scenarioList = [],
      columnOrder,
      payroll_category,
      exclude_invoices_attached_payroll,
    } = this.state;
    const isReadOnly = TokenManager.isReadOnlyAdmin();
    const showDecimal = get(this.props, "generalSettings.SHOW_CENTS", 0);
    const isEntitySelected = TokenManager.isCorpEntityRelatedToUser();

    const { allentities } = this.props;
    const isrowselected = rows && rows.find((x) => x.isselected);
    const isheaderchecked =
      get(rows, "[0]", "") && !rows.find((x) => !x.isselected);

    const columns = [
      {
        header: (
          <input
            className="md-checkbox"
            hidden={isReadOnly}
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                rows: rows.map((x) => ({ ...x, isselected: !isheaderchecked })),
              });
            }}
            data-tlabel={`${
              isheaderchecked ? "Selected" : "Unselect"
            } all payroll`}
            type="checkbox"
            checked={isheaderchecked ? true : false}
          />
        ),
        id: "isselected",
        accessorKey: "isselected",
        show: !isReadOnly,
        cell: (props) => (
          <div>
            <input
              hidden={isReadOnly}
              onClick={(e) => {
                e.stopPropagation();
                const newvalue = !props.getValue();
                this.setState({
                  rows: rows.map((d) =>
                    d.id === get(props, "row.original.id", "")
                      ? { ...d, isselected: newvalue }
                      : d
                  ),
                });
              }}
              data-tlabel={`${
                props.getValue() ? "Selected" : "Unselect"
              } payroll ${get(props, "row.original.id")}`}
              className="md-checkbox"
              type="checkbox"
              checked={props.getValue() ? true : false}
            />
          </div>
        ),
        size: 50,
        dragable: false,
        resizable: false,
        disableClick: true,
      },
      {
        header: () => (
          <span
            className="ms-3 cursor-pointer"
            onClick={() => this.setAPISort("due_date")}
          >
            {getLocaleString("common.date", "Date")}
            <span className="sort-icons">
              <i data-tlabel="sort list" className="fas fa-caret-up"></i>
              <i data-tlabel="sort list" className="fas fa-caret-down"></i>
            </span>
          </span>
        ),
        id: "due_date",
        accessorKey: "due_date",
        columnName: "Date",
        show: true,
        size: 100,
        cell: (data) => <span>{getFormattedDate(data.getValue())}</span>,
      },
      {
        header: () => (
          <span
            className="cursor-pointer"
            onClick={() => this.setAPISort("total")}
          >
            {getLocaleString("common.amount", "Amount")}
            <span className="sort-icons">
              <i data-tlabel="sort list" className="fas fa-caret-up"></i>
              <i data-tlabel="sort list" className="fas fa-caret-down"></i>
            </span>
          </span>
        ),
        id: "total",
        accessorKey: "total",
        size: 100,
        show: true,
        className: "text-end pe-2",
        headerClassName: "text-end",
        columnName: "Amount",
        cell: (data) => (
          <span>
            {showDecimal
              ? convertAmountWithDecimal(data.getValue())
              : convertAmount(data.getValue())}
          </span>
        ),
      },
      {
        header: () => (
          <span
            className="cursor-pointer"
            onClick={() => this.setAPISort("entity")}
          >
            {getLocaleString("common.entity", "Entity")}
            <span className="sort-icons">
              <i data-tlabel="sort list" className="fas fa-caret-up"></i>
              <i data-tlabel="sort list" className="fas fa-caret-down"></i>
            </span>
          </span>
        ),
        id: "entity",
        columnName: "Entity",
        accessorKey: "entity",
        show: true,
        cell: (data) => {
          const cf = get(data, "row.original.corporate_entity.name", "")
            ? get(data, "row.original.corporate_entity.name", "")
            : get(data, "row.original.entity", "");
          return <span>{cf}</span>;
        },
      },
      {
        header: () => (
          <span
            className="cursor-pointer"
            onClick={() => this.setAPISort("client_supplier_rel_id")}
          >
            {getLocaleString("common.vendor", "Vendor")}{" "}
            <span className="sort-icons">
              <i data-tlabel="sort list" className="fas fa-caret-up"></i>
              <i data-tlabel="sort list" className="fas fa-caret-down"></i>
            </span>
          </span>
        ),
        columnName: "Vendor",
        id: "client_supplier_rel_id",
        show: true,
        accessorKey: "client_supplier_rel.associated_supplier.name",
        cell: (data) => <span>{data.getValue() || "-"}</span>,
      },
      {
        header: () => (
          <span>{getLocaleString("common.invoices", "Invoices")}</span>
        ),
        accessorKey: "client_supplier_rel",
        size: 200,
        columnName: "Invoices",
        id: "invoices",
        show: !isReadOnly,
        disableClick: true,
        cell: (data) => {
          const selectedVendor = data.getValue();
          const attachedInvoices = get(
            data,
            "row.original.attached_invoices",
            []
          ).map((d) => ({
            label: d.invoice.invoice_number,
            value: d.invoice_id,
          }));
          return (
            <div onClick={(e) => e.stopPropagation()}>
              <AssociateInvoiceList
                selectedVendor={selectedVendor}
                invoiceList={attachedInvoices}
                setInvoiceList={(list) => {
                  if (!isEqual(list, attachedInvoices)) {
                    this.updatePayroll(get(data, "row.original.id", ""), {
                      invoice_list: list.map((d) => d.value),
                      client_supplier_rel_id: selectedVendor.id,
                    });
                  }
                }}
              />
            </div>
          );
        },
      },
      {
        header: () => (
          <span
            className="cursor-pointer"
            onClick={() => this.setAPISort("bank_account_id")}
          >
            {getLocaleString("common.bank_account", "Bank Account")}
            <span className="sort-icons">
              <i data-tlabel="sort list" className="fas fa-caret-up"></i>
              <i data-tlabel="sort list" className="fas fa-caret-down"></i>
            </span>
          </span>
        ),
        id: "bank_account_id",
        show: true,
        columnName: "Bank Account",
        accessorKey: "bank_account_id",
        cell: (data) => {
          const selectedBank = allbankaccounts.find(
            (x) => x.id === data.getValue()
          );
          const cf = get(selectedBank, "name", "-");
          return <span>{cf}</span>;
        },
      },
      {
        header: () => (
          <span
            className="cursor-pointer"
            onClick={() => this.setAPISort("notes")}
          >
            {getLocaleString("common.note", "Note")}
            <span className="sort-icons">
              <i data-tlabel="sort list" className="fas fa-caret-up"></i>
              <i data-tlabel="sort list" className="fas fa-caret-down"></i>
            </span>
          </span>
        ),
        id: "notes",
        show: true,
        columnName: "Note",
        accessorKey: "notes",
        cell: (data) => <span className="note-expand">{data.getValue()}</span>,
      },
      {
        header: () => <span />,
        accessorKey: "id",
        className: "overflow-unset",
        show: !isReadOnly,
        columnName: "",
        id: "id",
        staticColumn: true,
        dragable: false,
        disableClick: true,
        resizable: false,
        cell: (data) => (
          <span>
            <Button
              title={getLocaleString("payroll.clone", "Clone")}
              data-testid="clone"
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                this.props.history.push(`payroll/${data.getValue()}/clone`);
              }}
            >
              <i
                className="fa fa-clone color-blue"
                data-tlabel="Payroll clone"
              />
            </Button>{" "}
            |
            <Button
              title={getLocaleString("common.delete", "Delete")}
              data-testid="Delete"
              variant="icon"
              onClick={(e) => {
                e.stopPropagation();
                setconfirmationboxData({
                  variant: "warning",
                  msg: getLocaleString(
                    "common.are_you_sure_you_want_to_delete_record",
                    "Are you sure you want to delete this record?"
                  ),
                  onSave: () => {
                    resetconfirmationboxData();
                    this.onDelete(data.getValue());
                  },
                });
              }}
            >
              <i
                className="fa fa-trash-o color-blue"
                data-tlabel="Payroll Delete"
              />
            </Button>
          </span>
        ),
      },
    ];
    const total = payrollGraphData
      .map((x) => x.value)
      .reduce((a, b) => a + b, 0);
    const corporate_entity_idObj = payrollGraphData.find(
      (x) => x.corporate_entity_id === corporate_entity_id
    );

    return (
      <div className="card-body">
        <div className="row">
          <div className="titletranshist px-3 t-18 float-start">
            {this.props.location.hash === "#fromreports" && (
              <span
                onClick={() => window.history.back()}
                title={getLocaleString("common.back", "Back")}
              >
                <i
                  className="fa fa-arrow-left"
                  data-tlabel="Payroll list back button"
                  style={{
                    color: "#ddd",
                    marginRight: "15px",
                    ariaHidden: true,
                    cursor: "pointer",
                  }}
                />
              </span>
            )}
            {getLocaleString("page_names.payroll", "Payroll")}
          </div>
        </div>

        {isFetchingOriginal ? (
          <CustomSpinner isfullscreen />
        ) : (
          <div>
            <div className="colorwireframe">
              <div className="row">
                {!chartdatareceived ? (
                  <div className="w-100" style={{ height: 300 }}>
                    <CustomSpinner />
                  </div>
                ) : total ? (
                  <div className="col-md-12 d-flex justify-content-center">
                    <div>
                      {this.renderDonutChart(
                        payrollGraphData,
                        corporate_entity_idObj
                      )}
                    </div>
                    <div
                      className="hide-scroll"
                      style={{
                        height: 300,
                        marginLeft: 50,
                        overflowY: "auto",
                      }}
                    >
                      <div className="mt-4">
                        {payrollGraphData &&
                          payrollGraphData.map((entity, i) => (
                            <div
                              className="col-md-12 p-2 cursor-pointer"
                              onClick={() => {
                                this.setState(
                                  {
                                    corporate_entity_id:
                                      entity.corporate_entity_id,
                                    currPage: 1,
                                  },
                                  this.filterData
                                );
                              }}
                            >
                              <span
                                className={`ms-2 me-2 donutchart-legend-dot`}
                                style={{
                                  backgroundColor: `${get(
                                    entity,
                                    "line-color",
                                    COLORS[i % 8]
                                  )}`,
                                }}
                              />
                              <span>
                                {get(entity, "name", "-")}&nbsp;(
                                {getFAWC(entity.value)})
                              </span>
                            </div>
                          ))}
                      </div>
                    </div>
                  </div>
                ) : (
                  <PieChartPlaceholder />
                )}
              </div>
            </div>

            <div className="row mb-4 mt-2">
              <div className="col-md-6">
                <div className="dropdown float-start">
                  <Dropdown
                    showDropdown={showFilter}
                    buttonProps={{
                      as: "span",
                      role: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                    }}
                    btnClassName="dropdown-toggle cursor-pointer"
                    setShowDropdown={this.setshowFilter}
                    buttonText={getLocaleString("common.filter", "Filter")}
                    dropdownWrapperClassName="filterdroplist p-2 pe-4 width-500px"
                    dropdownWrapperProps={{
                      id: "outerside",
                    }}
                    filterButton
                  >
                    <div className="row">
                      <div className="col-md-6">
                        <div className="mb-3">
                          <label className="">
                            {getLocaleString(
                              "common.entity_name",
                              "Entity name"
                            )}
                          </label>
                          <select
                            style={{ width: 200 }}
                            className="form-control width-200px"
                            onChange={(e) => {
                              this.setState({
                                corporate_entity_id: e.target.value,
                              });
                            }}
                            value={corporate_entity_id}
                            placeholder="select"
                          >
                            <option value={""}>
                              {getLocaleString(
                                "common.all_entities",
                                "All entities"
                              )}
                            </option>
                            {!isEntitySelected ? (
                              <option value="0">
                                {getLocaleString("common.none", "None")}
                              </option>
                            ) : null}
                            {allentities &&
                              allentities.map((acc) => (
                                <option value={acc.id}>{acc.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            {getLocaleString("common.date", "Date")}
                          </label>
                          <div className="t-12 overflow-hidden">
                            <div className="float-start">
                              <DateRangePicker
                                startDate={moment(startDate)}
                                endDate={moment(endDate)}
                                getSelectedDates={this.setDates}
                                labelFormat="MMM DD, YYYY"
                                parentEl="#outerside"
                              />
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-3 form-group mb-3">
                          <label className="form-label">
                            {getLocaleString("common.scenario", "Scenario")}
                          </label>
                          <select
                            onChange={(e) =>
                              this.changeScenario(e.target.value)
                            }
                            className="form-control width-200px"
                            value={currentScenario || "default"}
                          >
                            <option value="default">
                              {getLocaleString("common.default", "Default")}
                            </option>
                            {scenarioList.map((d) => (
                              <option value={d.id}>{d.name}</option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-6">
                        <div className="form-group mb-3">
                          <label className="form-label">
                            {getLocaleString(
                              "payroll.payroll_category",
                              "Payroll Category"
                            )}
                          </label>
                          <select
                            onChange={(e) =>
                              this.setState({
                                payroll_category: e.target.value,
                              })
                            }
                            className="form-control width-200px"
                            value={payroll_category}
                          >
                            <option value="">
                              {getLocaleString("common.select", "Select")}
                            </option>
                            {[
                              "bonus",
                              "tax",
                              "benefits",
                              "salary",
                              "commission",
                              "misc",
                            ].map((d) => (
                              <option value={d}>
                                {getLocaleString(`payroll.${d}`, d)}
                              </option>
                            ))}
                          </select>
                        </div>
                      </div>
                      <div className="col-md-12">
                        <div className="form-group mb-3">
                          <span
                            className="cursor-pointer d-inline-block mt-2 me-2"
                            onClick={(e) => {
                              this.setState({
                                exclude_invoices_attached_payroll:
                                  +e.target.checked,
                              });
                            }}
                          >
                            <input
                              type="checkbox"
                              className="me-2 d-inline-block align-middle md-checkbox"
                              name="Exclude Invoice attached Payrolls"
                              checked={exclude_invoices_attached_payroll}
                            />
                          </span>
                          <label className="form-label">
                            {getLocaleString(
                              "payroll.exclude_invoice_attached_payroll",
                              "Exclude Invoice attached Payrolls"
                            )}
                          </label>
                        </div>
                      </div>
                      <div className="col-md-6" />
                      <div className="col-md-6">
                        <div className="setbtn">
                          <Button
                            type="button"
                            variant="light"
                            className="resetbtn"
                            onClick={this.onReset}
                          >
                            {getLocaleString("common.reset", "Reset")}
                          </Button>
                          <Button
                            type="button"
                            className="applybtn"
                            onClick={() =>
                              this.setState({ currPage: 1 }, this.filterData)
                            }
                          >
                            {getLocaleString("common.submit", "Submit")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  </Dropdown>
                </div>

                <Button
                  hidden={isReadOnly}
                  onClick={this.handleAddClick}
                  className="ms-3"
                  id="hidetable"
                  data-testid="Add New"
                  data-toggle="modal"
                >
                  {getLocaleString("payroll.add_new", "Add New")}
                </Button>
              </div>
              <div className="col-md-6 text-end">
                <div className="dropdown">
                  {totalRecords ? (
                    <span className="m-2">
                      {getLocaleString("common.total", "Total")}: {totalRecords}
                    </span>
                  ) : null}
                  <CSVLink
                    data={ExportCSV(
                      exportedData,
                      allbankaccounts,
                      allentities,
                      columnOrder
                    )}
                    ref="csv"
                    filename={`payroll_${moment().format(
                      "MMMM Do YYYY-h:mm:ss-a"
                    )}.csv`}
                    style={{ display: "none" }}
                  />
                  <Dropdown
                    showDropdown={showexport}
                    buttonProps={{
                      as: "span",
                      role: "button",
                      "data-toggle": "dropdown",
                      "aria-haspopup": "true",
                      "aria-expanded": "false",
                      "data-cy": "export",
                      endIcon: exporting && (
                        <i className="fa fa-spinner fa-spin" />
                      ),
                    }}
                    setShowDropdown={this.setshowExport}
                    buttonText={getLocaleString("common.exportTitle", "Export")}
                    dropdownWrapperClassName="dropdown-menu-end right-0 payexpdroplist right-0"
                    dropdownWrapperProps={{
                      id: "payment-export",
                    }}
                  >
                    <span
                      className="t-12 d-block text-end me-2 mb-1 cursor-pointer"
                      onClick={() => this.exportAllData(true)}
                    >
                      {getLocaleString("common.exportCSV", "Export CSV")}
                    </span>
                    <span
                      className="t-12 d-block text-end me-2 cursor-pointer"
                      onClick={this.exportAllData}
                    >
                      {getLocaleString("common.exportExcel", "Export Excel")}
                    </span>
                  </Dropdown>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                {isrowselected ? (
                  <div className="pt-2 pb-2 ps-1">
                    <span className="float-start mt-2" style={{ width: 54 }}>
                      <input
                        className="md-checkbox"
                        style={{ marginLeft: 16, marginTop: 1 }}
                        type="checkbox"
                        defaultChecked={
                          get(rows, "[0]", "") &&
                          !rows.find((x) => !x.isselected)
                        }
                        data-tlabel={`${
                          isheaderchecked ? "Selected" : "Unselect"
                        } all payroll`}
                        onChange={(e) =>
                          this.setState({
                            rows: rows.map((d) => ({
                              ...d,
                              isselected: e.target.checked,
                            })),
                          })
                        }
                      />
                    </span>
                    <span className="mt-2">
                      {/* <span
                        onClick={() => {
                          const selectedPayrolls = rows.filter(
                            (x) => x.isselected
                          );
                          if (selectedPayrolls.length > 1) {
                            this.props.changeSelection(
                              {
                                id: selectedPayrolls[0].id,
                                selectedPayrolls: rows.filter(
                                  (x) => x.isselected
                                ),
                              },
                              true
                            );
                          } else {
                            this.props.changeSelection(selectedPayrolls[0]);
                          }
                        }}
                        className="multiActivityshow btn btn-primary btn-sm cursor-pointer me-2 width-94px"
                      >
                        <i className="fa fa-pencil me-1" aria-hidden="true" />
                        Update
                      </span> */}
                      <Button
                        as="span"
                        size="sm"
                        variant="danger"
                        data-testid="Delete"
                        onClick={(e) => {
                          e.stopPropagation();
                          setconfirmationboxData({
                            variant: "warning",
                            msg: getLocaleString(
                              "common.are_you_sure_you_want_to_delete_record",
                              "Are you sure you want to delete this record?"
                            ),
                            onSave: () => {
                              resetconfirmationboxData();
                              this.onBulkDelete();
                            },
                          });
                        }}
                        startIcon={
                          <i className="fa fa-trash" aria-hidden="true" />
                        }
                        className="multiActivityshow cursor-pointer me-2 width-94px"
                      >
                        {getLocaleString("common.delete", "Delete")}
                      </Button>
                    </span>
                  </div>
                ) : null}
                <Table
                  data={rows}
                  columns={columns}
                  sorting={
                    api_sort_by
                      ? [
                          {
                            id: api_sort_by,
                            desc: api_sort_order === "desc",
                          },
                        ]
                      : []
                  }
                  onRowClick={(row) =>
                    this.props.history.push(`payroll/${row.id}`)
                  }
                  currPage={currPage}
                  nextPage={rows.length >= 20 ? currPage + 1 : null}
                  prevPage={currPage !== 1 ? currPage - 1 : null}
                  isFetching={isFetching}
                  setSorting={this.setAPISort}
                  hideHeader={isheaderchecked}
                  setNextPage={this.setNextPage}
                  setPrevPage={this.setPrevPage}
                  defaultColumnOrder={columnOrder}
                  onDraggedColumnChange={(cols = {}) => {
                    setuxvalue("PAYROLL_LIST_COLUMN_ORDER", cols);
                    this.setState({
                      columnOrder: cols,
                    });
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  generalSettings: get(state, "configurableSettings.generalSettings", {}),
});

export default connect(mapStateToProps, null)(PayrollList);
