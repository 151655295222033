import { instanceWithRetry } from "actions/axiosInstance";

export const getQuery = async (payload) => {
  try {
    const updatedPayload = {
      messages: [payload],
      session_state: {},
      context: {
        overrides: {
          retrieval_mode: "text",
          semantic_ranker: true,
          semantic_captions: false,
          top: 3,
          minimum_search_score: 0.0,
          minimum_reranker_score: 0.0,
          temperature: 0.3,
          prompt_template: "Your custom system prompt here",
        },
        auth_claims: {},
      },
    };
    const url = "/chat_agent/chat";
    const result = await instanceWithRetry.post(url, updatedPayload);
    if (result.data) {
      return result.data;
    }
  } catch (error) {
    return error;
  }
};
