import React from "react";
import { emailRegEx } from "constants/regex";
import cx from "classnames";
import Chip from "@mui/material/Chip";
import { setemailData } from "./EmailCreator";
import { onEmailSave } from "containers/customers/details/actions";
import { getLocaleString } from "utils/localization/locale";

export default class EmailInput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      items: props.email || [],
      value: "",
      error: null,
      copy_class: "fa-clone",
      showpencil: !this.props.showCopy,
      editmode: false,
      isEdited: "",
    };
  }

  onBlur = () => {
    const value = this.state.value.trim();

    if (value && this.isValid(value)) {
      this.setState(
        {
          items: [...this.state.items, this.state.value],
          value: "",
        },
        () => {
          this.props.getEmailId(
            this.props.label,
            this.state.items.toString(),
            this.state.isEdited
          );
        }
      );
    }
    if (this.props.isAllEmailValid) {
      if (!value) {
        this.props.isAllEmailValid(false);
      }
    }
  };

  handleKeyDown = (evt) => {
    const { showCopy, customerSidebarEmail } = this.props;

    if (["Enter", "Tab", ","].includes(evt.key)) {
      evt.preventDefault();
      this.onBlur();
      if (customerSidebarEmail && this.state.editmode) {
        this.setState({
          editmode: true,
          showpencil: !showCopy,
        });
      }
    }
  };

  handleChange = (evt) => {
    this.setState({
      value: evt.target.value,
      error: null,
    });
  };

  handleDelete = (item) => {
    this.setState(
      {
        items: this.state.items.filter((i) => i !== item),
      },
      () => this.props.getEmailId(this.props.label, this.state.items.toString())
    );
  };

  handlePaste = (evt) => {
    evt.preventDefault();

    const paste = evt.clipboardData.getData("text");
    let emailArr = paste ? paste.split(",") : [];
    let validEmails = [];
    let invalidEmails = [];

    emailArr.forEach((email) => {
      let emails = email.trim().match(emailRegEx);
      if (emails) {
        const toBeAdded = emails.filter((email) => this.isValid(email));
        validEmails.push(...toBeAdded);
      } else {
        invalidEmails.push(email);
      }
    });

    if (invalidEmails.length) {
      this.setState({
        editmode: true,
        value: invalidEmails.toString(),
        items: [...this.state.items, ...validEmails],
      });
    } else if (validEmails.length) {
      this.setState(
        {
          items: [...this.state.items, ...validEmails],
        },
        () =>
          this.props.getEmailId(this.props.label, this.state.items.toString())
      );
    }
  };

  isValid = (email) => {
    let error = null;

    if (this.isInList(email)) {
      error = `${email} has already been added.`;
    }

    if (!this.isEmail(email)) {
      error = `${email} is not a valid email address.`;
    }

    if (error) {
      this.setState({ error });
      if (this.props.isAllEmailValid) {
        this.props.isAllEmailValid(true, error);
      }
      return false;
    }
    if (this.props.isAllEmailValid) {
      this.props.isAllEmailValid(false);
    }
    return true;
  };

  isInList = (email) => this.state.items.includes(email);

  isEmail = (email) => emailRegEx.test(email);

  render() {
    const { items, error, value, copy_class, showpencil, editmode, isEdited } =
      this.state;
    const {
      id = "multiemail-input",
      email,
      label = "",
      readOnly = false,
      showCopy = false,
      customerSidebarEmail = false,
    } = this.props;
    const StyledChip = Chip;

    return (
      <div
        onMouseEnter={() => {
          !readOnly &&
            customerSidebarEmail &&
            !editmode &&
            items.length &&
            this.setState({ showpencil: true });
        }}
        onMouseLeave={() => {
          !readOnly &&
            customerSidebarEmail &&
            !editmode &&
            items.length &&
            this.setState({ showpencil: false });
        }}
      >
        {items.map((item, i) => (
          <span
            className="tag-item d-inline-block me-1 mb-1"
            key={`${item}_${i}_${label}`}
            title={item}
            onClick={() => {
              if (!readOnly && customerSidebarEmail && showCopy && !editmode) {
                setemailData({
                  // to: [...new Set(items)].join(","),
                  to: item,
                  show: true,
                  onSave: onEmailSave,
                  hidePayBtn: true,
                  hideStatementBtn: true,
                  cc: null,
                });
              } else if (customerSidebarEmail && showCopy && editmode) {
                this.setState({
                  value: item,
                  isEdited: item,
                  items: items
                    .filter((i) => i !== item)
                    .concat(isEdited ? [isEdited] : []),
                });
              }
            }}
          >
            <StyledChip
              size="small"
              title={item}
              label={item}
              disabled={readOnly}
              onDelete={(e) => {
                e.stopPropagation();
                this.handleDelete(item);
              }}
            />
          </span>
        ))}
        <span
          className={`${
            customerSidebarEmail ? "d-flex align-items-center" : ""
          }`}
        >
          {(customerSidebarEmail ? editmode : true) ? (
            <input
              id={id}
              disabled={readOnly}
              className={`email-input ${
                customerSidebarEmail
                  ? `p-1 ${cx("", { error: !!error })}`
                  : cx("multiemail-input", { error: !!error })
              }`}
              value={value}
              data-cy="Enter email address"
              placeholder={getLocaleString(
                "common.enter_email_address",
                "Enter email address"
              )}
              onBlur={this.onBlur}
              onKeyDown={this.handleKeyDown}
              onChange={this.handleChange}
              onPaste={this.handlePaste}
              autoComplete="on"
            />
          ) : (
            ""
          )}
          {customerSidebarEmail && editmode && !readOnly ? (
            <i
              onClick={(e) => {
                e.preventDefault();
                e.stopPropagation();
                this.setState(
                  {
                    editmode: false,
                    showpencil: !items.length,
                    value: "",
                    isEdited: "",
                    items: this.state.items.concat(isEdited ? [isEdited] : []),
                  },
                  () => {
                    if (email.length !== items.length) {
                      this.props.getEmailId(label, items.toString());
                    }
                  }
                );
              }}
              title="Close editor"
              id="calender-fa-cross"
              data-tlabel={`Remove email`}
              className="fas fa-times float-end cursor-pointer"
              style={{ position: "absolute", right: 10 }}
            />
          ) : (
            ""
          )}
        </span>

        <span
          onClick={(e) => {
            e.stopPropagation();
            this.setState({
              showpencil: false,
              editmode: true,
            });
          }}
          className={`${
            customerSidebarEmail && showpencil && !editmode && !readOnly
              ? "visible"
              : "invisible"
          }`}
        >
          <i data-tlabel="Edit Email" className="fa fa-pencil-alt" />
        </span>

        {customerSidebarEmail &&
        showCopy &&
        showpencil &&
        !editmode &&
        !readOnly ? (
          <i
            className={`fa ${copy_class} cursor-pointer copyIcon ms-2`}
            aria-hidden="true"
            title="Copy email"
            onClick={(e) => {
              e.stopPropagation();
              this.setState({
                copy_class: "fa-check",
              });
              navigator.clipboard.writeText(items);
              setTimeout(() => {
                this.setState({
                  copy_class: "fa-clone",
                });
              }, 1000);
            }}
          ></i>
        ) : (
          ""
        )}
      </div>
    );
  }
}
