import { ReducersTypes } from "constants/ReducersTypes";

export const initialData = {
  connection: "",
  device: "",
  counter: 0,
  muted: false,
  identity: "",
  call_status: "Offline",
  showDialog: false,
  isDeviceReady: false,
  recentCalls: [],
  callInterval: "",
  flipCard: "showOnCall",
  extensionCall: "",
};

const phoneCall = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SET_CALL_DATA:
      return {
        ...state,
        ...payload,
      };
    case ReducersTypes.RESET_CALL_DATA:
      return {
        ...initialData,
      };
    default:
      return state;
  }
};

export default phoneCall;
