import { instanceWithRetry } from "actions/axiosInstance";
import { logout } from "handlers/setters";
import TokenManager from "utils/TokenManager";
import { getParameterByName } from "utils";
import axios from "axios";
import Toaster from "components/Toaster";
import fe_config from "constants/Configs";
import get from "lodash/get";
import { setUserData } from "actions/user";

export default function getNewToken(headers, callback) {
  const callTokenAPI = headers
    ? instanceWithRetry.get("token", headers)
    : instanceWithRetry.get("token");
  callTokenAPI
    .then((response) => {
      if (!TokenManager.getClientId()) {
        console.log("Logout reason: No client id");
        logout();
      }
      TokenManager.set(response.data.token, response.data["expires at"]);
      TokenManager.setUserName(response.data.username, response.data["id"]);
      if (callback) {
        callback(response);
      }
    })
    .catch(logout);
}

export function loginAndRedirectByRoute(props, callback) {
  const init_token = getParameterByName("token");
  const isExistingUser = getParameterByName("exists");
  const redirectClientContext = getParameterByName("redirect");
  const hash = get(props, "location.hash", "")
    ? get(props, "location.hash", "").split("?")[0]
    : "";
  const currentPath = `${get(props, "location.pathname", "")}${hash}`;
  function setAndRedirect() {
    if (currentPath) {
      if (currentPath.includes("customer/")) {
        sessionStorage.setItem(
          "emailRedirectionPath",
          currentPath.replace("customer/", "customers/")
        );
      } else {
        sessionStorage.setItem("emailRedirectionPath", currentPath);
      }
    }
    if (redirectClientContext !== "canceled") {
      sessionStorage.setItem("client_id_context", redirectClientContext);
    }
    props.history.push("/login");
  }

  if (init_token && !isExistingUser) {
    axios
      .get(`${fe_config.API_URL}token`, {
        auth: {
          username: init_token,
          password: Math.random().toString(20),
        },
        headers: {
          client_id_context: redirectClientContext,
        },
      })
      .then((response) => {
        const role = response.data.role;
        TokenManager.set(response.data.token, response.data["expires at"]);
        TokenManager.setUserName(response.data.username, response.data["id"]);
        setTimeout(() => {
          const APIs = [
            `/client/user?id=${TokenManager.getUserId()}`,
            "client/accessible",
            "user/entities",
            "client",
            "user/settings",
          ].map((x) => instanceWithRetry.get(x));
          Promise.all(APIs).then((responses) => {
            //corporate_entity_id_context
            const corporate_entity_id_context =
              response.data.corporate_entity_id;

            // userdata
            const datatemp = {
              ...response.data,
              ...get(responses[0], "data.result[0]", {}),
              roles: ["customer_invoice_restricted", "customer"].includes(
                response.data.role
              )
                ? ["customer"]
                : response.data.roles,
              corporate_entity_id_context,
              client_customer_rel_id_context:
                response.data.client_customer_rel_id,
              client_supplier_rel_id_context:
                response.data.client_supplier_rel_id,
              client_id: response.data.client_id,
              client_address: responses[3].data.result.address,
              client_name: responses[3].data.result.name,
              client_logo: responses[3].data.result.logo_url,
            };

            //accessible
            setUserData({
              datatemp,
              clients: responses[1].data.result,
              corporateEntities: responses[2].data.result,
            });

            const base_currency = get(
              response,
              "data.corp_entity.currency",
              null
            )
              ? get(response, "data.corp_entity.currency", "USD")
              : get(response, "data.client.base_currency", "USD");
            const date_format = get(
              response,
              "data.corp_entity.date_format",
              null
            )
              ? get(response, "data.corp_entity.date_format", "MM/DD/YYYY")
              : get(response, "data.client.date_format", "MM/DD/YYYY");
            localStorage.setItem("base_currency", base_currency);
            localStorage.setItem(
              "client_base_currency",
              get(response, "data.client.base_currency", "USD")
            );
            localStorage.setItem("date_format", date_format);
            localStorage.setItem(
              "locale",
              get(responses[0], "data.result[0].language", "en")
            );
            localStorage.setItem(
              "corp_entity_selected",
              !!get(response, "data.corp_entity", "")
            );

            TokenManager.setUserData(datatemp);
            callback && callback();
            //navigate
            const navigate = () => {
              const redirectPath = currentPath.includes("customers")
                ? `/customers/rel/${response.data.client_customer_rel_id}`
                : currentPath;
              window.localStorage.setItem("loggedin", get(datatemp, "id", ""));
              sessionStorage.setItem("emailRedirectionPath", redirectPath);
              props.history.replace(redirectPath);
              window.location.reload();
              return;
            };

            // entityadmin fallback
            if (
              role === "entityadmin" &&
              !get(response, "data.corporate_entity_id", "")
            ) {
              const headers = {
                client_id_context: get(
                  responses[0],
                  "data.result[0].client_id",
                  ""
                ),
                corporate_entity_id_context,
              };
              getNewToken({ headers }, navigate);
            } else {
              navigate();
            }
          });
        }, 300);
      })
      .catch(() => {
        Toaster("Authentication failed", "error");
        if (currentPath.includes("customer/")) {
          sessionStorage.setItem(
            "emailRedirectionPath",
            currentPath.replace("customer/", "customers/")
          );
        } else {
          sessionStorage.setItem("emailRedirectionPath", currentPath);
        }
        props.history.push("/login");
      });
  } else {
    setAndRedirect();
  }
}
