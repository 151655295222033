import TokenManager from "utils/TokenManager";
import get from "lodash/get";

// Initializing empty variable for interval
let clientInterval;

let intervalFunc;

// Interval time that can be used for declaring time for the intervals
let ONE_SECOND = 1000;

// Variable for storing time elapsed for client interval
let clientTotalTime;

// User tracking function
export const userTracking = () => {
  const loggedInUserData = JSON.parse(
    localStorage.getItem("loggedInUserData") || "{}"
  );
  const clientID = get(loggedInUserData, "client_id", "");
  const clientIdFlag = clientID ? `${clientID}-clientTotalTime` : "";
  if (clientIdFlag && localStorage.getItem(clientIdFlag)) {
    clientTotalTime = isNaN(parseInt(localStorage.getItem(clientIdFlag)))
      ? 0
      : parseInt(localStorage.getItem(clientIdFlag));
  } else {
    clientTotalTime = 0;
  }

  intervalFunc = () => {
    if (
      !document.hidden &&
      localStorage.getItem("loggedInUserData") &&
      clientIdFlag
    ) {
      clientTotalTime += ONE_SECOND;
      localStorage.setItem(clientIdFlag, clientTotalTime);
      localStorage.setItem(
        `clientId-${clientID}-ActiveTime`,
        formatTime(clientTotalTime)
      );
      localStorage.setItem(
        "user-activity",
        JSON.stringify({
          client_name: get(loggedInUserData, "client_name", ""),
          corp_entity_name:
            TokenManager && TokenManager.getCorporateEntityName()
              ? TokenManager.getCorporateEntityName()
              : "",
          time_since_last_event: !isNaN(
            parseInt(localStorage.getItem(clientIdFlag))
          )
            ? parseInt(localStorage.getItem(clientIdFlag)) / 1000
            : "",
          user_email: get(loggedInUserData, "email", ""),
        })
      );
    }
  };

  // timer for Clients
  clientInterval = setInterval(() => intervalFunc(), ONE_SECOND);
};

// timer formatter function that provides data in form of HOUR : MINUTES : SECONDS
const formatTime = (ms) => {
  let seconds = Math.floor(ms / 1000);
  seconds = seconds < 10 ? "0" + seconds : seconds;

  let minutes = Math.floor(ms / (1000 * 60));
  minutes = minutes < 10 ? "0" + minutes : minutes;

  let hours = Math.floor(ms / (1000 * 60 * 60));
  hours = hours < 10 ? "0" + hours : hours;

  return hours + ":" + minutes + ":" + seconds;
};

// Function to clear interval on changing client from header dropdown
export const clearClientInterval = () => {
  clearInterval(clientInterval);
  clientTotalTime = 0;
};
