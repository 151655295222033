import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";

export const actionTypes = {
  FETCH_WORKFLOW_STEPS: "FETCH_WORKFLOW_STEPS",
  FETCH_EMAIL_TEMPLATES: "FETCH_EMAIL_TEMPLATES",
  FETCH_WORKFLOW_LIST: "FETCH_WORKFLOW_LIST",
  FETCH_SCHEDULE_LIST: "FETCH_SCHEDULE_LIST",
};

const setData = (type, data, isFetching) => ({
  type,
  payload: {
    data,
  },
  isFetching,
});

export const fetchWorkflowSteps =
  (isMutate = false) =>
  (dispatch) => {
    const URL = "arworkflow/sequence?no_duplicates=1";
    dispatch(setData(actionTypes.FETCH_WORKFLOW_STEPS, [], true));
    return instanceWithRetry
      .get(isMutate ? `${URL}&mutate=1` : URL)
      .then((response) => {
        if (response && response.data) {
          dispatch(
            setData(
              actionTypes.FETCH_WORKFLOW_STEPS,
              response.data.result,
              false
            )
          );
        }
      })
      .catch((e) => handleAPIError(e));
  };

export const fetchEmailTemplateList =
  (isMutate = false) =>
  (dispatch) => {
    const URL = isMutate
      ? "arworkflow/template?mutate=1"
      : "arworkflow/template";
    dispatch(setData(actionTypes.FETCH_EMAIL_TEMPLATES, [], true));
    return instanceWithRetry
      .get(URL)
      .then((response) => {
        if (response && response.data) {
          dispatch(
            setData(
              actionTypes.FETCH_EMAIL_TEMPLATES,
              response?.data?.result,
              false
            )
          );
        }
      })
      .catch((e) => handleAPIError(e));
  };

export const fetchWorkflowList = () => (dispatch) => {
  const URL = "arworkflow";
  // dispatch(setData(actionTypes.FETCH_WORKFLOW_LIST, [], true));
  return instanceWithRetry
    .get(URL)
    .then((response) => {
      if (response && response.data) {
        dispatch(
          setData(actionTypes.FETCH_WORKFLOW_LIST, response.data.result, false)
        );
      }
    })
    .catch((e) => handleAPIError(e));
};
export const fetchScheduleList = () => (dispatch) => {
  const URL = "arworkflow/schedule";
  dispatch(setData(actionTypes.FETCH_SCHEDULE_LIST, [], true));
  return instanceWithRetry
    .get(URL)
    .then((response) => {
      if (response && response.data) {
        dispatch(
          setData(actionTypes.FETCH_SCHEDULE_LIST, response.data.result, false)
        );
      }
    })
    .catch((e) => handleAPIError(e));
};
