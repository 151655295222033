import React from "react";
import { CSVLink } from "react-csv";
import DateRangePicker from "components/DateRangePicker";
import Button from "components/Button";
import { ExportXLSX } from "utils/table-utils";
import moment from "moment";
import debounce from "lodash/debounce";
import uniqBy from "lodash/uniqBy";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import Autocomplete from "components/Autocomplete";
import { utcToLocal } from "utils/dates";
import Modal from "components/CustomModal";
import { getCustomerList } from "./actions";
import { setuxvalue, getuxvalue } from "handlers/ux";
import { convertAmountWithDecimal, replaceQuotes } from "utils";
import TokenManager from "utils/TokenManager";
import { getCurrency } from "country-currency-map";
import {
  enableEasyPayPG,
  enableForVoltyx,
  enableWorldPayPG,
  enableCustomerInvoiceRestrictedCreation,
} from "handlers/features";
import DropdownButton from "components/Button/Dropdown";
import { getLocaleString } from "utils/localization/locale";

function filterProperties(originalObj, visibleHeaders) {
  Object.keys(visibleHeaders).forEach((key) => {
    if (!visibleHeaders[key]) delete originalObj[key];
  });
  return originalObj;
}

function replaceCurrency(amount) {
  return convertAmountWithDecimal(amount).replace(
    getCurrency(TokenManager.getCurrencyCode()).symbolFormat.replace("{#}", ""),
    ""
  );
}

function ExportCSV(
  data,
  visibleColumns,
  isInCustomerPortal = false,
  exportAllColumns
) {
  const isRefundTransaction =
    data.length && data.some((d) => d.payment_type === "REFUND");
  const showFailureColumn =
    data.length &&
    data.some((d) =>
      ["FAILED", "REQUIRES_ACTION"].includes(get(d, "latest_status", ""))
    );

  let visibleHeaders = {
    [getLocaleString("common.customer", "Customer")]:
      visibleColumns[getLocaleString("common.customer", "Customer")],
    [getLocaleString("common.invoice", "Invoices")]:
      visibleColumns[getLocaleString("common.invoice", "Invoices")],
    [getLocaleString("payments.autopaid_invoice", "Autopaid Invoice")]:
      isInCustomerPortal && !isRefundTransaction,
    [getLocaleString("payments.payout_id", "Payout ID")]:
      visibleColumns[getLocaleString("payments.payout_id", "Payout ID")],
    [getLocaleString("payments.payout_date", "Payout Date")]:
      visibleColumns[getLocaleString("payments.payout_date", "Payout Date")],
    [getLocaleString("payments.payout_amount", "Payout Amount")]:
      visibleColumns[
        getLocaleString("payments.payout_amount", "Payout Amount")
      ],
    [getLocaleString("payments.refund", "Refund")]:
      data.length && !isRefundTransaction,
    [isRefundTransaction
      ? [getLocaleString("payments.refund_amount", "Refund Amount")]
      : [getLocaleString("common.amountPaid", "Amount Paid")]]:
      (!isInCustomerPortal ? true : isRefundTransaction) &&
      visibleColumns[
        getLocaleString("common.show_payment_details", "Show Payment Details")
      ],
    [getLocaleString("payments.fees_paid", "Fees Paid")]:
      !isInCustomerPortal &&
      !isRefundTransaction &&
      visibleColumns[
        getLocaleString("common.show_payment_details", "Show Payment Details")
      ],
    [isInCustomerPortal
      ? [getLocaleString("common.amountPaid", "Amount Paid")]
      : [getLocaleString("payments.total_paid", "Total Paid")]]:
      visibleColumns[
        getLocaleString("common.show_payment_details", "Show Payment Details")
      ] && !isRefundTransaction,
    [getLocaleString("payments.fees_deducted", "Fees Deducted")]:
      !isInCustomerPortal &&
      visibleColumns[
        getLocaleString("common.show_payment_details", "Show Payment Details")
      ] &&
      !isRefundTransaction,
    [getLocaleString("payments.net_amount", "Net Amount")]:
      !isInCustomerPortal &&
      visibleColumns[getLocaleString("payments.net_amount", "Net Amount")] &&
      !isRefundTransaction,
    [getLocaleString("common.paymentMethod", "Payment Method")]:
      visibleColumns[getLocaleString("common.paymentMethod", "Payment Method")],
    [getLocaleString("payments.initiated_date", "Initiated Date")]:
      visibleColumns[
        getLocaleString("payments.initiated_date", "Initiated Date")
      ],
    [getLocaleString("payments.last_updated_at", "Last Updated At")]:
      visibleColumns[
        getLocaleString("payments.last_updated_at", "Last Updated At")
      ],
    [getLocaleString("common.status", "Status")]:
      visibleColumns[getLocaleString("common.status", "Status")],
    [getLocaleString("common.message", "Message")]:
      !isInCustomerPortal &&
      data.length &&
      visibleColumns[getLocaleString("common.message", "Message")] &&
      showFailureColumn,
  };
  if (exportAllColumns) {
    visibleHeaders = mapValues(visibleHeaders, () => true);
  }
  if (!data.length) {
    return [
      filterProperties(
        {
          Customer: "",
          Invoices: "",
          "Autopaid Invoice": "",
          "Payout ID": "",
          "Payout Date": "",
          "Payout Amount": "",
          [isRefundTransaction ? "Refund Amount" : "Amount Paid"]: "",
          "Fees Paid": "",
          "Total Paid": "",
          "Fees Deducted": "",
          "Net Amount": "",
          "Payment Method": "",
          "Initiated Date": "",
          "Last Updated At": "",
          Status: "",
          Message: "",
        },
        visibleHeaders
      ),
    ];
  }
  const csvData = data.map((element) => {
    const len = get(element, "session_history", []).length - 1;
    const ele = {
      Customer: replaceQuotes(
        get(element, "client_customer_rel.associated_customer.name", "-")
      ),
      Invoices: get(element, "payment_session_ar_invoice", [])
        .map((d) => get(d, "invoice.invoice_number"))
        .join(", "),
      "Autopaid Invoice": get(element, "is_auto_paid", false) ? "Yes" : "No",
      "Payout ID": get(element, "payout.external_payout_id"),
      "Payout Date": utcToLocal(get(element, "payout.payout_date", "")),
      "Payout Amount": get(element, "payout.payout_amount"),
      [isRefundTransaction ? "Refund Amount" : "Amount Paid"]: replaceCurrency(
        get(element, "total_transaction_amount", 0) || 0
      ),
      "Fees Paid": replaceCurrency(
        get(element, "customer_total_transaction_fees_paid", 0) || 0
      ),
      [isInCustomerPortal ? "Amount Paid" : "Total Paid"]: replaceCurrency(
        get(element, "total_amount_paid", 0) || 0
      ),
      "Fees Deducted": replaceCurrency(
        get(element, "total_transaction_fees", 0)
      ),
      "Net Amount": replaceCurrency(get(element, "amount_received", 0)),
      "Payment Method": get(element, "payment_method", "-"),
      "Initiated Date": utcToLocal(get(element, "initiated_date", "")),
      "Last Updated At": utcToLocal(get(element, "latest_status_date", "")),
      Status: get(element, "latest_status", "-"),
      Message: ["FAILED", "REQUIRES_ACTION"].includes(
        get(element, "latest_status", "")
      )
        ? get(element, `session_history[${len}].message`, "-")
        : "-",
    };
    const obj = filterProperties(ele, visibleHeaders);
    return obj;
  });

  return csvData;
}

export const initialPaymentListFilters = {
  status: "",
  to_date: moment().format("MM/DD/YYYY"),
  sort_by: "latest_status_date",
  from_date: moment().subtract(30, "days").format("MM/DD/YYYY"),
  sort_order: "desc",
  customerSelect: null,
  interval: "",
  payment_type: enableEasyPayPG() ? "PAYMENT" : "",
};

export default class FilterAndExport extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      showFilter: false,
      showexport: false,
      interval: "",
      ...getuxvalue("ANALYTICS_PAYMENTS_FILTERS"),
      status: get(props, "paymentAnalytics.filters.status", ""),
      payment_type: enableEasyPayPG()
        ? get(
            getuxvalue("ANALYTICS_PAYMENTS_FILTERS"),
            "payment_type",
            "PAYMENT"
          )
        : "",
      isCustomerFetching: false,
      customerList: [],
      exporting: false,
      columnsCount: 0,
      transactionType: "",
      visibleColumnsShow: false,
      exportAllColumns: get(
        getuxvalue("EXPORT_ALL_COLUMNS"),
        "reportsPaymentsList",
        false
      ),
    };

    this.debounceMethod = debounce(this.searchCustomer.bind(this), 300);
  }

  componentDidMount() {
    const { visibleColumns } = this.props;
    const newCol = Object.keys(visibleColumns).filter(
      (key) => visibleColumns[key]
    );
    this.setState({
      columnsCount: Object.keys(newCol).length,
    });
  }

  setExportAllOption = (value) => {
    this.setState(
      {
        exportAllColumns: value,
      },
      setuxvalue("EXPORT_ALL_COLUMNS", {
        ...getuxvalue("EXPORT_ALL_COLUMNS"),
        reportsPaymentsList: value,
      })
    );
  };

  setDates = (from_date, to_date, interval) => {
    this.setState({
      from_date: from_date.format("MM/DD/YYYY"),
      to_date: to_date.format("MM/DD/YYYY"),
      interval,
    });
  };

  onReset = () => {
    const { client_customer_rel_id } = this.props;
    this.setState(
      {
        showFilter: false,
        showexport: false,
        isCustomerFetching: false,
        exporting: false,
        showFilterModal: false,
        ...initialPaymentListFilters,
      },
      () => {
        this.props.getPaymentsData({
          ...initialPaymentListFilters,
          client_customer_rel_id,
          is_customer_invoice_restricted_role:
            enableCustomerInvoiceRestrictedCreation() &&
            TokenManager.isCustomer()
              ? TokenManager.isCustomerInvoiceRestricted()
                ? "1"
                : "0"
              : "",
        });
        this.props.resetPaymentsListReducer();
      }
    );
  };

  setStatus = (status) => this.setState({ status });

  setTransactionType = (payment_type) => this.setState({ payment_type });

  setshowFilter = (showFilter) => this.setState({ showFilter });

  setshowExport = (showexport) => this.setState({ showexport });

  setShowFilterModal = (flag) =>
    this.setState({
      showFilterModal: flag,
    });

  searchCustomer = (text) => {
    this.setState({
      isCustomerFetching: true,
    });
    if (text) {
      getCustomerList(text, (customerList) => {
        this.setState({
          customerList,
          isCustomerFetching: false,
        });
      });
    } else {
      this.setState({
        customerList: [],
        isCustomerFetching: false,
      });
    }
  };

  getData = (is_export = false, cb) => {
    const { filters } = this.props.paymentAnalytics;
    const { client_customer_rel_id } = this.props;
    const {
      from_date,
      to_date,
      status,
      customerSelect,
      interval,
      payment_type,
    } = this.state;
    const params = {
      from_date,
      to_date,
    };

    if (status || filters.status) {
      params.status = status;
    }
    if (customerSelect || client_customer_rel_id) {
      params.client_customer_rel_id = client_customer_rel_id
        ? client_customer_rel_id
        : customerSelect.id;
      params.customerSelect = customerSelect;
    }
    if (is_export || filters.is_export) {
      params.is_export = is_export;
    }
    if (interval || filters.interval) {
      params.interval = interval;
    }
    if (enableEasyPayPG() && (payment_type || filters.payment_type)) {
      params.payment_type = payment_type || "PAYMENT";
    }

    this.props
      .getPaymentsData({
        ...filters,
        ...params,
        is_customer_invoice_restricted_role:
          enableCustomerInvoiceRestrictedCreation() && TokenManager.isCustomer()
            ? TokenManager.isCustomerInvoiceRestricted()
              ? "1"
              : "0"
            : "",
        page: 1,
      })
      .then(() => {
        this.setState({
          showFilter: false,
          exporting: false,
          showexport: false,
          showFilterModal: false,
        });
        cb && cb();
      });
  };

  exportAllData = (flag) => {
    this.setState(
      {
        exporting: true,
      },
      () => {
        this.getData(true, () => {
          if (window.Cypress) {
            return;
          }
          if (flag) {
            this.refs.csv.link.click();
          } else {
            ExportXLSX(
              ExportCSV(
                this.props.paymentAnalytics.listData,
                this.props.visibleColumns,
                this.props.isInCustomerPortal,
                this.state.exportAllColumns
              ),
              `Payments_${moment().format("MMMM Do YYYY-h:mm:ss-a")}.xlsx`
            );
          }
        });
      }
    );
  };

  render() {
    const {
      status,
      interval,
      exporting,
      showFilter,
      showexport,
      payment_type,
      customerList,
      columnsCount,
      customerSelect,
      showFilterModal,
      isCustomerFetching,
      visibleColumnsShow,
      exportAllColumns,
    } = this.state;

    const {
      hideCustomerFilter,
      isInCustomerPortal,
      paymentAnalytics: {
        listData,
        filters: { from_date, to_date },
      },
      onChange,
      visibleColumns,
    } = this.props;
    const isMobile = window.innerWidth <= 767;

    const showPaymentFailedDetail = listData.some((data) =>
      ["FAILED", "REQUIRES_ACTION"].includes(get(data, "latest_status", ""))
    );
    const showRefundButton = listData.some((data) =>
      enableEasyPayPG()
        ? get(data, "latest_status", "") === "SETTLED"
        : enableWorldPayPG()
          ? get(data, "latest_status", "") === "PAID"
          : false
    );
    const isRefundTransactions = payment_type === "REFUND";

    return (
      <div className="row mb-4 mt-4">
        <div className="col-md-6 d-flex col">
          <DropdownButton
            showDropdown={showFilter}
            buttonProps={{
              as: "span",
              role: "button",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
            }}
            btnClassName="dropdown-toggle cursor-pointer"
            setShowDropdown={(value) => {
              isMobile
                ? this.setShowFilterModal(value)
                : this.setshowFilter(value);
            }}
            buttonText={getLocaleString("common.filter", "Filter")}
            dropdownWrapperClassName="filterdroplist p-2 width-500px"
            dropdownWrapperProps={{
              id: "outerside",
            }}
            filterButton
          >
            <div className="row">
              <div className="col-md-6">
                <div className="form-group mb-0">
                  <label>
                    {getLocaleString(
                      "payments.initiated_date",
                      "Initiated Date"
                    )}
                  </label>
                  <div className="single-date-picker t-12 overflow-hidden">
                    <DateRangePicker
                      allToDate={true}
                      label={interval}
                      startDate={moment(from_date)}
                      endDate={moment(to_date)}
                      getSelectedDates={this.setDates}
                      parentEl="#outerside"
                      className="single-date-picker"
                    />
                  </div>
                </div>
              </div>
              <div className="col-md-6">
                <div className="form-group">
                  <label>{getLocaleString("common.status", "Status")}</label>
                  <select
                    data-testid="Status"
                    className="form-control"
                    value={status}
                    onChange={(e) => this.setStatus(e.target.value)}
                  >
                    <option value="">
                      {getLocaleString("common.all", "All")}
                    </option>
                    <option value="INITIATED">
                      {getLocaleString("common.initiated", "Initiated")}
                    </option>
                    <option value="PROCESSING">
                      {getLocaleString("common.processing", "Processing")}
                    </option>
                    <option value="PAID">
                      {getLocaleString("common.paid", "Paid")}
                    </option>
                    <option value="REQUIRES_ACTION">
                      {getLocaleString(
                        "payments.requires_action",
                        "Requires Action"
                      )}
                    </option>
                    <option value="FAILED">
                      {getLocaleString("common.failed", "Failed")}
                    </option>
                    <option value="SETTLED">
                      {getLocaleString("common.settled", "Settled")}
                    </option>
                  </select>
                </div>
              </div>
              {enableEasyPayPG() && (
                <div className="col-md-6">
                  <div className="form-group">
                    <label>
                      {getLocaleString(
                        "payments.transaction_type",
                        "Transaction Type"
                      )}
                    </label>
                    <select
                      data-testid="transactionType"
                      className="form-control"
                      value={payment_type}
                      onChange={(e) => this.setTransactionType(e.target.value)}
                    >
                      <option value="REFUND">
                        {getLocaleString("common.refund", "Refund")}
                      </option>
                      <option value="PAYMENT">
                        {getLocaleString("common.payment", "Payment")}
                      </option>
                    </select>
                  </div>
                </div>
              )}
              {!hideCustomerFilter ? (
                <div className="col-md-6">
                  <div className="form-group">
                    <div className="pagepayable">
                      <label className="">
                        {getLocaleString("common.customer", "Customer")}
                      </label>
                      <Autocomplete
                        suggestions={uniqBy(
                          customerList.map((s) => ({
                            id: s.client_customer_rel.id,
                            name: s.customer.name,
                          })),
                          "id"
                        )}
                        getSelected={(item) =>
                          this.setState({ customerSelect: item })
                        }
                        search={(text) => this.debounceMethod(text)}
                        isLoading={isCustomerFetching}
                        defaultValue={customerSelect && customerSelect.name}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="col-md-6" />
              )}
              <div className="col-md-6">
                <div className="setbtn">
                  <Button
                    type="button"
                    variant="light"
                    data-testid="resetbtn"
                    className="resetbtn"
                    onClick={this.onReset}
                  >
                    {getLocaleString("common.reset", "Reset")}
                  </Button>
                  <Button
                    type="button"
                    data-testid="applybtn"
                    className="applybtn"
                    onClick={() => this.getData()}
                  >
                    {getLocaleString("common.submit", "Submit")}
                  </Button>
                </div>
              </div>
            </div>
          </DropdownButton>
          <DropdownButton
            showDropdown={visibleColumnsShow}
            buttonProps={{
              as: "span",
              role: "button",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
              "data-cy": "columns-dropdown",
            }}
            btnClassName="ms-2"
            setShowDropdown={(show) =>
              this.setState({ visibleColumnsShow: show })
            }
            buttonText={getLocaleString("common.columns", "Columns")}
            dropdownWrapperClassName="p-0"
            dropdownWrapperProps={{
              id: "companylist",
              style: {
                maxHeight: "50vh",
                overflowY: "auto",
                minWidth: "13rem",
              },
            }}
            columnsButton
          >
            {Object.keys(visibleColumns)
              .filter((a) => {
                if (
                  (a === "Message" && !showPaymentFailedDetail) ||
                  (a === "Refund" && !showRefundButton)
                ) {
                  return false;
                }
                if (
                  isRefundTransactions &&
                  ["Net Amount", "Refund"].includes(a)
                ) {
                  return false;
                }
                return true;
              })
              .map((acc) => {
                return (
                  <span
                    style={{
                      background: "#eee",
                    }}
                    onClick={() => {
                      if (visibleColumns[acc] && columnsCount === 1) return;
                      this.setState({
                        columnsCount: visibleColumns[acc]
                          ? columnsCount - 1
                          : columnsCount + 1,
                      });
                      const _columns = {
                        ...visibleColumns,
                        [acc]: !visibleColumns[acc],
                      };
                      onChange(_columns);
                      setuxvalue("REPORTS_PAYMENT_COLUMNS", _columns);
                    }}
                  >
                    <input
                      type="checkbox"
                      className="me-2 d-inline-block align-middle"
                      name={acc}
                      disabled={columnsCount === 1}
                      checked={visibleColumns[acc]}
                      data-tlabel={`checkbox ${acc}`}
                      data-cy="column-check-payables"
                    />
                    <span className={"d-inline-block align-middle p-0 w-80"}>
                      {acc}
                    </span>
                  </span>
                );
              })}
          </DropdownButton>
        </div>
        <div className="col-md-6 col d-flex justify-content-end">
          {
            <div className="m-2">
              {this.props.paymentAnalytics.total ? (
                <span className="">
                  {getLocaleString("common.total", "Total")}:{" "}
                  {this.props.paymentAnalytics.total}
                </span>
              ) : null}
            </div>
          }
          <CSVLink
            data={ExportCSV(
              listData,
              visibleColumns,
              isInCustomerPortal,
              exportAllColumns
            )}
            ref="csv"
            filename={`Payments_${moment().format(
              "MMMM Do YYYY-h:mm:ss-a"
            )}.csv`}
            style={{ display: "none" }}
          />
          <DropdownButton
            showDropdown={showexport}
            buttonProps={{
              as: "span",
              role: "button",
              "data-toggle": "dropdown",
              "aria-haspopup": "true",
              "aria-expanded": "false",
              "data-cy": "export",
              endIcon: exporting && <i className="fa fa-spinner fa-spin" />,
            }}
            setShowDropdown={this.setshowExport}
            buttonText={getLocaleString("common.exportTitle", "Export")}
            dropdownWrapperClassName="dropdown-menu-end right-0 payexpdroplist"
            dropdownWrapperProps={{
              id: "payment-export",
            }}
          >
            {enableForVoltyx() ? (
              <span className="t-12 d-block text-end cursor-pointer mb-1 me-2">
                <label className="m-0">
                  <input
                    checked={exportAllColumns}
                    onChange={(e) => this.setExportAllOption(e.target.checked)}
                    type="checkbox"
                    className="align-middle me-1"
                  />{" "}
                  <span className="align-middle">
                    {getLocaleString("common.all_data", "All Data")}
                  </span>
                </label>
              </span>
            ) : null}
            <span
              className="t-12 d-block text-end pe-2 mb-1 cursor-pointer"
              onClick={() => this.exportAllData(true)}
            >
              {getLocaleString("common.exportCSV", "Export CSV")}
            </span>
            <span
              className="t-12 d-block text-end pe-2 cursor-pointer"
              onClick={() => this.exportAllData(false)}
            >
              {getLocaleString("common.exportExcel", "Export Excel")}
            </span>
          </DropdownButton>
        </div>
        <Modal
          footer={() => (
            <>
              <Button
                type="button"
                variant="light"
                data-testid="resetbtn"
                className="resetbtn"
                onClick={this.onReset}
              >
                {getLocaleString("common.reset", "Reset")}
              </Button>
              <Button
                type="button"
                data-testid="applybtn"
                className="applybtn"
                onClick={() => this.getData()}
              >
                {getLocaleString("common.submit", "Submit")}
              </Button>
            </>
          )}
          title="Filter"
          size="md"
          show={showFilterModal}
          onHide={this.setShowFilterModal}
        >
          <div
            className="row"
            style={{ maxHeight: "calc(100vh - 200px)", overflowY: "auto" }}
          >
            <div className="col-12" id="mobile-picker">
              <div className="form-group">
                <label>{getLocaleString("common.date", "Date")}</label>
                <div className="single-date-picker t-12 overflow-hidden">
                  <DateRangePicker
                    all={true}
                    label={interval}
                    startDate={moment(from_date)}
                    endDate={moment(to_date)}
                    getSelectedDates={this.setDates}
                    className="single-date-picker"
                    parentEl="#mobile-picker"
                  />
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="form-group">
                <label>{getLocaleString("common.status", "Status")}</label>
                <select
                  data-testid="Status"
                  className="form-control"
                  value={status}
                  onChange={(e) => this.setStatus(e.target.value)}
                >
                  <option value="">
                    {getLocaleString("common.all", "All")}
                  </option>
                  <option value="INITIATED">
                    {getLocaleString("common.initiated", "Initiated")}
                  </option>

                  <option value="PROCESSING">
                    {getLocaleString("common.processing", "Processing")}
                  </option>
                  <option value="PAID">
                    {getLocaleString("common.paid", "Paid")}
                  </option>
                  <option value="REQUIRES_ACTION">
                    {getLocaleString(
                      "payments.requires_action",
                      "Requires Action"
                    )}
                  </option>
                  <option value="FAILED">
                    {getLocaleString("common.failed", "Failed")}
                  </option>

                  {enableEasyPayPG() && (
                    <option value="SETTLED">
                      {getLocaleString("common.settled", "Settled")}
                    </option>
                  )}
                </select>
              </div>
            </div>
            {enableEasyPayPG() && (
              <div className="col-12">
                <div className="form-group">
                  <label>
                    {getLocaleString(
                      "payments.transaction_type",
                      "Transaction Type"
                    )}
                  </label>
                  <select
                    data-testid="transactionType"
                    className="form-control"
                    value={payment_type}
                    onChange={(e) => this.setTransactionType(e.target.value)}
                  >
                    <option value="REFUND">
                      {getLocaleString("common.refund", "Refund")}
                    </option>
                    <option value="PAYMENT">
                      {getLocaleString("common.payment", "Payment")}
                    </option>
                  </select>
                </div>
              </div>
            )}
          </div>
        </Modal>
      </div>
    );
  }
}
