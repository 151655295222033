import get from "lodash/get";
import TokenManager from "utils/TokenManager";
import Toaster from "components/Toaster";
import { store } from "store";
import { syncUserSettings } from "./ux";

export const clearRedux = () => {
  store.dispatch({ type: "DESTROY_SESSION" });
};

export const logout = (forceLogout) => {
  /* Set current tab data */
  if (window.location.pathname !== "/login") {
    window.sessionStorage.removeItem("client_id_context");
    window.sessionStorage.removeItem("client_customer_rel_id_context");
  }

  if (
    window.location.href.includes("token") &&
    window.location.href.includes("redirect")
  ) {
    localStorage.setItem("loginURL", window.location.href); // store login link
  }

  const clearAndRedirect = () => {
    // Set other open tabs data
    window.localStorage.setItem("loggedout", TokenManager.getUserId());
    const loginUrl = localStorage.getItem("loginURL");
    localStorage.clear();
    setTimeout(() => {
      if (loginUrl) {
        window.open(loginUrl, "_self");
      } else {
        window.location.pathname !== "/login" &&
          window.open(`${window.location.origin}/login`, "_self");
      }
      setTimeout(clearRedux, 100);
    }, 100);
  };

  /* Save current path in UX api */
  if (forceLogout) {
    clearAndRedirect();
    return;
  }
  syncUserSettings(false).then(clearAndRedirect).catch(clearAndRedirect);
};

export const handleAPIError = (e, display_error = false, setisFetching) => {
  // "Request failed with status code 401"
  setisFetching && setisFetching(false);
  let error_message = get(e, "message", "");
  if (display_error && error_message && typeof error_message == "string")
    Toaster(error_message, "error");
  if (get(e, "status", "") === 401) {
    logout(true);
  }
};
