import React, { useRef, useCallback, useState } from "react";
import IconButton from "@mui/material/IconButton";
import FormControl from "@mui/material/FormControl";
import TextField from "@mui/material/TextField";

function InputText({ isLoadingRes = false, onSubmit = () => {} }) {
  const [text, setText] = useState("");
  const textFiledRef = useRef();

  const handleMessageSubmit = useCallback(
    (e) => {
      e.preventDefault();

      if (text?.trim() && !isLoadingRes) {
        onSubmit(text);
        setText("");
      } else {
        // maybe show toaster to user to input something
      }
    },
    [text, isLoadingRes, onSubmit]
  );

  const handleKeyDown = useCallback(
    (e) => {
      if (e.key === "Enter" && !e.shiftKey) {
        e.preventDefault();
        handleMessageSubmit(e);
      }
    },
    [handleMessageSubmit]
  );

  return (
    <FormControl component="form" fullWidth onSubmit={handleMessageSubmit}>
      <section className="d-flex align-items-center border-top p-2 bg-white">
        <TextField
          multiline
          autoFocus
          fullWidth
          inputRef={textFiledRef}
          value={text}
          onChange={(e) => setText(e.target.value)}
          size="small"
          onKeyDown={handleKeyDown}
          maxRows={2}
        />
        <IconButton
          type="submit"
          style={{ transform: "rotate(45deg) " }}
          aria-label="Send"
          onClick={handleMessageSubmit}
          disabled={isLoadingRes}
          sx={{ color: "#2b7ef6" }}
        >
          <i class="fas fa-location-arrow"></i>
        </IconButton>
      </section>
    </FormControl>
  );
}

export default InputText;
