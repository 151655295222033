import { LogglyTracker } from "loggly-jslogger";
import fe_config from "constants/Configs";
import TokenManager from "./TokenManager";
import { isLocal } from "utils";

const logger = new LogglyTracker();
logger.push({
  logglyKey: "93c6de04-b99d-458a-b838-4dca3fafd001",
  tag: "react-log",
});
export default logger;

export const trackEvent = (message, eventType, moreInfo = {}) => {
  // eventTypes = "navigation", "click", "error", "global_search", "driver_invoice_pdf"
  try {
    if (!isLocal()) {
      logger.push({
        message,
        url: window.location.pathname,
        ENV: fe_config.MODE,
        clientId: TokenManager.getClientId(),
        userId: TokenManager.getUserEmail(),
        source: "Sindhu",
        eventType,
        ...moreInfo,
      });
    }
  } catch (e) {}
};
