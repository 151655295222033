import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { enableDefaultAllDateFilter } from "handlers/features";
import { getUXSettingsFromStorage } from "utils";
import { getDateByRangeInterval } from "utils/dates";
import get from "lodash/get";
import mapValues from "lodash/mapValues";
import omit from "lodash/omit";
import { getLocaleString } from "utils/localization/locale";

export const PAYABLE_LIST_COLUMNS = {
  [getLocaleString("common.invoice", "Invoice")]: true,
  [getLocaleString("common.vendor", "Vendor")]: true,
  [getLocaleString("common.vendor_category", "Vendor Category")]: true,
  [getLocaleString("common.lineCategory", "Line Category")]: false,
  [getLocaleString("common.payIQDate", "PayIQ Date")]: true,
  [getLocaleString("common.projectID", "Project ID")]: true,
  [getLocaleString("common.dueDate", "Due Date")]: true,
  [getLocaleString("common.invoiceDate", "Invoice Date")]: true,
  [getLocaleString("common.InvoiceAmount", "Invoice Amount")]: true,
  [getLocaleString("common.closedDate", "Closed Date")]: true,
  [getLocaleString("common.amountDue", "Amount Due")]: true,
  [getLocaleString("common.amountPaid", "Amount Paid")]: true,
  [getLocaleString("common.entity", "Entity")]: false,
  [getLocaleString("common.tags", "Tags")]: false,
  [getLocaleString("po_number.po_number", "PO Number")]: false,
  [getLocaleString("common.method", "Method")]: true,
  [getLocaleString("common.status", "Status")]: true,
  [getLocaleString("payables.sentiment", "Sentiment")]: true,
};

let i = 1;
export const INITIAL_PAYABLE_LIST_COLUMNS_ARRANGEMENT = mapValues(
  PAYABLE_LIST_COLUMNS,
  () => i++
);
i = 1;

export const boxes = [
  {
    id: 0,
    type: getLocaleString("common.totalAmount", "Total Amount"),
    amount: 0,
    isFetching: false,
  },
  {
    id: 1,
    type: getLocaleString("common.critical", "Critical"),
    amount: 0,
    isFetching: false,
  },
  {
    id: 2,
    type: getLocaleString("common.high", "High"),
    amount: 0,
    isFetching: false,
  },
  {
    id: 3,
    type: getLocaleString("common.medium", "Medium"),
    amount: 0,
    isFetching: false,
  },
  {
    id: 4,
    type: getLocaleString("common.low", "Low"),
    amount: 0,
    isFetching: false,
  },
];

const dateRange = {
  from_date: enableDefaultAllDateFilter()
    ? moment(0).format("MM/DD/YYYY")
    : moment().format("MM/DD/YYYY"),
  to_date: enableDefaultAllDateFilter()
    ? moment().add(1, "year").format("MM/DD/YYYY")
    : moment().add(30, "days").format("MM/DD/YYYY"),
  interval: enableDefaultAllDateFilter() ? "All" : "Next 30 Days",
};

let savedFilters = getUXSettingsFromStorage("PAYABLE_FILTERS");
if (savedFilters && savedFilters.interval) {
  const { interval, from_date, to_date } = savedFilters;
  const updatedKeys = {};
  const [sd, ed] = getDateByRangeInterval(
    interval === "All" ? "AllToDate" : interval,
    from_date,
    to_date
  );
  savedFilters = {
    ...savedFilters,
    ...updatedKeys,
    from_date: sd,
    to_date: ed,
  };
}

export const initialPayablesFilters = {
  ...dateRange,
  page: 1,
  status: "open",
  tag_ids: [],
  sort_by: "amount_due",
  category: "",
  date_type: "recommendation",
  sentiment: "",
  sort_order: "desc",
  not_tag_ids: [],
  payment_plan: "",
  invoiceSelect: null,
  corp_entity_id: "",
  vendorSelect: null,
  amount_less_than: "",
  amount_greater_than: "",
  exclude_payment_type: "",
  appliedCustomFilters: {},
  show_payiq_overriden: true,
  line_vendor_category_id: "",
  header_vendor_category_id: "",
};

export const handleSavedFilters = (filters) => {
  const updatedFilters = {};
  let omitFilters = [];
  if (get(filters, "custom_field_master_id", "")) {
    omitFilters.push("custom_field_master_id");
    const customField = get(filters, "custom_field_master_id")
      .split(",")
      .reduce((acc, cur) => {
        const csFilter = Object.keys(filters).reduce((a, c) => {
          if (c.includes(cur)) {
            omitFilters.push(c);
            a = {
              ...a,
              [c]: filters[c] ? decodeURIComponent(filters[c]) : "",
            };
          }
          return a;
        }, {});
        return (acc = {
          ...acc,
          [Number(cur)]: {
            custom_field_master_id: Number(cur),
            ...csFilter,
          },
        });
      }, {});
    updatedFilters.appliedCustomFilters = customField;
  }
  if (get(filters, "invoice_id", "")) {
    omitFilters.push("invoice_id");
    updatedFilters.invoiceSelect = {
      name: get(filters, "invoice_id", ""),
      id: get(filters, "invoice_id", ""),
    };
  }
  if (get(filters, "supplier_id", "")) {
    omitFilters.push("supplier_id");
    omitFilters.push("supplier_name");
    updatedFilters.vendorSelect = {
      name: get(filters, "supplier_name", ""),
      id: get(filters, "supplier_id", ""),
    };
  }
  return omit({ ...filters, ...updatedFilters }, omitFilters);
};

const initialPayablesData = {
  invoiceList: [],
  exportedData: [],
  isFetching: false,
  isExporting: false,
  getCustomFieldsData: true,
  filters: {
    ...initialPayablesFilters,
    ...handleSavedFilters(savedFilters),
  },
  visibleColumns: {
    ...PAYABLE_LIST_COLUMNS,
    ...getUXSettingsFromStorage("PAYABLE_LIST_COLUMNS"),
  },
};

const payables = (state = initialPayablesData, action) => {
  switch (action.type) {
    case ReducersTypes.SET_PAYABLES_LIST_DATA:
      return {
        ...state,
        ...action.payload,
      };

    case ReducersTypes.SET_PAYABLES_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...state.filters,
          ...action.payload,
        },
      };

    case ReducersTypes.RESET_PAYABLES_LIST_FILTERS:
      return {
        ...state,
        filters: {
          ...initialPayablesFilters,
          ...action.payload,
        },
      };

    default:
      return state;
  }
};

export default payables;
