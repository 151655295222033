import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";

const InfoBox = ({ placement = "right", description, label }) => {
  return (
    <OverlayTrigger
      placement={placement}
      overlay={
        <Tooltip className="max-width-300px">
          <div className="tooltip-inline">{description}</div>
        </Tooltip>
      }
    >
      <span data-cy="info-tooltip" className="align-top">
        {label}
        <i
          className="fas fa-info-circle t-20 align-middle"
          data-tlabel={`Info Icon ${label || ""}`}
        />
      </span>
    </OverlayTrigger>
  );
};

export default InfoBox;
