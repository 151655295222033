import get from "lodash/get";
import trim from "lodash/trim";
import { numberRegEx, urlRegEx } from "../constants/regex";
import fe_config from "constants/Configs";

export const getValueFromUrl = (_url = "", _key = "") =>
  _url
    ? get(
        _url
          .split("&")
          .filter((x) => x)
          .map((x) => x.split("="))
          .find((param) => [_key].includes(param[0])),
        "[1]",
        ""
      )
    : "";

export const getValueFromLocalFilter = (fetch_from_key = "", _key = "") => {
  const obj = JSON.parse(localStorage.getItem(fetch_from_key) || "{}");
  return obj[_key];
};

export const deleteValuesFromURL = (_url = "", _keys = "") => {
  let result = _url
    .split("&")
    .filter((x) => x)
    .map((x) => x.split("="))
    .filter((param) => !_keys.includes(param[0]))
    .map((x) => x.join("="))
    .join("&");
  return _url ? result : "";
};

export const deleteEmptyValuesFromURL = (_url = "", _keys = "") => {
  let result = _url
    .split("&")
    .filter((x) => x)
    .map((x) => x.split("="))
    .filter((param) => param[1] && param[1] !== undefined && param[1] !== [])
    .map((x) => x.join("="))
    .join("&");
  return _url ? result : "";
};

export const encodeValues = (value) =>
  value && "X".repeat(10) + value.substr(value.length - 4);

export function deURLify(string) {
  return string
    .trim()
    .replace("%20", "")
    .replace("%22", "")
    .replace("%27", "")
    .replace(/'/g, "", "");
}

export function trimText(str) {
  return trim(str);
}

export function removeFirstNumberWord(str) {
  return String(str).split(" ")[0].match(numberRegEx)
    ? str.split(" ").slice(1).join(" ")
    : str;
}

export function removeNumbersFromWord(str) {
  return String(str)
    .split(" ")
    .filter((word) => !word.match(numberRegEx))
    .join(" ");
}

export function urlify_string_only(text) {
  const str = text.split("<").join(" < ");
  const str1 = str.split(">").join(" > ");
  let res = "";
  str1.replace(urlRegEx, function (url) {
    res = url;
    return url;
  });
  return res.replace(fe_config.REACT_APP_URL, window.location.origin);
}

export function urlify(text) {
  const str = text.split("<").join(" < ");
  const str1 = str.split(">").join(" > ");
  const x = str1.replace(urlRegEx, function (url) {
    return (
      '<a className="disabled" href="' +
      url +
      '" target="_blank">' +
      url +
      "</a>"
    );
  });
  return `<span>${x}</span>`;
}
