import { ReducersTypes } from "constants/ReducersTypes";

export const initialData = {
  listData: [],
  isFetching: false,
  pagination: {
    next_num: 2,
    pages: 2,
    prev_num: 1,
  },
};

const skipArEmail = (state = initialData, { type, payload }) => {
  switch (type) {
    case ReducersTypes.SKIP_AR_EMAIL_LIST_FETCH:
      return {
        ...state,
        isFetching: payload.isFetching,
        listData: payload.listData,
      };
    default:
      return state;
  }
};

export default skipArEmail;
