import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import { generateQueryString } from "utils/query";
import get from "lodash/get";

export const getDisputeCodes = (mutate = "") => {
  const url = `client/config/dispute_codes${generateQueryString({ mutate: mutate ? 1 : "" })}`;
  return instanceWithRetry
    .get(url)
    .then((res) => {
      localStorage.setItem(
        "dispute_code_list",
        JSON.stringify(get(res, "data.result", []))
      );
      return res;
    })
    .catch((e) => handleAPIError(e));
};

export const createOrUpdateDisputeCode = (data, id) =>
  instanceWithRetry
    .post(
      `client/config/dispute_codes${generateQueryString({ id, mutate: 1 })}`,
      data
    )
    .catch((e) => handleAPIError(e));

export const deleteDisputeCode = (id) =>
  instanceWithRetry
    .delete(`client/config/dispute_codes?id=${id}&mutate=1`)
    .catch((e) => handleAPIError(e, true));
