const { ReducersTypes } = require("constants/ReducersTypes");
const compareScenariosInitialState = {
  isloading: [],
  dateRanges: [],
  entityWiseData: [],
  total_inflow_main: [],
  total_outflow_main: [],
  total_bankbalance_main: [],
  total_netoperating_main: [],
  total_endingbalance_main: [],
};

const compareScenarios = (state = compareScenariosInitialState, action) => {
  switch (action.type) {
    case ReducersTypes.COMPARE_SCENARIOS:
      return { ...state, ...action.payload };
    case ReducersTypes.RESET_COMPARE_SCENARIOS:
      return compareScenariosInitialState;
    default:
      return state;
  }
};
export default compareScenarios;
