export const TYPE_USER = "user";
export const TYPE_SYSTEM = "system";
export const TYPE_ASSISTANT = "assistant";

export class Message {
  constructor(role = "TYPE_USER", content, files = [], timestamp) {
    this.role = role;
    this.content = content;
    this.files = files;
    this.timestamp = timestamp;
  }
}
