import { handleAPIError } from "handlers/setters";
import { generateQueryString } from "utils/query";
import get from "lodash/get";
import omit from "lodash/omit";
import moment from "moment";
import uniqBy from "lodash/uniqBy";
import { trimText } from "utils/strings";
import {
  isInvoiceOpen,
  setReducerData,
  removeEmptyValueFromObject,
} from "utils";
import { instanceWithRetry } from "actions/axiosInstance";
import { ReducersTypes } from "constants/ReducersTypes";
import { sendExportedReport } from "actions/global";
import TokenManager from "utils/TokenManager";
import Toaster from "components/Toaster";
import { getLocaleString } from "utils/localization/locale";

export const setReceivablesData = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.SET_RECEIVABLE_LIST_DATA, data));

export const setReceivablesTotals = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.SET_RECEIVABLE_LIST_TOTALS, data));

export const setReceivablesFilters = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.SET_RECEIVABLE_LIST_FILTERS, data));

export const resetReceivablesFilters = (data) => (dispatch) =>
  dispatch(setReducerData(ReducersTypes.RESET_RECEIVABLE_LIST_FILTERS, data));

export const getParams = (params, type) => {
  const {
    status,
    tag_ids,
    sort_by,
    cat_ids,
    sort_order,
    entity_ids,
    assignedTo,
    not_tag_ids,
    not_cat_ids,
    invoiceSelect,
    corp_entity_id,
    not_entity_ids,
    customerSelect,
    ignoreURIencode,
    other_categories,
    appliedCustomFilters,
    auto_categorize_ar_invoice,
    header_customer_category_id,
  } = params;

  let omitParams = [
    "0",
    "1",
    "2",
    "3",
    "tag_ids",
    "endDate",
    "cat_ids",
    "currPage",
    "dateType",
    "search_id",
    "startDate",
    "totalPages",
    "assignedTo",
    "overridden",
    "entity_ids",
    "not_cat_ids",
    "api_sort_by",
    "not_tag_ids",
    "isEmailExport",
    "invoiceSelect",
    "customerSelect",
    "not_entity_ids",
    "api_sort_order",
    "ignoreURIencode",
    "bandFilteredStatus",
    "appliedCustomFilters",
    "auto_categorize_ar_invoice",
  ];

  const isClassificationParams = type === "classification";

  const omitClassificationParams = [
    "page",
    "sort_by",
    "sort_order",
    "send_custom_fields",
  ];

  if (isClassificationParams) {
    omitParams = omitParams.concat(omitClassificationParams);
  }

  const commonParams = isClassificationParams
    ? {}
    : {
        with_notes: 1,
        include_customer_category: 1,
        invoice_status: "ACTIVE",
      };
  let updatedParams = {
    ...omit(params, omitParams),
    ...commonParams,
  };

  if (invoiceSelect) {
    updatedParams = {
      invoice_id: invoiceSelect.name,
      status: "all",
    };
    if (isClassificationParams) {
      updatedParams.categories = params.categories;
    }
  } else {
    if (Object.keys(appliedCustomFilters).length) {
      let masterId = [];
      Object.keys(appliedCustomFilters).forEach((d) => {
        const filter = appliedCustomFilters[d];
        let flag = false;
        Object.keys(filter).forEach((f) => {
          if (f !== "custom_field_master_id" && filter[f]) {
            updatedParams[f] = ignoreURIencode
              ? filter[f]
              : encodeURIComponent(filter[f]);
            flag = true;
          }
        });
        if (flag) {
          masterId.push(d);
          flag = false;
        }
      });
      if (masterId.length) {
        updatedParams["custom_field_master_id"] = masterId.join();
      }
    }
    if (customerSelect) {
      updatedParams.customer_id = customerSelect.id;
    }
    if (sort_by) {
      updatedParams.sort_order = sort_order;
      updatedParams.sort_by = sort_by;
      if (status === "open" && sort_by === "amount_closed") {
        updatedParams.sort_by = "amount_due";
      } else if (status === "closed" && sort_by === "amount_due") {
        updatedParams.sort_by = "amount_closed";
      }
    }
    if (assignedTo) {
      updatedParams.user_role_ids = assignedTo;
      updatedParams.role_name = "arrep";
    }
    [
      { name: "tag_ids", value: tag_ids },
      { name: "not_tag_ids", value: not_tag_ids },
      {
        name: "cat_ids",
        value:
          header_customer_category_id &&
          !["0", "null", "", null].includes(header_customer_category_id)
            ? [header_customer_category_id]
            : cat_ids,
      },
      { name: "not_cat_ids", value: not_cat_ids },
      {
        name: "entity_ids",
        value:
          corp_entity_id && !["0", "null", "", null].includes(corp_entity_id)
            ? [corp_entity_id]
            : entity_ids,
      },
      { name: "not_entity_ids", value: not_entity_ids },
    ].forEach((param) => {
      if (param.value && param.value.length) {
        updatedParams[param.name] =
          typeof param.value === "string" ? param.value : param.value.join();
      }
    });
    if (status === "open") {
      updatedParams.exclude_zero_due = 1;
    }
    if (auto_categorize_ar_invoice) {
      updatedParams.auto_categorize_enabled = 1;
    }
    if (other_categories) {
      updatedParams.other_categories = JSON.stringify(other_categories);
    }
  }
  return isClassificationParams
    ? omit(updatedParams, omitClassificationParams)
    : updatedParams;
};

export const getInvoiceList = (params) => {
  const updatedParams = getParams(params, "invoicelist");
  if (get(params, "isEmailExport", false)) {
    return sendExportedReport(
      removeEmptyValueFromObject({
        ...updatedParams,
        export_type: "csv",
        page_type: "ar_invoice",
      })
    );
  }
  return instanceWithRetry
    .get(`arinvoice${generateQueryString(updatedParams)}`)
    .catch((e) => handleAPIError(e));
};

export const getInvoiceClassificationData = (params) => {
  const updatedParams = getParams(params, "classification");
  return instanceWithRetry
    .get(`arinvoice/total${generateQueryString(updatedParams)}`)
    .catch((e) => handleAPIError(e));
};

export const deleteInvoice = (requestObject) => {
  return instanceWithRetry
    .delete("arinvoice?mutate=1", requestObject)
    .catch((e) => handleAPIError(e));
};

export const changePayIqDate = (selectedInvoices = [], value) => {
  const allpromises = selectedInvoices.map((x) =>
    instanceWithRetry.put(`/arinvoice?id=${x.id}&mutate=1`, {
      expected_date: value,
      expected_date_override: true,
    })
  );

  return Promise.all(allpromises)
    .then(() => {
      const allnotespromises = selectedInvoices.map((x) =>
        instanceWithRetry.put(`/customer/note?mutate=1`, {
          date: moment().format("MM/DD/YYYY"),
          note: `${
            x.invoice_number ||
            `${getLocaleString(
              "common.invoice_for_amount",
              "Invoice for Amount"
            )} ${x.invoice_amount}`
          }: ${getLocaleString(
            "common.updated_payiq_to",
            "Updated PayIQ to"
          )}  ${value}`,
          invoice_id: x.id,
          client_customer_rel_id: get(x, "client_customer_rel.id", ""),
        })
      );
      Promise.all(allnotespromises);
    })
    .catch((e) => handleAPIError(e, true));
};

export const excludeInvoices = (requestObject) => {
  return instanceWithRetry
    .post("arinvoice?mutate=1", requestObject)
    .catch((e) => handleAPIError(e, true));
};

export const getCustomerCategories = () => {
  return instanceWithRetry
    .get("customercategory?per_page=1000")
    .catch((e) => handleAPIError(e));
};

export const searchCustomer = (text, searchBy) => {
  if (!text) return Promise.resolve();
  const url = `arinvoice/search?${searchBy}=${trimText(text)}`;
  return instanceWithRetry.get(url).catch((e) => handleAPIError(e));
};

export const getCustomFieldDropdownData = (data, callback) => {
  return instanceWithRetry
    .get(`/customfield${generateQueryString(data)}`)
    .then((response) => {
      const mappedValues = response.data.result
        .filter((d) => d)
        .map((d) => ({ label: d, value: d }));
      callback && callback(mappedValues);
      return {
        id: data.custom_field_master_id,
        values: mappedValues,
      };
    })
    .catch((e) => {
      handleAPIError(e);
      callback && callback([]);
      return {
        id: data.custom_field_master_id,
        values: [],
      };
    });
};

export const getCustomFieldFilters = () => {
  return instanceWithRetry
    .get("customfields?model_name=ARInvoiceHeader,ClientCustomerRelationship")
    .then(async (res) => {
      const result = res.data.result;
      const masterFieldsData = result
        .filter((d) => d.field_type === "STRING")
        .map((d) => {
          return getCustomFieldDropdownData({
            custom_field_master_id: d.id,
            per_page: 1000,
          });
        });
      const masterFieldValues = (
        masterFieldsData.length ? await Promise.all(masterFieldsData) : []
      ).reduce((acc, cur) => ({ ...acc, [cur.id]: cur.values }), {});
      return {
        customFields: result,
        masterFieldValues,
      };
    })
    .catch((e) => handleAPIError(e));
};

export const calculateAmountPaidForLines = (lines, amount_closed) => {
  if (lines && lines.length) {
    return lines.map((d) => {
      if (d.fact_invoice_amount) {
        return {
          ...d,
          amount_closed: d.fact_invoice_amount * amount_closed,
        };
      }
      return d;
    });
  }
  return [];
};

export const updateSentiment = (dataId, sentiment) =>
  instanceWithRetry
    .put(`customer?id=${dataId}&mutate=1`, {
      sentiment: sentiment,
    })
    .catch((e) => handleAPIError(e));

export function setTags(
  selectedInvoices,
  selectedTags = [],
  unselectedTags = [],
  clear_all = false
) {
  const allPromises = selectedInvoices.map((x) => {
    const previousTagIds = get(x, "tags", []).map((value) => value.id);
    return instanceWithRetry.put(`/arinvoice?id=${x.id}&mutate=1`, {
      tag_ids: clear_all
        ? []
        : uniqBy([
            ...previousTagIds.filter((item) => !unselectedTags.includes(item)),
            ...selectedTags,
          ]),
      remove_tag_ids: unselectedTags,
    });
  });
  return Promise.all(allPromises);
}

export const checkRowsForMarkPaid = (selectedRows = [], equal = false) => {
  const tagList = ["MARKED_PAID_BY_USER", "PAID_WITH_CHECK"];
  if (equal) {
    return selectedRows.some((value) =>
      get(value, "tags", []).some((value) => tagList.includes(value.name))
    );
  } else {
    return selectedRows
      .filter((d) => isInvoiceOpen(d) && get(d, "status", "") !== "Pending")
      .some((value) =>
        get(value, "tags", []).length
          ? !value.tags.some((value) => tagList.includes(value.name))
          : true
      );
  }
};

export const getUsersList = () =>
  instanceWithRetry.get("client/user").catch((e) => handleAPIError(e));

export const refreshInvoices = (refreshInvoices) =>
  instanceWithRetry
    .post(
      "client/fetchdata?mutate=1",
      {
        internal_id_list: refreshInvoices,
        data_type: "ar_invoice",
      },
      {
        headers: {
          client_id_context: TokenManager.getClientId(),
        },
      }
    )
    .then((res) => {
      if (res.status === 200) {
        Toaster(
          getLocaleString(
            "common.data_will_be_updated_in_while",
            "Data will be updated in a while"
          ),
          "success"
        );
      } else {
        Toaster(
          getLocaleString(
            "common.failed_to_refresh_invoices",
            "Failed to refresh invoices"
          ),
          "error"
        );
      }
    })
    .catch((e) => {
      if (e.message === "Network Error") {
        Toaster("API timeout", "error");
      } else {
        Toaster(
          `${getLocaleString(
            "common.error_occured_while_fetching_data",
            "Error occured while fetching data"
          )} ${e.message || ""}`,
          "error"
        );
      }
      handleAPIError(e);
    });
