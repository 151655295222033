import React from "react";
import "./DotLoader.css";

function DotLoader({ variant = "v1" }) {
  return (
    <div className="dotLoader__wrapper dotLoader__variant-v1">
      <span></span>
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
}

export default DotLoader;
