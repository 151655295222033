import React from "react";
import If from "components/If";
import get from "lodash/get";
import { instanceWithRetry, instanceMultipart } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import Toaster from "components/Toaster";
import DNDContainer from "components/DNDContainer";
import DragCard from "components/DragCard";
import omit from "lodash/omit";
import { compareState, paginationNextButtonStatus, dataURItoBlob } from "utils";
import ActionsModal from "./ActionsModal";
import { getClientData, updateClientData } from "actions/client";
import { connect } from "react-redux";
import { getLocaleString } from "utils/localization/locale";
import {
  setconfirmationboxData,
  resetconfirmationboxData,
} from "components/ConfirmationBox";
import CustomeCheckbox from "components/DynamicForm/custome-chekbox/custome-checkbox";
import InfoBox from "components/InfoBox";
import {
  saveConfigSettings,
  getConfigSettings,
  updateSettings,
} from "../../action";
import TokenManager from "utils/TokenManager";
import Button from "components/Button";
import LogoUpload from "components/LogoUpload";

const newStepData = {
  body: "",
  email: "",
  count: "",
  subject: "",
  step_seq: 1,
  trigger_days: null,
  workflow_template_id: null,
  trigger_on_statement_date: false,
};

const reminderOptions = [
  { name: "Every day", value: "1" },
  { name: "Every 2 days", value: "2" },
  { name: "Every 3 days", value: "3" },
  { name: "Every 5 days", value: "5" },
  { name: "Every 7 days", value: "7" },
  { name: "Every 10 days", value: "10" },
  { name: "Every 14 days", value: "14" },
];

const initState = {
  currPage: 1,
  createNew: false,
  showModal: false,
  sequenceID: "",
  searchList: [],
  companyData: null,
  workflowName: "",
  workflowLogo: "",
  uploadedLogo: { data: null, name: "" },
  searchLoading: false,
  modalStepData: null,
  selectedBulkSequence: "",
  selectBulkAssignment: false,
  workflow_sequence_steps: [],
  trigger_on_statement_date: false,
  apply_default_workflow_to_new_customers: false,
  override_workflow_assignment: false,
  apply_workflow_for_future_customers: false,
  future_customer_workflow_sequence_id: "",
};

class Workflow extends React.Component {
  constructor(props) {
    super(props);
    this.state = initState;
  }

  componentDidUpdate() {
    // reset on back btn click
    if (
      get(this.props, "match.params.id", "") &&
      this.props.workflowSteps.data.length &&
      !this.state.sequenceID
    ) {
      this.getWorkflowSequenceDetail(
        Number(get(this.props, "match.params.id", ""))
      );
    } else if (
      this.state.sequenceID &&
      !get(this.props, "match.params.id", "")
    ) {
      this.setState({ ...initState });
    }
  }

  componentDidMount() {
    const {
      clientDetails: { clientData },
    } = this.props;
    if (clientData && clientData.client_settings) {
      const clientSettingsValue = get(
        clientData,
        "client_settings.future_customer_workflow_sequence_id",
        ""
      );
      this.setState({
        future_customer_workflow_sequence_id: clientSettingsValue,
      });
    }
  }

  setWorkflowLogo = (uploadedLogo) => this.setState({ uploadedLogo });

  getWorkflowSequenceDetail = (id) => {
    if (!id) return;
    const selectedWF = this.props.workflowSteps.data.find((x) => x.id === id);
    if (!selectedWF) {
      this.props.history.push(`/settings/receivables/ar-workflow`);
      return;
    }
    this.props.history.push(`/settings/receivables/ar-workflow/${id}`);
    this.setState({
      createNew: false,
      sequenceID: id,
      workflowLogo: selectedWF.logo_url,
      workflow_sequence_steps: selectedWF.workflow_sequence_steps
        .map((x) => ({
          ...get(x, "workflow_template", {}),
          ...x,
        }))
        .sort((a, b) => a.step_seq - b.step_seq),
      workflowName: selectedWF.name,
    });
  };

  getEmailTemplateDetails = (id, stepData) => {
    const URL = `arworkflow/template?id=${id}`;
    instanceWithRetry
      .get(URL)
      .then((response) => {
        this.setState({
          modalStepData: {
            ...this.state.modalStepData,
            body: get(response, "data.result[0].body", null),
            subject: get(response, "data.result[0].subject", ""),
          },
        });
        this.updateStepData(
          stepData,
          "body",
          get(response, "data.result[0].body", null)
        );
        this.updateStepData(
          stepData,
          "name",
          get(response, "data.result[0].name", null)
        );
        this.updateStepData(
          stepData,
          "subject",
          get(response, "data.result[0].subject", "")
        );
      })
      .catch((e) => handleAPIError(e));
  };

  updateStepData = (stepData, updateKey, value) => {
    const { workflow_sequence_steps } = this.state;
    this.setState({
      workflow_sequence_steps: workflow_sequence_steps.map((d) => {
        if (d.step_seq === stepData.step_seq) {
          return {
            ...d,
            [updateKey]: value,
          };
        }
        return d;
      }),
    });
  };

  onModalSave = (step_seq, stepData, newWf = false) => {
    this.setState(
      {
        showModal: false,
        modalStepData: null,
        trigger_on_statement_date: false,
        workflow_sequence_steps: this.state.workflow_sequence_steps
          .map((d) => {
            if (d.step_seq === step_seq) {
              return {
                ...d,
                ...stepData,
              };
            }
            return d;
          })
          .sort((a, b) => {
            const x = !a.trigger_days ? 0 : parseInt(a.trigger_days, 10);
            const y = !b.trigger_days ? 0 : parseInt(b.trigger_days, 10);

            if (x === 0 && y === 0) return 1 / x - 1 / y || 0;
            else return x - y;
          })
          .map((d, i) => ({ ...d, step_seq: i + 1 })),
      },
      () => (newWf ? null : this.createWorkflow(true))
    );
  };

  addSequenceStep = (currentStep) => {
    const { workflow_sequence_steps } = this.state;
    const incompleteStep = workflow_sequence_steps.find((d) =>
      d.trigger_task
        ? !(
            get(d, "task_details.title", "") &&
            (get(d, "task_trigger_criteria.overdue_days", 0) ||
              get(d, "task_trigger_criteria.overdue_balance", 0))
          )
        : !d.body && !d.workflow_template_id && !d.subject && !d.trigger_days
    );
    if (workflow_sequence_steps.length && incompleteStep) {
      this.setState({ showModal: true, modalStepData: incompleteStep });
    } else {
      this.setState({
        workflow_sequence_steps: [
          {
            ...newStepData,
            step_seq: currentStep,
          },
        ].concat(workflow_sequence_steps),
        showModal: true,
        modalStepData: {
          ...newStepData,
          step_seq: currentStep,
        },
      });
    }
  };

  removeSequenceStep = (step_seq, id) => {
    const { workflow_sequence_steps } = this.state;
    if (step_seq) {
      this.setState({
        workflow_sequence_steps: workflow_sequence_steps
          .filter((d) => !d.id && d.step_seq !== step_seq)
          .map((d, i) => ({ ...d, step_seq: i + 1, count: i + 1 })),
      });
    }
    if (id) {
      this.setState({
        workflow_sequence_steps: workflow_sequence_steps.map((d, i) => {
          if (d.id === id) {
            return {
              ...d,
              is_archived: true,
            };
          } else {
            return { ...d, count: i + 1, step_seq: i + 1 };
          }
        }),
      });
    }
  };

  saveSettingDetails = async (settings, label) => {
    const obj = [];
    Object.keys(settings).forEach((key) =>
      obj.push({
        name: key,
        value: Number(settings[key]).toString(),
        type: "BOOLEAN",
        description: `${label} setting`,
      })
    );
    await saveConfigSettings(obj, label).then(() => {
      Toaster(`${label} settings updated`, "success");
      this.setState({
        showWorkflowSettings: false,
      });
      getConfigSettings(true);
    });
  };

  createWorkflow = (redirect = true) => {
    const { workflowName, sequenceID, uploadedLogo } = this.state;
    const requestObject = {
      name: workflowName,
      workflow_sequence_steps: this.reorderWorkflowStepsBeforeSubmit(),
    };

    let bodyFormData = new FormData();

    if (uploadedLogo && uploadedLogo.data) {
      bodyFormData.append(
        "logo",
        new File([dataURItoBlob(uploadedLogo.data)], uploadedLogo.name),
        `${uploadedLogo.name}`
      );
    }

    if (!uploadedLogo.data && uploadedLogo.name === "remove_logo") {
      requestObject.logo = "0";
    }

    bodyFormData.append("records", JSON.stringify(requestObject));

    const URL = sequenceID
      ? `arworkflow/sequence?id=${sequenceID}&mutate=1`
      : "arworkflow/sequence?mutate=1";
    instanceMultipart
      .post(URL, bodyFormData)
      .then(() => {
        if (sequenceID) {
          Toaster(
            getLocaleString("receivables.workflow_updated", "Workflow Updated"),
            "success"
          );
          this.props.fetchWorkflowSteps(true);
        } else {
          this.setState(
            {
              ...initState,
            },
            () => this.props.fetchWorkflowSteps(true)
          );
          Toaster(
            getLocaleString("receivables.workflow_added", "Workflow added"),
            "success"
          );
        }
        if (redirect) {
          this.props.history.push("/settings/receivables/ar-workflow");
        }
      })
      .catch((e) => handleAPIError(e, true));
  };

  deleteWorkflow = (id) => {
    setconfirmationboxData({
      variant: "warning",
      msg: getLocaleString(
        "receivables.are_you_sure_you_want_to_delete",
        "Are you sure you want to delete?"
      ),
      onSave: () => {
        resetconfirmationboxData();
        instanceWithRetry
          .delete(
            `arworkflow/sequence?id=${this.state.sequenceID || id}&mutate=1`
          )
          .then(() => {
            this.props.history.push("/settings/receivables/ar-workflow");
            Toaster(
              getLocaleString(
                "common.deleted_successfully",
                "Deleted successfully"
              ),
              "success"
            );
            this.setState(
              {
                ...initState,
              },
              () => this.props.fetchWorkflowSteps(true)
            );
          })
          .catch((error) => {
            if (error && error.status === 400) {
              setconfirmationboxData({
                variant: "warning",
                hideCancel: true,
                msg: getLocaleString(
                  "receivables.some_customers_are_using_workflow",
                  "Some customer(s) are using this workflow, please remove this workflow from the customers and try again."
                ),
                okButtonText: getLocaleString("common.ok", "Ok"),
                onSave: () => {
                  resetconfirmationboxData();
                },
              });
            }
          });
      },
    });
  };

  updateStepsSequence = (step, newStep) => {
    const { workflow_sequence_steps } = this.state;
    const movedItem = workflow_sequence_steps.filter(
      (_, index) => index === step
    );
    const remainingItems = workflow_sequence_steps.filter(
      (_, index) => index !== step
    );

    const reorderedItems = [
      ...remainingItems.slice(0, newStep),
      movedItem[0],
      ...remainingItems.slice(newStep),
    ]
      .map((d, index) => ({ ...d, step_seq: index + 1 }))
      .sort((a, b) => a.step_seq - b.step_seq);

    this.setState({
      workflow_sequence_steps: reorderedItems,
    });
  };

  reorderWorkflowStepsBeforeSubmit = () => {
    const updatedSteps = this.state.workflow_sequence_steps
      .sort((a, b) => {
        const x =
          a.triggerEvent === "2"
            ? -99999
            : !a.trigger_days
              ? 0
              : parseInt(a.trigger_days, 10);
        const y =
          b.triggerEvent === "2"
            ? -99999
            : !b.trigger_days
              ? 0
              : parseInt(b.trigger_days, 10);

        if (x === 0 && y === 0) return 1 / x - 1 / y || 0;
        else return x - y;
      })
      .map((d, i) => {
        if (d.trigger_days > 0 || d.trigger_days < 0) {
          const obj = omit({ ...d, step_seq: i + 1 }, [
            "count",
            "triggerEvent",
            "trigger_on_statement_date",
            "statement_day",
            "statement_period",
          ]);
          if (d.trigger_days < 0) {
            return omit({ ...obj, is_recurring: false }, [
              "trigger_after_invoice_date",
            ]);
          }
          return {
            ...obj,
            trigger_on_invoice_date: 0,
            trigger_on_statement_date: 0,
          };
        } else if (d.trigger_on_invoice_date) {
          const obj = omit({ ...d, step_seq: i + 1 }, [
            "count",
            "triggerEvent",
            "trigger_after_invoice_date",
            "trigger_on_statement_date",
            "statement_day",
            "statement_period",
          ]);
          return {
            ...obj,
            is_recurring: false,
            trigger_on_statement_date: 0,
          };
        } else if (d.trigger_on_statement_date) {
          const obj = omit({ ...d, step_seq: i + 1 }, [
            "count",
            "triggerEvent",
            "trigger_after_invoice_date",
            "trigger_on_invoice_date",
          ]);
          return {
            ...obj,
            is_recurring: false,
            trigger_on_invoice_date: 0,
          };
        } else if (d.trigger_days === 0) {
          const obj = omit({ ...d, step_seq: i + 1 }, [
            "count",
            "triggerEvent",
            "trigger_on_invoice_date",
            "trigger_on_statement_date",
            "statement_day",
            "statement_period",
          ]);
          return {
            ...obj,
            is_recurring: false,
            trigger_on_invoice_date: 0,
            trigger_on_statement_date: 0,
          };
        }
        return omit({ ...d, step_seq: i + 1 }, ["count", "triggerEvent"]);
      });
    this.setState({ workflow_sequence_steps: updatedSteps });
    return updatedSteps;
  };

  renderWorkflowSteps = (stepData, index) => {
    const {
      id,
      name,
      email,
      step_seq,
      trigger_task,
      trigger_days,
      trigger_on_invoice_date,
      trigger_on_statement_date,
      trigger_after_invoice_date,
    } = stepData;

    return (
      <DragCard
        key={id || step_seq}
        dragIndex={step_seq}
        index={index}
        moveCard={this.updateStepsSequence}
        className="row mb-3"
      >
        <div className="col-md-1 d-flex align-items-center">
          <span className="email-option d-flex align-items-center justify-content-center">
            <i className="far fa-envelope"></i>
          </span>
        </div>
        <div
          className={`${
            trigger_on_statement_date || trigger_on_invoice_date || trigger_task
              ? "row-gray"
              : trigger_days > 0
                ? "row-red"
                : !trigger_days
                  ? "row-orange"
                  : "row-green"
          } col-md-11 workflow-bar`}
        >
          <div className="row">
            <div
              title={`Users: ${email}`}
              className="col-md-1 users-icon align-items-center d-flex justify-content-center"
            >
              <i className="fas fa-users"></i>
            </div>
            <div className="col-md-2 align-items-center justify-content-center d-flex">
              <button
                onClick={() =>
                  this.setState({ showModal: true, modalStepData: stepData })
                }
                title="Edit event"
                className="btn color-blue"
              >
                <i className="fa fa-pencil"></i>
              </button>
              <span className="px-1">|</span>
              <button
                disabled={
                  this.state.workflow_sequence_steps.filter(
                    (d) => !d.is_archived
                  ).length === 1
                }
                onClick={() => {
                  if (id) {
                    setconfirmationboxData({
                      variant: "warning",
                      msg: getLocaleString(
                        "receivables.are_you_sure_you_want_to_delete_event",
                        "Are you sure you want to delete this event?"
                      ),
                      onSave: () => {
                        resetconfirmationboxData();
                        this.removeSequenceStep("", id);
                      },
                    });
                  } else {
                    this.removeSequenceStep(step_seq);
                  }
                }}
                title={getLocaleString(
                  "receivables.delete_event",
                  "Delete event"
                )}
                className="btn color-blue"
              >
                <i className="fa fa-trash-o"></i>
              </button>
            </div>
            <div className="col-md-2"></div>
            <div className="col-md-6 ms-2">
              <div>{name}</div>
              <div>
                {trigger_task
                  ? getLocaleString("receivables.trigger_task", "Trigger Task")
                  : trigger_on_statement_date
                    ? getLocaleString("receivables.statements", "Statements")
                    : trigger_on_invoice_date
                      ? getLocaleString(
                          "receivables.on_invoice_date",
                          "On Invoice Date"
                        )
                      : !trigger_days
                        ? getLocaleString(
                            "receivables.on_due_date",
                            "On due date"
                          )
                        : `${Math.abs(trigger_days)} days ${
                            trigger_days > 0
                              ? getLocaleString("receivables.after", "after")
                              : getLocaleString("receivables.before", "before")
                          } ${
                            trigger_after_invoice_date
                              ? getLocaleString(
                                  "receivables.invoice_date",
                                  "invoice date"
                                )
                              : getLocaleString(
                                  "receivables.due_date",
                                  "due date"
                                )
                          }`}
                {trigger_task && stepData.task_details ? (
                  <span className="d-block">{stepData.task_details.title}</span>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </DragCard>
    );
  };

  setNextPage = () =>
    this.setState({ currPage: this.state.currPage + 1 }, () =>
      this.props.fetchWorkflowSteps(this.state.currPage)
    );

  setPrevPage = () =>
    this.setState(
      {
        currPage: this.state.currPage === 1 ? 1 : this.state.currPage - 1,
      },
      () => this.props.fetchWorkflowSteps(this.state.currPage)
    );

  setDefaulworkflow = () => {
    this.props
      .updateClientData({
        apply_default_workflow_to_new_customers:
          +this.state.apply_default_workflow_to_new_customers,
      })
      .then(this.props.getClientData);
  };

  setworkflowForfutureCustomers = () => {
    const { future_customer_workflow_sequence_id } = this.state;
    this.props
      .updateClientData({
        future_customer_workflow_sequence_id:
          future_customer_workflow_sequence_id
            ? future_customer_workflow_sequence_id
            : null,
      })
      .then(() => {
        Toaster(
          `${
            future_customer_workflow_sequence_id
              ? getLocaleString("receivables.assigned", "Assigned")
              : getLocaleString("receivables.unassigned", "Unassigned")
          } ${getLocaleString(
            "receivables.workflow_for_future_customers",
            "workflow for future customers."
          )}`,
          "success"
        );
        this.props.getClientData(true);
      })
      .catch((e) => handleAPIError(e));
  };

  onBulkAssignment = () => {
    const { selectedBulkSequence, override_workflow_assignment } = this.state;

    const requestObject = selectedBulkSequence
      ? selectedBulkSequence === "1"
        ? {
            is_enabled: 0,
          }
        : {
            workflow_sequence_id: selectedBulkSequence,
            is_enabled: 1,
          }
      : {
          is_enabled: 0,
        };
    requestObject.override_workflow_assignment = override_workflow_assignment
      ? 1
      : 0;
    setconfirmationboxData({
      variant: "warning",
      msg: selectedBulkSequence
        ? getLocaleString(
            "receivables.you_are_assigning_workflow",
            "You are assigning this workflow to all customers. Existing workflow assignment will be overridden. Do you want to continue?"
          )
        : getLocaleString(
            "receivables.you_are_removing_workflow",
            "You are removing AR workflows from all customers. Existing workflow assignments will be canceled. Do you want to continue?"
          ),
      onSave: () => {
        // this.setDefaulworkflow();  //Left for future use
        instanceWithRetry
          .post("arworkflow/bulk?mutate=1", requestObject)
          .then(() => {
            Toaster(
              `${
                selectedBulkSequence
                  ? selectedBulkSequence === "1"
                    ? getLocaleString("receivables.unassigned", "Unassigned")
                    : getLocaleString("receivables.assigned", "Assigned")
                  : getLocaleString("receivables.unassigned", "Unassigned")
              } ${getLocaleString(
                "receivables.bulk_ar_workflow",
                "bulk AR workflow"
              )}`,
              "success"
            );
          })
          .catch((e) => handleAPIError(e));
        resetconfirmationboxData();
      },
    });
  };

  handleSave = () => {
    const {
      selectedBulkSequence,
      override_workflow_assignment,
      future_customer_workflow_sequence_id,
    } = this.state;
    const {
      clientDetails: { clientData },
    } = this.props;

    const clientSettingsValue = get(
      clientData,
      "client_settings.future_customer_workflow_sequence_id",
      ""
    );
    if (clientSettingsValue !== future_customer_workflow_sequence_id) {
      this.setworkflowForfutureCustomers();
    }

    const isBulkChange = compareState(
      {
        selectedBulkSequence: initState.selectedBulkSequence,
        override_workflow_assignment: initState.override_workflow_assignment,
      },
      {
        selectedBulkSequence,
        override_workflow_assignment,
      }
    ).length;

    if (isBulkChange) {
      this.onBulkAssignment();
    }
  };

  render() {
    const {
      currPage,
      showModal,
      sequenceID,
      workflowName,
      workflowLogo,
      modalStepData,
      showWorkflowSettings,
      workflow_sequence_steps,
      override_workflow_assignment,
    } = this.state;

    const {
      usersList,
      workflowSteps,
      emailTemplate,
      workflowSettings,
      clientDetails: { clientData, isFetching },
      match: {
        params: { id },
      },
    } = this.props;

    const ERP_TYPE = clientData.erp_type;
    const mappedUserList = usersList
      .filter(
        (d) =>
          ![
            "driveractivityadmin",
            "supplier",
            "customer",
            "customer_invoice_restricted",
          ].includes(d.role)
      )
      .map((d) => ({
        label: d.username,
        value: d.user_id,
        role: d.role,
        user_role_id: d.user_role_id,
      }));

    if (isFetching || !Object.keys(clientData).length)
      return <div className="mt-4 circular-loader" />;

    return (
      <div className="row">
        <div className="col-md-12">
          <div className="row">
            <If condition={!id}>
              <>
                <div className="col-md-6 mt-4 ps-0">
                  <Button
                    data-cy="create-new-workflow"
                    onClick={() =>
                      this.setState({ createNew: true }, () =>
                        this.props.history.push(
                          `/settings/receivables/ar-workflow/new`
                        )
                      )
                    }
                  >
                    {getLocaleString("receivables.create_new", "Create New")}
                  </Button>
                </div>
                <div className="col-md-6 mt-2 ps-0">
                  <div className="float-end width-200px">
                    <div className="mt-2">
                      <strong>
                        {getLocaleString(
                          "receivables.combine_emails",
                          "Combine Emails"
                        )}
                        :
                      </strong>
                    </div>
                    <select
                      className="form-control t-13"
                      value={clientData.ar_wf_email_combine_days || ""}
                      onChange={(e) => {
                        this.props
                          .updateClientData({
                            ar_wf_email_combine_days: e.target.value,
                          })
                          .then(() => {
                            Toaster("Updated", "success");
                            this.props.getClientData();
                          });
                      }}
                    >
                      <option default selected value="0">
                        {getLocaleString("common.select", "Select")}
                      </option>
                      {reminderOptions.map((d) => (
                        <option key={d.id} value={d.value}>
                          {d.name}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </>
            </If>
            <h5 className="me-2 col-md-12 my-3 ps-0">
              {id === "new"
                ? getLocaleString(
                    "receivables.create_workflow",
                    "Create Workflow"
                  )
                : id
                  ? getLocaleString(
                      "receivables.edit_workflow",
                      "Edit Workflow"
                    )
                  : getLocaleString(
                      "receivables.custom_workflows",
                      "Custom Workflows"
                    )}
            </h5>
            <div className="col-md-12 mt-2">
              <If condition={!id}>
                <div>
                  {workflowSteps &&
                    workflowSteps.data.map((d) => (
                      <div className="workflow-bar row" data-cy="workflow-bar">
                        <div
                          className="col-md-10 align-items-center  d-flex"
                          data-cy="custome-workflow"
                        >
                          {d.name}
                        </div>
                        <div className="col-md-2 align-items-center justify-content-center d-flex">
                          <button
                            onClick={() => this.getWorkflowSequenceDetail(d.id)}
                            title={getLocaleString(
                              "receivables.edit_event",
                              "Edit event"
                            )}
                            className="btn color-blue"
                            data-cy={`Edit event-${d.name}`}
                          >
                            <i className="fa fa-pencil"></i>
                          </button>
                          <span className="px-1">|</span>
                          <button
                            onClick={() => this.deleteWorkflow(d.id)}
                            title={getLocaleString(
                              "receivables.delete_event",
                              "Delete event"
                            )}
                            className="btn color-blue"
                            data-cy={`Delete event-${d.name}`}
                          >
                            <i className="fa fa-trash-o"></i>
                          </button>
                        </div>
                      </div>
                    ))}
                  {!TokenManager.isCorpEntityRelatedToUser() ? (
                    <div className="form-group mb-3 mt-4">
                      <div>
                        <h5 className="me-2 col-md-12 my-3 ps-0">
                          {getLocaleString(
                            "receivables.workflow_settings",
                            "Workflow Settings"
                          )}
                        </h5>
                        <div className="mt-1">
                          <CustomeCheckbox
                            type="checkbox"
                            checked={
                              workflowSettings["ENTITY_LEVEL_WORKFLOW_ENABLED"]
                            }
                            wrapperClass="d-inline-block me-1"
                            onChange={(e) => {
                              updateSettings({
                                workflowSettings: {
                                  ...workflowSettings,
                                  ENTITY_LEVEL_WORKFLOW_ENABLED:
                                    e.target.checked,
                                },
                              });
                              this.setState({
                                showWorkflowSettings: true,
                              });
                            }}
                            lable={getLocaleString(
                              "receivables.enable_entity_level_workflow",
                              "Enable Entity Level Workflow"
                            )}
                          />
                          <InfoBox
                            description={getLocaleString(
                              "receivables.enable_entity_level_workflow_tooltip",
                              "This setting enables independent workflows at the corporate entity level. E.g. it allows a customer having invoices associated with multiple entities to have a unique workflow per entity."
                            )}
                          />
                        </div>
                        <div className="mt-1">
                          <CustomeCheckbox
                            type="checkbox"
                            checked={
                              workflowSettings[
                                "SELECT_HIGH_SEVERITY_WORKFLOW_STEP"
                              ]
                            }
                            wrapperClass="d-inline-block me-1"
                            onChange={(e) => {
                              updateSettings({
                                workflowSettings: {
                                  ...workflowSettings,
                                  SELECT_HIGH_SEVERITY_WORKFLOW_STEP:
                                    e.target.checked,
                                },
                              });
                              this.setState({
                                showWorkflowSettings: true,
                              });
                            }}
                            lable={getLocaleString(
                              "receivables.use_highest_severity_template",
                              "Use highest severity template when combining emails"
                            )}
                          />
                          <InfoBox
                            description={getLocaleString(
                              "receivables.use_highest_severity_template_tooltip",
                              "The email templates used in the workflow emails depend of the trigger points. When multiple triggers are met the lower-trigger template will be used. e.g. invoice 1 is 7 days overdue and invoice 2 is 45 days overdue. In this scenario when both invoices are included in the same email, the 7 days overdue template is used. This setting reverses that behavior and uses the 45 days overdue template instead."
                            )}
                          />
                        </div>
                        <div className="mt-3">
                          <Button
                            hidden={!showWorkflowSettings}
                            onClick={() => {
                              this.saveSettingDetails(
                                workflowSettings,
                                "workflow"
                              );
                            }}
                          >
                            {getLocaleString("common.save", "Save")}
                          </Button>
                        </div>
                      </div>
                    </div>
                  ) : null}
                  <div className="mt-4">
                    <h5 className="me-2 col-md-12 my-3 ps-0">
                      {getLocaleString(
                        "receivables.bulk_assignment",
                        "Bulk Assignment"
                      )}{" "}
                      <InfoBox
                        description={getLocaleString(
                          "receivables.bulk_assignment_tooltip",
                          "Assign/Unassign a workflow in bulk to all customers."
                        )}
                      />
                    </h5>
                    <select
                      data-testid=" Bulk Assignment"
                      className="form-control w-25 t-12"
                      onChange={(e) => {
                        this.setState({
                          selectedBulkSequence: e.target.value,
                          apply_default_workflow_to_new_customers:
                            +!!e.target.value,
                        });
                      }}
                    >
                      <option value="1">
                        {getLocaleString(
                          "receivables.unassign_workflow",
                          "Unassign Workflow"
                        )}
                      </option>
                      {workflowSteps &&
                        workflowSteps.data.map((d) => (
                          <option value={d.id}>{d.name}</option>
                        ))}
                    </select>
                    {/* For future use */}
                    {/* <div className="mt-3">
                      <CustomeCheckbox
                        type="checkbox"
                        disabled={
                          !apply_default_workflow_to_new_customers &&
                          !this.state.selectedBulkSequence
                        }
                        wrapperClass="d-inline-block me-1"
                        lable="Automatically assign this workflow to newly added customers"
                        checked={apply_default_workflow_to_new_customers}
                        onChange={(e) => {
                          this.setState({
                            apply_default_workflow_to_new_customers:
                              +e.target.checked,
                          });
                        }}
                      />
                      <InfoBox description="Checking the box will also assign this workflow to new future customers" />
                    </div> */}
                    <div className="mt-3">
                      <CustomeCheckbox
                        type="checkbox"
                        disabled={
                          !override_workflow_assignment &&
                          !this.state.selectedBulkSequence
                        }
                        wrapperClass="d-inline-block me-1"
                        lable={getLocaleString(
                          "receivables.override_existing_assignments",
                          "Override existing assignments"
                        )}
                        checked={override_workflow_assignment}
                        onChange={(e) => {
                          this.setState({
                            override_workflow_assignment: +e.target.checked,
                          });
                        }}
                      />
                      <InfoBox
                        description={getLocaleString(
                          "receivables.override_existing_assignments_tooltip",
                          "Checking the box will override existing assignments"
                        )}
                      />
                    </div>
                    {[
                      "NETSUITE",
                      "STRIPE",
                      "TEMPWORKS",
                      "SAGE_INTACCT",
                    ].includes(ERP_TYPE) && (
                      <div>
                        <div className="mt-3">
                          <h5 className="me-2 col-md-12 my-3 ps-0">
                            {getLocaleString(
                              "common.automatically_assign_workflow_to_future_customers",
                              "Automatically assign workflow to future customers"
                            )}
                            <InfoBox description="Assign/Unassign selected workflow to future customers" />
                          </h5>
                        </div>
                        <div className="mt-3">
                          <select
                            data-testid="Workflow for future customers"
                            className="form-control w-25 t-12"
                            value={
                              this.state.future_customer_workflow_sequence_id ||
                              ""
                            }
                            onChange={(e) => {
                              this.setState({
                                future_customer_workflow_sequence_id:
                                  e.target.value,
                              });
                            }}
                          >
                            <option value="">
                              {getLocaleString(
                                "receivables.unassign_workflow",
                                "Unassign Workflow"
                              )}
                            </option>
                            {workflowSteps &&
                              workflowSteps.data.map((d) => (
                                <option value={d.id}>{d.name}</option>
                              ))}
                          </select>
                        </div>
                      </div>
                    )}

                    <div className="mt-3">
                      <Button data-testid="save" onClick={this.handleSave}>
                        {getLocaleString("common.save", "Save")}
                      </Button>
                    </div>
                  </div>
                </div>
                <div className="mt-3 float-end d-none">
                  <Button
                    className="me-2 minwidth-94px"
                    onClick={this.setPrevPage}
                  >
                    {getLocaleString("common.previous", "Previous")}
                  </Button>
                  <Button
                    disabled={paginationNextButtonStatus(
                      currPage - 1,
                      currPage + 1,
                      workflow_sequence_steps.length
                    )}
                    onClick={this.setNextPage}
                    className="minwidth-94px"
                  >
                    {getLocaleString("common.next", "Next")}
                  </Button>
                </div>
              </If>
            </div>
            <If condition={id}>
              <div className="col-md-12 mb-4 text-end">
                <div className="row">
                  <div className="col-md-5">
                    <input
                      type="text"
                      onChange={(e) =>
                        this.setState({ workflowName: e.target.value })
                      }
                      defaultValue={workflowName}
                      placeholder={getLocaleString(
                        "receivables.workflow_name",
                        "Workflow Name"
                      )}
                      className="form-control t-14"
                      value={workflowName}
                    />
                  </div>
                  <div className="col-md-7">
                    <Button
                      type="button"
                      data-cy="submit-workflow"
                      className="me-2"
                      onClick={() => this.createWorkflow(true)}
                      disabled={
                        !workflowName ||
                        !workflow_sequence_steps.length ||
                        workflow_sequence_steps
                          .filter((d) => !d.is_archived)
                          .some((x) =>
                            x.trigger_task
                              ? !(
                                  get(x, "task_details.title", "") &&
                                  (get(
                                    x,
                                    "task_trigger_criteria.overdue_days",
                                    0
                                  ) ||
                                    get(
                                      x,
                                      "task_trigger_criteria.overdue_balance",
                                      0
                                    ))
                                )
                              : !(
                                  x.body &&
                                  x.subject &&
                                  x.trigger_days !== null &&
                                  x.workflow_template_id
                                )
                          )
                      }
                    >
                      {sequenceID
                        ? getLocaleString("common.save", "Save")
                        : getLocaleString("common.submit", "Submit")}
                    </Button>
                    <Button
                      onClick={() =>
                        this.setState(
                          {
                            ...initState,
                          },
                          () =>
                            this.props.history.push(
                              `/settings/receivables/ar-workflow`
                            )
                        )
                      }
                      type="button"
                      data-cy="cancel-workflow"
                      variant="secondary"
                      className="me-2"
                    >
                      {getLocaleString("common.cancel", "Cancel")}
                    </Button>
                    {sequenceID && (
                      <Button
                        variant="danger"
                        type="button"
                        data-cy="delete-workflow"
                        className="me-2"
                        onClick={this.deleteWorkflow}
                      >
                        {getLocaleString("common.delete", "Delete")}
                      </Button>
                    )}
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <Button
                  className="mb-3"
                  onClick={() =>
                    this.addSequenceStep(workflow_sequence_steps.length + 1)
                  }
                >
                  {getLocaleString("receivables.add_step", "Add Step")}
                </Button>
                <div className="my-2">
                  <LogoUpload
                    key={workflowLogo}
                    logo={{ data: workflowLogo }}
                    isFetching={false}
                    getLogoData={this.setWorkflowLogo}
                  />
                </div>
                <DNDContainer>
                  {workflow_sequence_steps
                    .filter((d) => !d.is_archived)
                    .map((step, i) => this.renderWorkflowSteps(step, i))}
                </DNDContainer>

                {showModal && (
                  <ActionsModal
                    key={showModal}
                    handleModal={() => {
                      const workflow_sequence_steps_updated =
                        !modalStepData.id && sequenceID
                          ? workflow_sequence_steps.filter(
                              (d) => d.step_seq !== modalStepData.step_seq
                            )
                          : workflow_sequence_steps;
                      this.setState({
                        showModal: false,
                        modalStepData: null,
                        workflow_sequence_steps:
                          workflow_sequence_steps_updated,
                      });
                    }}
                    show={showModal}
                    newWf={!sequenceID}
                    isSmsEnabled={get(
                      clientData,
                      "client_settings.is_sms_enabled",
                      false
                    )}
                    modalStepData={modalStepData}
                    emailTemplate={emailTemplate}
                    onSave={this.onModalSave}
                    userList={mappedUserList}
                    getEmailTemplateDetails={this.getEmailTemplateDetails}
                  />
                )}
              </div>
            </If>
          </div>
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  clientDetails: state.clientDetails,
  usersList: get(state, "dashboard.userList.list", []),
  workflowSettings: state.configurableSettings.workflowSettings,
});

const mapDispatchToProps = {
  getClientData,
  updateSettings,
  updateClientData,
};

export default connect(mapStateToProps, mapDispatchToProps)(Workflow);
