import React, { useEffect, useRef } from "react";
import moment from "moment";
import omit from "lodash/omit";
import { getLocaleString } from "utils/localization/locale";
import DateRangePicker from "react-bootstrap-daterangepicker";
import TokenManager from "utils/TokenManager";
const defaultLabel = "MM/DD/YYYY";

export let ranges = {
  [getLocaleString("common.dateRangePicker.all")]: [moment(0), moment()],
  AllToDate: [moment(0), moment().add(1, "year")],
  [getLocaleString("common.dateRangePicker.today")]: [moment(), moment()],
  [getLocaleString("common.dateRangePicker.yesterday")]: [
    moment().subtract(1, "days"),
    moment().subtract(1, "days"),
  ],
  [getLocaleString("common.dateRangePicker.next7Days")]: [
    moment(),
    moment().add(7, "days"),
  ],
  [getLocaleString("common.dateRangePicker.next30Days")]: [
    moment(),
    moment().add(30, "days"),
  ],
  [getLocaleString("common.dateRangePicker.past7Days")]: [
    moment().subtract(7, "days"),
    moment(),
  ],
  [getLocaleString("common.dateRangePicker.past30Days")]: [
    moment().subtract(30, "days"),
    moment(),
  ],
  [getLocaleString("common.dateRangePicker.past90Days")]: [
    moment().subtract(90, "days"),
    moment(),
  ],
  [getLocaleString("common.dateRangePicker.past365Days")]: [
    moment().subtract(365, "days"),
    moment(),
  ],
};

const excludeInReceivables = {
  Today: [moment(), moment()],
  Yesterday: [moment().subtract(1, "days"), moment().subtract(1, "days")],
  [getLocaleString("common.dateRangePicker.next7Days")]: [
    moment(),
    moment().add(7, "days"),
  ],
  [getLocaleString("common.dateRangePicker.past7Days")]: [
    moment().subtract(7, "days"),
    moment(),
  ],
};

const defaultAgingBucketRanges = {
  [getLocaleString("common.dateRangePicker.past_1_30")]: [
    moment().subtract(30, "days"),
    moment().subtract(1, "days"),
  ],
  [getLocaleString("common.dateRangePicker.past_31_60")]: [
    moment().subtract(60, "days"),
    moment().subtract(31, "days"),
  ],
  [getLocaleString("common.dateRangePicker.past_61_90")]: [
    moment().subtract(90, "days"),
    moment().subtract(61, "days"),
  ],
  [getLocaleString("common.dateRangePicker.past_gt_90")]: [
    moment().subtract(2, "years"),
    moment().subtract(91, "days"),
  ],
};

const rangeSequence = [
  getLocaleString("common.dateRangePicker.all"),
  getLocaleString("common.dateRangePicker.today"),
  getLocaleString("common.dateRangePicker.yesterday"),
  getLocaleString("common.dateRangePicker.next7Days"),
  getLocaleString("common.dateRangePicker.next30Days"),
  getLocaleString("common.dateRangePicker.past7Days"),
  getLocaleString("common.dateRangePicker.past30Days"),
  getLocaleString("common.dateRangePicker.past90Days"),
  getLocaleString("common.dateRangePicker.past365Days"),
  getLocaleString("common.dateRangePicker.past_gt_30"),
  getLocaleString("common.dateRangePicker.past_gt_60"),
];

function CustomDateRangePicker(props) {
  const {
    all,
    label,
    opens = "right",
    endDate,
    classes,
    maxSpan,
    parentEl,
    allToDate,
    startDate,
    isClearable,
    isFilterChip = false,
    showAgingBuckets = false,
    getSelectedDates,
    agingBucketRanges = defaultAgingBucketRanges,
  } = props;

  const agingBucketIntervals = Object.keys(agingBucketRanges);

  const datePickerRef = useRef();
  let updatedStartDate = startDate
    ? moment(startDate).set({ hour: 0, minute: 0, second: 0 })
    : undefined;
  let updatedEndDate = endDate
    ? moment(endDate).set({ hour: 23, minute: 59, second: 59 })
    : undefined;

  if (label && label !== "Custom Range" && ranges[label]) {
    [updatedStartDate, updatedEndDate] = ranges[label];
  }

  const getDates = (picker) => {
    if (picker) {
      updatedStartDate = picker.startDate;
      updatedEndDate = picker.endDate;
      datePickerRef.current.setStartDate(updatedStartDate);
      datePickerRef.current.setEndDate(updatedEndDate);
      getSelectedDates(updatedStartDate, updatedEndDate, picker.chosenLabel);
    }
  };

  const currentDateFormat = TokenManager.getDateFormat();

  if (allToDate) {
    ranges["All"] = [moment(0), moment().add(1, "year")];
  } else if (all) {
    ranges["All"] = [moment(0), moment()];
  }
  if (!all && !allToDate) {
    ranges = omit(ranges, "All");
  }
  if (showAgingBuckets) {
    ranges = omit(ranges, [
      "Today",
      "Yesterday",
      getLocaleString("common.dateRangePicker.next7Days"),
      getLocaleString("common.dateRangePicker.past7Days"),
    ]);
    ranges = {
      ...ranges,
      ...agingBucketRanges,
      [getLocaleString("common.dateRangePicker.past_gt_30")]: [
        moment(0),
        moment().subtract(30, "days"),
      ],
      [getLocaleString("common.dateRangePicker.past_gt_60")]: [
        moment(0),
        moment().subtract(60, "days"),
      ],
    };
  } else {
    ranges = omit(ranges, [
      ...agingBucketIntervals,
      getLocaleString("common.dateRangePicker.past_gt_30"),
      getLocaleString("common.dateRangePicker.past_gt_60"),
    ]);
    ranges = {
      ...ranges,
      ...excludeInReceivables,
    };
  }

  const mappedRange = rangeSequence
    .concat(showAgingBuckets ? agingBucketIntervals : [])
    .reduce((acc, cur) => {
      if (cur && ranges[cur]) {
        return {
          ...acc,
          [cur]: ranges[cur],
        };
      }
      return acc;
    }, {});

  useEffect(() => {
    if (!updatedStartDate) {
      datePickerRef.current.ref.value = "";
    }
  }, [updatedStartDate, updatedEndDate]);

  return (
    <div id="datepicker-wrap" className="min-width-200">
      <div
        id="wrap-date"
        className="rounded"
        style={{
          background: "#fff",
          cursor: "pointer",
          padding: "8px 10px",
          border: "1px solid #ccc",
          position: "relative",
        }}
      >
        <i id="fa-calender-real" className="fa fa-calendar real"></i>&nbsp;
        <DateRangePicker
          ref={datePickerRef}
          initialSettings={{
            parentEl,
            startDate: updatedStartDate,
            endDate: updatedEndDate,
            ranges: mappedRange,
            className: classes,
            width: "100",
            showDropdowns: true,
            maxSpan: maxSpan,
            opens: opens,
            linkedCalendars: false,
            locale: {
              customRangeLabel: getLocaleString(
                "common.dateRangePicker.custom",
                "Custom Range"
              ),
              applyLabel: getLocaleString("common.apply", "Apply"),
              cancelLabel: getLocaleString("common.cancel", "Cancel"),
              format: currentDateFormat || defaultLabel,
            },
            format: currentDateFormat || defaultLabel,
          }}
          onApply={(_, picker) => getDates(picker)}
          onCallback={(startDate, endDate, chosenLabel) =>
            getDates({ startDate, endDate, chosenLabel })
          }
        >
          <input
            type="text"
            placeholder={`${currentDateFormat} - ${currentDateFormat}`}
            className="border-none outline-none w-90 d-inline-block t-12"
          />
        </DateRangePicker>
        {isClearable && (
          <i
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              getDates({
                startDate: undefined,
                endDate: undefined,
                chosenLabel: "",
              });
              datePickerRef.current.ref.value = "";
              datePickerRef.current.ref.focus();
            }}
            id="calender-fa-cross"
            className="fas fa-times float-end"
            style={{
              position: "absolute",
              top: 12,
              marginRight: isFilterChip ? "10px" : "",
            }}
          />
        )}
      </div>
    </div>
  );
}

export default CustomDateRangePicker;
