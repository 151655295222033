import { ReducersTypes } from "../../constants/ReducersTypes";

const initState = {
  data: "settings data",
  entityLabels: {},
};

export const settings = (
  state = {
    ...initState,
  },
  action
) => {
  switch (action.type) {
    case ReducersTypes.SETTINGS:
      return {
        ...state,
        data: action.payload.data,
      };
    case ReducersTypes.LABEL_SETTINGS:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};

export default settings;
