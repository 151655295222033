import { ReducersTypes } from "constants/ReducersTypes";
import React from "react";
import {
  setReducerData,
  getRoundedIntegerNo,
  getShortNumber,
  convertAmount,
  removeEmptyValueFromObject,
} from "utils";
import { useDispatch } from "react-redux";
import get from "lodash/get";
import { initialReceivablesFilters } from "containers/receivables/reducer";
import { setuxvalue } from "handlers/ux";
import { setNotesData } from "containers/analytics-report/Notes/actions";
import { getLocaleString } from "utils/localization/locale";

function ActivityList({
  collection,
  task,
  email,
  notes,
  assigned_to,
  user_email,
  date_label,
  mr = "activity-card-bg",
  from_date,
  to_date,
  history,
  customer,
  setReceivablesFilters,
}) {
  const dispatch = useDispatch();
  const getSymbol = (val1, val2) => {
    const valPercent = getRoundedIntegerNo(
      Math.abs((val1 - val2) / val2) * 100
    );
    if (!isFinite(valPercent)) return ["", ""];
    else if (getRoundedIntegerNo(val1) > getRoundedIntegerNo(val2))
      return ["pyramid-green-activity", `${valPercent}%`];
    else if (getRoundedIntegerNo(val1) < getRoundedIntegerNo(val2))
      return ["pyramid-red-activity", `${valPercent}%`];
    else
      return [
        "fas fa-minus-square",
        getLocaleString("activity.no_change", "No change"),
      ];
  };

  const applyFilter = (label, callback) => {
    switch (label) {
      case "email":
        dispatch(
          setReducerData(ReducersTypes.SET_EMAIL_REPORT_LIST_FILTERS, {
            sort_by: "sent_at",
            sort_order: "desc",
            page: 1,
            to_date,
            from_date,
            assignedTo: get(assigned_to, "user_id", ""),
            customerSelect: customer,
          })
        );
        break;
      case "receivables":
        const customerSelect = customer
          ? { ...customer, id: get(customer, "customer_id", "") }
          : "";
        const finalFilters = {
          ...initialReceivablesFilters,
          to_date: to_date,
          from_date: from_date,
          sort_by: "amount_closed",
          status: "closed",
          customerSelect,
          interval: "",
          assignedTo: get(assigned_to, "user_role_id", ""),
        };
        setReceivablesFilters && setReceivablesFilters(finalFilters);
        setuxvalue("RECEIVABLE_FILTERS", finalFilters);
        break;
      case "task":
        dispatch(
          setReducerData(ReducersTypes.APPLY_USER_FILTER, {
            assignedUser: { ...assigned_to, from_date, to_date, date_label },
          })
        );
        break;
      case "notes":
        const notesFilter = removeEmptyValueFromObject({
          to_date,
          from_date,
          sort_by: "date",
          followed_by: get(assigned_to, "user_id", "")
            ? {
                id: get(assigned_to, "user_id", ""),
                name: get(assigned_to, "email", ""),
              }
            : null,
          client_customer_rel_id: customer
            ? { id: get(customer, "id", ""), name: get(customer, "name", "") }
            : "",
        });
        dispatch(setNotesData(notesFilter, "SET_NOTES_LIST_FILTER"));
        break;
      default:
        break;
    }
    callback();
  };

  const divClass = `cursor-pointer col-md-3 text-center card-container d-flex flex-col align-items-center ${mr}`;
  return (
    <div className="pt-2">
      {assigned_to ? (
        <h1 className="h2">
          {getLocaleString("activity.activity_report", "Activity report")}-{" "}
          {user_email}
        </h1>
      ) : (
        ""
      )}
      <div className="t-14">
        {getLocaleString("activity.date_range", "Date range")}:{" "}
        {date_label ? date_label : "Custom Range"}
      </div>
      <div
        className="row mt-3 d-flex justify-content-center"
        onClick={() => history.push("/analytics#activity")}
      >
        <div
          className={divClass}
          onClick={(e) =>
            applyFilter(
              "email",
              () => e.stopPropagation(),
              history.push("/analytics#emails")
            )
          }
        >
          <div className="t-20 f">
            {getLocaleString("activity.emails_sent", "Emails Sent")}
          </div>
          <h2 className="fw x-small">{getShortNumber(email[0], false)}</h2>
          <div className="t-14 f btn d-flex align-items-center">
            <span className={`${getSymbol(email[0], email[1])[0]}`}></span>
            &nbsp;&nbsp;{getSymbol(email[0], email[1])[1]}
          </div>
        </div>
        <div
          className={divClass}
          onClick={(e) =>
            applyFilter(
              "task",
              () => e.stopPropagation(),
              history.push("/dashboard#tab2")
            )
          }
        >
          <div className="t-20 f">
            {getLocaleString("dashboard.tasks", "Tasks")}
          </div>
          <h2 className="fw">{getShortNumber(task[0], false)}</h2>
          <div className="t-14 f btn d-flex align-items-center">
            <span className={`${getSymbol(task[0], task[1])[0]}`}></span>
            &nbsp;&nbsp;{getSymbol(task[0], task[1])[1]}
          </div>
        </div>
        <div
          className={divClass}
          onClick={(e) =>
            applyFilter(
              "notes",
              () => e.stopPropagation(),
              history.push("/analytics#notes")
            )
          }
        >
          <div className="t-20 f">
            {getLocaleString("common.notes", "Notes")}
          </div>
          <h2 className="fw">{getShortNumber(notes[0], false)}</h2>
          <div className="t-14 f btn d-flex align-items-center">
            <span className={`${getSymbol(notes[0], notes[1])[0]}`}></span>
            &nbsp;&nbsp;{getSymbol(notes[0], notes[1])[1]}
          </div>
        </div>
        <div
          className={divClass}
          onClick={(e) =>
            applyFilter(
              "receivables",
              () => e.stopPropagation(),
              history.push("/receivables")
            )
          }
        >
          <div className="t-20 f">
            {getLocaleString("activity.collection", "Collection")}
          </div>
          <h2 className="fw">{convertAmount(collection[0])}</h2>
          <div className="t-14 f btn d-flex align-items-center">
            <span
              className={`${getSymbol(collection[0], collection[1])[0]}`}
            ></span>
            &nbsp;&nbsp;
            {getSymbol(collection[0], collection[1])[1]}
          </div>
        </div>
      </div>
    </div>
  );
}

export default ActivityList;
