import { ReducersTypes } from "constants/ReducersTypes";
import { instanceWithRetry } from "actions/axiosInstance";
import { handleAPIError } from "handlers/setters";
import { setReducerData } from "utils";
import { generateQueryString } from "utils/query";

const URL = "client";
export const getClientData =
  (isMutate = false, corp_entity_id = "") =>
  (dispatch) => {
    dispatch(
      setReducerData(ReducersTypes.FETCH_CLIENT_DATA, { isFetching: true })
    );
    return instanceWithRetry
      .get(
        `client${generateQueryString({ mutate: isMutate ? 1 : "", corp_entity_id })}`
      )
      .then((response) => {
        if (response && response.data) {
          dispatch(
            setReducerData(ReducersTypes.FETCH_CLIENT_DATA, {
              clientData: response.data.result,
              initialClientData: response.data.result,
              isFetching: false,
            })
          );
          return response.data;
        }
      })
      .catch((e) => {
        dispatch(
          setReducerData(ReducersTypes.FETCH_CLIENT_DATA, { isFetching: false })
        );
        handleAPIError(e);
        return;
      });
  };

export const updateClientData = (data) => () => {
  return instanceWithRetry
    .post(`${URL}?mutate=1`, data)
    .then((response) => {
      if (response && response.data) {
        return;
      }
    })
    .catch((e) => {
      handleAPIError(e);
      return;
    });
};

export const updateDiscountState = (data) => (dispatch) => {
  dispatch(setReducerData(ReducersTypes.UPDATE_DISCOUNT_STATE, data));
};

export const getAccountTypes = () => {
  return instanceWithRetry
    .get("bankaccount/types?mutate=1")
    .then((res) => {
      const accounts = res.data.result.filter((d) => d);
      localStorage.setItem("account_types", JSON.stringify(accounts));
    })
    .catch((e) => handleAPIError(e));
};

const PAYROLL_URL = "payroll/setting";
export const getPayrollSettings = (params) => (dispatch) => {
  const paramString =
    params &&
    Object.keys(params)
      .filter((d) => params[d])
      .map((key) => `${key}=${params[key]}`)
      .join("&");
  const urlWithParam = params ? `${PAYROLL_URL}?${paramString}` : PAYROLL_URL;
  dispatch(
    setReducerData(ReducersTypes.PAYROLL_SETTINGS_FETCH, { isFetching: true })
  );
  return instanceWithRetry
    .get(urlWithParam)
    .then((response) => {
      if (response && response.data) {
        dispatch(
          setReducerData(ReducersTypes.PAYROLL_SETTINGS_FETCH, {
            payrollSettings: response.data.result,
            isFetching: false,
          })
        );
        return;
      }
    })
    .catch((e) => {
      dispatch(
        setReducerData(ReducersTypes.PAYROLL_SETTINGS_FETCH, {
          isFetching: false,
        })
      );
      handleAPIError(e);
      return;
    });
};

export const getPaymentAccountType = () => {
  return instanceWithRetry
    .get("payment/account/type?mutate=1")
    .then((response) => {
      localStorage.setItem(
        "account_data",
        JSON.stringify(response.data.result)
      );
      return response.data.result;
    })
    .catch((e) => {
      handleAPIError(e);
      localStorage.setItem("account_data", JSON.stringify({}));
      return;
    });
};
