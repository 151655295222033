import jsPDF from "jspdf";
import { convertNumberWithDecimal, dataURItoBlob, replaceQuotes } from "utils";
import "jspdf-autotable";
import get from "lodash/get";
import omit from "lodash/omit";
import difference from "lodash/difference";
import { getFormattedDate } from "./dates";
import React from "react";
import * as XLSX from "xlsx";
import FileSaver from "file-saver";

export function downloadCSV(csv, filename) {
  var csvFile;
  var downloadLink;

  // CSV file
  csvFile = new Blob([csv], { type: "text/csv" });

  // Download link
  downloadLink = document.createElement("a");

  // File name
  downloadLink.download = filename;

  // Create a link to the file
  downloadLink.href = window.URL.createObjectURL(csvFile);

  // Hide download link
  downloadLink.style.display = "none";

  // Add the link to DOM
  document.body.appendChild(downloadLink);

  // Click download link
  downloadLink.click();

  // remove link
  document.body.removeChild(downloadLink);
}

export function exportTableToCSV(
  filename = "export-table",
  tableid = "export-csv-table"
) {
  let csv = [];
  let rows = document.querySelectorAll(`#${tableid} tr`);
  for (let i = 0; i < rows.length; i++) {
    let row = [];
    let cols = rows[i].querySelectorAll(`#${tableid} td, #${tableid} th`);
    for (let j = 0; j < cols.length; j++) {
      row.push(
        cols[j].childNodes[0] && cols[j].childNodes[0].nodeName === "INPUT"
          ? `"${cols[j].childNodes[0].value}"` || 0
          : cols[j].innerText
      );
    }
    csv.push(row.join(","));
  }

  // Download CSV file
  downloadCSV(csv.join("\n"), `${filename}.csv`);
}

export function readCSVFile(fileInput, cb) {
  if (fileInput && fileInput.files[0]) {
    const reader = new FileReader();
    reader.onload = () => {
      cb(reader.result);
    };
    reader.readAsBinaryString(fileInput.files[0]);
  }
}

export function downloadPDF(
  data,
  header,
  fileName,
  client_name,
  showStatement = true,
  save = true
) {
  const doc = new jsPDF();
  const cellWidth = (doc.internal.pageSize.width - 20) / (header.length || 1);
  if (client_name) {
    doc
      .text(
        10,
        10,
        showStatement ? `Statement from ${client_name}` : client_name
      )
      .setFont(undefined, "bold");
  }

  try {
    doc.autoTable({
      head: [header],
      body: data,
      theme: "grid",
      styles: { fontSize: 7, halign: "center" },
      rowPageBreak: "avoid",
      margin: { horizontal: client_name ? 10 : 5 },
      headStyles: {
        valign: "middle",
        cellWidth,
        halign: "left",
        fillColor: "#bdc0bf",
        textColor: "black",
      },
    });
  } catch (e) {}

  if (save) doc.save(fileName);

  return new File([dataURItoBlob(doc.output("datauristring"))], fileName);
}

export function customFieldTable(
  customFields = [],
  visibleColumns = {},
  aggregated = false
) {
  if (!customFields.length || !Object.keys(visibleColumns).length) {
    return [];
  }
  return customFields.map((field) => ({
    header: () => (
      <div className="text-center">{get(field, "erp_field_name", "")}</div>
    ),
    sortable: false,
    id: `${get(field, "id", "")}`,
    accessorKey: "custom_fields",
    className: "text-center",
    columnName: get(field, "erp_field_name", ""),
    show: visibleColumns[get(field, "erp_field_name", "")],
    minWidth: 120,
    aggregate: (values) => {
      if (!aggregated) return null;

      const obj = get(values, "[0]", []).find(
        (assigned_field) =>
          get(assigned_field, "custom_field_master_id", "") ===
          get(field, "id", "")
      );

      return obj
        ? obj[`erp_field_${get(field, "field_type", "").toLowerCase()}_value`]
        : null;
    },
    aggregated: (row) => {
      if (!aggregated) return null;
      return <span>{row.value}</span>;
    },
    cell: (data) => {
      if (aggregated) return null;
      const allFields = [
        ...get(data, "row.original.custom_fields", []),
        ...get(data, "row.original.invoice_lines.custom_fields", []),
        ...get(data, "row.original.client_supplier_rel.custom_fields", []),
        ...get(data, "row.original.client_customer_rel.custom_fields", []),
      ];
      const obj = allFields.find(
        (assigned_field) =>
          get(assigned_field, "custom_field_master_id", "") ===
          get(field, "id", "")
      );
      switch (get(field, "field_type", "")) {
        case "STRING":
          return <span>{get(obj, "erp_field_string_value", "-")}</span>;
        case "FLOAT":
          return convertNumberWithDecimal(
            get(obj, "erp_field_float_value", "")
              ? get(obj, "erp_field_float_value", "")
              : 0
          );
        case "DATE":
          return (
            <span>
              {getFormattedDate(get(obj, "erp_field_date_value", "")) || "-"}
            </span>
          );
        default:
          return "";
      }
    },
  }));
}

export function customFieldExportColumns(customFields, element) {
  const obj = {};
  customFields.length &&
    customFields.forEach((field) => {
      const allFields = [
        ...get(element, "custom_fields", []),
        ...get(element, "row.original.invoice_lines.custom_fields", []),
        ...get(element, "client_supplier_rel.custom_fields", []),
        ...get(element, "client_customer_rel.custom_fields", []),
      ];
      const res = allFields.find(
        (assigned_field) =>
          get(assigned_field, "custom_field_master_id", "") ===
          get(field, "id", "")
      );
      switch (get(field, "field_type", "")) {
        case "STRING":
          obj[get(field, "erp_field_name", "")] = replaceQuotes(
            get(res, "erp_field_string_value", "-")
          );
          break;
        case "FLOAT":
          obj[get(field, "erp_field_name", "")] = get(
            res,
            "erp_field_float_value",
            "-"
          );
          break;
        case "DATE":
          obj[get(field, "erp_field_name", "")] = get(
            res,
            "erp_field_date_value",
            "-"
          );
          break;
        default:
          return "";
      }
    });

  return obj;
}

export const ExportXLSX = (csvData, fileName = "report.xlsx") => {
  const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const ws = XLSX.utils.json_to_sheet(csvData);
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });
  const data = new Blob([excelBuffer], { type: fileType });
  FileSaver.saveAs(data, fileName);
};

export const rearrangeTableColumns = (tableColumns = [], positions = {}) => {
  return tableColumns
    .map((d, i) => ({ ...d, newIndex: get(positions, d.columnName, i) }))
    .sort((a, b) => {
      if (a.staticColumn) {
        return 0;
      } else if (b.staticColumn) {
        return 1;
      } else {
        return a.newIndex - b.newIndex;
      }
    });
};

export const rearrangeCSVColumns = (columnOrder = {}, dataObject = {}) => {
  const sortedColumnOrder = Object.entries(columnOrder)
    .sort((a, b) => a[1] - b[1])
    .reduce((acc, cur) => {
      if (cur && cur.length === 2 && cur[0]) {
        acc[cur[0]] = cur[1];
      }
      return acc;
    }, {});
  const omitKeys = difference(
    Object.keys(sortedColumnOrder),
    Object.keys(dataObject)
  );
  return omit(
    Object.assign({ ...sortedColumnOrder }, { ...dataObject }),
    omitKeys
  );
};

export const generateColumnPositionObject = (columns = []) => {
  return columns.reduce((acc, cur, index) => {
    if (!get(cur, "staticColumn", false) && get(cur, "columnName", "")) {
      acc[cur.columnName] = index + 1;
    }
    return acc;
  }, {});
};
