import { ReducersTypes } from "constants/ReducersTypes";
import moment from "moment";
import { setReducerData } from "utils";
import { instanceWithRetry } from "actions/axiosInstance";
import { betweenDays } from "utils/dates";
import get from "lodash/get";

const getResult = async (data) => {
  let p1 = "",
    p2 = "",
    p3 = "",
    p4 = "";
  const client_customer_rel_id = data.client_customer_rel_id
    ? `&client_customer_rel_id=${data.client_customer_rel_id}`
    : "";
  let date = `from_date=${data.from_date}&to_date=${data.to_date}`;
  date = data.client_customer_rel_id
    ? `${date}${client_customer_rel_id}`
    : date;

  if (data.assigned_to !== "" && data.role !== "arrep") {
    p1 = instanceWithRetry.get(
      `arinvoice/total?${date}&user_role_ids=${data.assigned_to.user_role_id}&role_name=arrep&status=closed`
    );
    p2 = instanceWithRetry.get(
      `artask/total?${date}&assigned_to=${data.assigned_to.user_id}&date_type=due_date`
    );
    p3 = instanceWithRetry.get(
      `email/total?${date}&user_id=${data.assigned_to.user_id}`
    );
    p4 = instanceWithRetry.get(
      `customer/note/total?${date}&followed_by=${data.assigned_to.user_id}`
    );
  } else if (data.role === "arrep") {
    p2 = instanceWithRetry.get(
      `artask/total?${date}&assigned_to=${data.assigned_to.user_id}&date_type=due_date`
    );
    p3 = instanceWithRetry.get(
      `email/total?${date}&user_id=${data.assigned_to.user_id}`
    );
    p4 = instanceWithRetry.get(
      `customer/note/total?${date}&followed_by=${data.assigned_to.user_id}`
    );
    p1 = instanceWithRetry.get(`arinvoice/total?${date}&status=closed`);
  } else {
    p1 = instanceWithRetry.get(`arinvoice/total?${date}&status=closed`);
    p2 = instanceWithRetry.get(`artask/total?${date}&date_type=due_date`);
    p3 = instanceWithRetry.get(`email/total?${date}`);
    p4 = instanceWithRetry.get(`customer/note/total?${date}`);
  }

  return Promise.all([p1, p2, p3, p4]).then((resp) => {
    return resp;
  });
};

export const getActivityList = (data) => async (dispatch) => {
  dispatch(
    setReducerData(ReducersTypes.FETCH_ACTIVITY_LIST, { isFetching: true })
  );
  const diff = betweenDays({
    startdate: data.from_date,
    enddate: data.to_date,
  });
  const pastData = {
    from_date: moment(data.from_date)
      .subtract(diff, "days")
      .format("MM/DD/YYYY"),
    to_date: data.from_date,
    assigned_to: data.assigned_to,
    role: data.role,
  };
  let activityList = [];
  if (get(data, "selectedUsers", []).length) {
    activityList = await Promise.all(
      get(data, "selectedUsers", []).map(async (element) => {
        const result = await getResult({ ...data, assigned_to: element.data });
        const resp = result.concat(
          await getResult({ ...pastData, assigned_to: element.data })
        );
        return {
          user_email: element.label,
          assigned_to: element.data,
          collection: [resp[0]?.data["total"], resp[4]?.data["total"]],
          task: [resp[1]?.data["total"], resp[5]?.data["total"]],
          email: [resp[2]?.data["total"], resp[6]?.data["total"]],
          notes: [resp[3]?.data["total"], resp[7]?.data["total"]],
        };
      })
    );
  } else {
    const result = await getResult(data);
    const resp = result.concat(await getResult(pastData));
    activityList = [
      {
        assigned_to: data.assigned_to,
        collection: [resp[0]?.data["total"], resp[4]?.data["total"]],
        task: [resp[1]?.data["total"], resp[5]?.data["total"]],
        email: [resp[2]?.data["total"], resp[6]?.data["total"]],
        notes: [resp[3]?.data["total"], resp[7]?.data["total"]],
      },
    ];
  }

  dispatch(
    setReducerData(ReducersTypes.FETCH_ACTIVITY_LIST, {
      isFetching: false,
      activityList,
      filters: { ...data },
    })
  );
};
