import React from "react";
import Select from "react-select";

export function MultiSelect(props) {
  const {
    onChange,
    value = [],
    placeholder,
    options = [],
    sendValue = true,
  } = props;
  const _options = [
    ...options.map((x) => ({ value: x.id, label: x.name, data: x.data })),
  ];
  const _value = _options.filter((x) =>
    sendValue ? value.includes(x.value) : value.some((e) => e.value === x.value)
  );
  return (
    <span className="t-14 t-black">
      <Select
        placeholder={placeholder || ""}
        isSearchable={true}
        isMulti={true}
        options={_options}
        label="Tags"
        onChange={(e) =>
          onChange(e && sendValue ? e.map((x) => x.value) : e ? e : [])
        }
        value={_value}
      />
    </span>
  );
}

export default MultiSelect;
