import { ReducersTypes } from "constants/ReducersTypes";
import { instanceWithRetry, isDifferentCurrency } from "./axiosInstance";
import { languageFileMapping } from "utils/localization/config";
import { setReducerData } from "utils";

export function navigateWithFilter(to, filter_name, filters, e) {
  e && e.stopPropagation();
  localStorage.setItem(filter_name, JSON.stringify(filters));
  window.open(to, "_self");
}

export const demoFunction = (data) => {
  return {
    type: "ReducersTypes.CONST",
    payload: data,
  };
};

export const setNavigation = (data) => {
  return {
    type: ReducersTypes.NAVIGATION,
    payload: data,
  };
};

export const refreshHeader = () => {
  return {
    type: ReducersTypes.HEADER_REFRESH_TOGGLE,
  };
};

export const sendExportedReport = (requestObject) => {
  const updatedReqObj = isDifferentCurrency
    ? { ...requestObject, use_foreign_keys: 1 }
    : requestObject;
  return instanceWithRetry.post("exportdata?mutate=1", updatedReqObj);
};

export const getLocaleJson =
  (locale = "en") =>
  (dispatch) => {
    const fileName = languageFileMapping[locale] ?? languageFileMapping["en"];
    return instanceWithRetry
      .get(`/get_locale_json?locale=${fileName}`)
      .then((res) => {
        dispatch(
          setReducerData(ReducersTypes.SET_TRANSLATION, res?.data?.result)
        );
        return res?.data?.result;
      });
  };
